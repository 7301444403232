




















































































import { Component, Vue } from "vue-property-decorator";
import { UserModule } from "@/store/modules/users";
import { router } from "@/router";
import { User } from "@/apis/axon_user_device_info";
@Component({ name: "UsersListComponent" })
export default class UsersListComponent extends Vue {
  search = "";
  email = "";
  message = "";

  async searchRemote() {
    if (this.email != "") {
      const user = await UserModule.searchUserByEmail(this.email);
      if (user) {
        router.push({ name: "user-view", params: { id: user.id } });
      } else {
        this.message = "User not found";
      }
    }
  }

  headers = [
    // { text: "User ID", value: "id", sortable: false },
    { text: "Email", value: "email", sortable: false },
    { text: "Display Name", value: "name", sortable: false },
    { text: "Role", value: "role", sortable: false },
    { text: "Verified", value: "verified", sortable: false },
    { text: "Status", value: "disabled", sortable: false },
    { text: "Actions", value: "actions", sortable: false },
  ];

  created() {
    if (this.page.pageToken == "") UserModule.fetchUsers(this.page);
  }

  mounted() {
    this.loadMore();
  }

  loadMore() {
    UserModule.fetchUsers(this.page);
  }

  get page() {
    return UserModule.usersPage;
  }

  onItemClick(user: User) {
    router.push({ name: "user-view", params: { id: user.id } });
  }
}
