import {
  TransactionHistoryPage,
  TransactionHistoryTrend,
  TransactionsHistoryManagementApi,
} from "@/apis/axon_user_device_info";
import { getIDTokenFromCurrentUser } from "@/plugins/firebase";
import store from "@/store";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";

export interface TransactionHistoryState {
  page: TransactionHistoryPage;
  trends: TransactionHistoryTrend[];
}

@Module({
  dynamic: true,
  namespaced: true,
  name: "TransactionsHistory",
  store,
})
class TransactionsHistory extends VuexModule
  implements TransactionHistoryState {
  public page: TransactionHistoryPage = {} as TransactionHistoryPage;
  public trends: TransactionHistoryTrend[] = [];

  @Mutation
  setPage(page: TransactionHistoryPage) {
    this.page = page;
  }

  @Mutation
  setTrends(items: TransactionHistoryTrend[]) {
    this.trends = items;
  }

  @Action
  async fetchTransactions(action: ListTransactionsAction) {
    const token = await getIDTokenFromCurrentUser();
    const api = new TransactionsHistoryManagementApi({
      basePath: "/api/user-device-info-service",
      accessToken: token,
    });
    try {
      this.context.commit("Loader/increase", null, { root: true });
      const page = (
        await api.getTransactions(action.email, action.page, action.limit)
      ).data;
      this.context.commit("setPage", page);
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }

  @Action
  async fetchTrends(days: number) {
    const token = await getIDTokenFromCurrentUser();
    const api = new TransactionsHistoryManagementApi({
      basePath: "/api/user-device-info-service",
      accessToken: token,
    });
    try {
      this.context.commit("Loader/increase", null, { root: true });
      const items = (await api.trendsByDays(days)).data;
      this.context.commit("setTrends", items);
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }
}

export class ListTransactionsAction {
  email: string | undefined;
  limit = 10;
  page = 1;
}

export const TransactionsHistoryModule = getModule(TransactionsHistory);
