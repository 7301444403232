









































































import { Component, Vue, Watch } from "vue-property-decorator";
import {
  CreateAxonCodes,
  CreateAxonCodesChannelEnum,
} from "@/apis/axon_user_codes";
import { CodesModule } from "@/store/modules/codes";

@Component({ name: "CodeGeneration" })
export default class CodeGeneration extends Vue {
  valid = false;
  dialog = true;
  channels = [
    CreateAxonCodesChannelEnum.WEB,
    CreateAxonCodesChannelEnum.MOBILE,
    CreateAxonCodesChannelEnum.RETAIL,
  ];

  model: CreateAxonCodes = { unlocked: false } as CreateAxonCodes;
  @Watch("model.unlocked")
  onUnlockedSelected() {
    if (this.model.unlocked) {
      this.model.points = 0;
    }
  }

  async onSave() {
    if (this.valid) {
      CodesModule.generateCodes(this.model);
      this.$router.go(-1);
    }
  }
}
