















































import { Component, Vue, Watch } from "vue-property-decorator";
import {
  KnownOptionsGroupsModule,
  ListGroupsAction,
} from "@/store/modules/known-option-groups";
import { router } from "@/router";
import { KnownOptionGroup } from "@/apis/axon_configurations";

@Component({ name: "KnownOptionGroupComponent" })
export default class KnownOptionGroupComponent extends Vue {
  action = new ListGroupsAction();
  search = "";

  headers = [
    // { text: "ID", value: "groupId", sortable: false },
    {
      text: "Name",
      value: "name",
      sortable: false,
    },
    { text: "Description", value: "description", sortable: false },
    { text: "Notes", value: "notes", sortable: false },
    { text: "Created", value: "created", sortable: false },
    { text: "Updated", value: "updated", sortable: false },
    { text: "actions", value: "actions", sortable: false },
  ];
  options = {};

  @Watch("options")
  optionsChanged() {
    KnownOptionsGroupsModule.fetchKnownOptionGroups(this.action);
  }

  get group() {
    return KnownOptionsGroupsModule.group;
  }

  @Watch("group")
  groupChanged() {
    KnownOptionsGroupsModule.fetchKnownOptionGroups(this.action);
  }

  public created() {
    KnownOptionsGroupsModule.fetchKnownOptionGroups(this.action);
  }

  get page() {
    return KnownOptionsGroupsModule.page;
  }

  onItemClick(item: KnownOptionGroup) {
    router.push({
      name: "known-option-group-view",
      params: { id: item.groupId },
    });
  }
}
