





























































































import { Component, Vue, Watch } from "vue-property-decorator";
import {
  DiagnosticsModule,
  SearchDiagnostics,
} from "@/store/modules/diagnostics";
import { DiagnosticsData } from "@/apis/axon_diagnostics";
import { router } from "@/router";

@Component({ name: "Diagnostics" })
export default class Diagnostics extends Vue {
  search = "";
  searchDiagnostics = new SearchDiagnostics();

  public created() {
    this.searchRemote();
  }

  searchRemote() {
    this.searchDiagnostics.page = 1;
    DiagnosticsModule.fetchDiagnosticsData(this.searchDiagnostics);
  }

  headers = [
    // { text: "ID", value: "id", sortable: false },
    {
      text: "VIN",
      value: "vin",
      sortable: false,
    },
    { text: "Email", value: "email", sortable: false },
    { text: "Device Serial Number", value: "deviceId", sortable: false },
    { text: "Device Version", value: "deviceVersion", sortable: false },
    { text: "Execution Date", value: "executionDate", sortable: false },
    { text: "Registration Date", value: "registrationDate", sortable: false },
    { text: "Actions", value: "actions", sortable: false },
  ];

  @Watch("options")
  optionsChanged() {
    DiagnosticsModule.fetchDiagnosticsData(this.searchDiagnostics);
  }

  options = {};

  get pageResult() {
    return DiagnosticsModule.diagnosticsDataPage;
  }

  onItemClick(item: DiagnosticsData) {
    if (item.id) {
      router.push({ name: "diagnostics-details", params: { id: item.id } });
    }
  }

  async onItemDelete(item: DiagnosticsData) {
    if (
      confirm("Are you sure you want to delete this Item?") &&
      item.id != null
    ) {
      await DiagnosticsModule.deleteDiagnostic(item.id);
      DiagnosticsModule.fetchDiagnosticsData(this.searchDiagnostics);
    }
  }
}
