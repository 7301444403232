import {
  KnownOptionsManagementApi,
  MemoryModule,
  MemoryModulesManagementApi,
} from "@/apis/axon_configurations";
import { getIDTokenFromCurrentUser } from "@/plugins/firebase";
import store from "@/store";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";

export interface MemoryModuleState {
  memoryModules: MemoryModule[];
  memoryModule: MemoryModule;
}

@Module({ dynamic: true, namespaced: true, name: "MemoryModules", store })
class MemoryModules extends VuexModule implements MemoryModuleState {
  public memoryModules: MemoryModule[] = [];
  public memoryModule: MemoryModule = {} as MemoryModule;

  @Mutation
  setMemoryModules(memoryModules: MemoryModule[]) {
    this.memoryModules = memoryModules;
  }

  @Mutation
  setMemoryModule(memoryModule: MemoryModule) {
    this.memoryModule = memoryModule;
    const x = this.memoryModules.find((x) => memoryModule.id == x.id);
    if (x) {
      this.memoryModules[this.memoryModules.indexOf(x)] = memoryModule;
    } else {
      this.memoryModules.push(memoryModule);
    }
    this.memoryModules.sort((a, b) => a.name.localeCompare(b.name));
  }

  @Action
  async fetchMemoryModules(action: ListMemoryModulesAction) {
    const token = await getIDTokenFromCurrentUser();
    const api = new MemoryModulesManagementApi({
      basePath: "/api/configurations-service",
      accessToken: token,
    });

    try {
      this.context.commit("Loader/increase", null, { root: true });
      let featureSetId: string | undefined = action.featureSetId;
      if (action.knownOptionId && !featureSetId) {
        const optionsApi = new KnownOptionsManagementApi({
          basePath: "/api/configurations-service",
          accessToken: token,
        });
        const { data } = await optionsApi.getOptionById(action.knownOptionId);
        featureSetId = data.featureSetId;
      }
      // --
      if (featureSetId) {
        const { data } = await api.getMemoryModulesByFeatureSetId(featureSetId);
        this.context.commit("setMemoryModules", data);
      }
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }

  @Action
  async fetchMemoryModuleById(id: string) {
    const token = await getIDTokenFromCurrentUser();
    const api = new MemoryModulesManagementApi({
      basePath: "/api/configurations-service",
      accessToken: token,
    });
    try {
      this.context.commit("Loader/increase", null, { root: true });
      const { data } = await api.getMemoryModuleById(id);
      this.context.commit("setMemoryModule", data);
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }

  @Action
  async saveMemoryModule(model: SaveMemoryModulesAction) {
    const token = await getIDTokenFromCurrentUser();
    const api = new MemoryModulesManagementApi({
      basePath: "/api/configurations-service",
      accessToken: token,
    });
    try {
      this.context.commit("Loader/increase", null, { root: true });
      if (model.id) {
        const { data } = await api.updateMemoryModule(model.id, model);
        this.context.commit("setMemoryModule", data);
      } else {
        const { data } = await api.createMemoryModule(model);
        this.context.commit("setMemoryModule", data);
      }
      this.context.dispatch("fetchMemoryModules", {
        featureSetId: model.featureSetId,
      } as ListMemoryModulesAction);
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }

  @Action
  async deleteMemoryModule(action: DeleteMemoryModulesAction) {
    const token = await getIDTokenFromCurrentUser();
    const api = new MemoryModulesManagementApi({
      basePath: "/api/configurations-service",
      accessToken: token,
    });
    try {
      this.context.commit("Loader/increase", null, { root: true });
      await api.deleteMemoryModule(action.id);
      this.context.dispatch("fetchMemoryModules", {
        featureSetId: action.featureSetId,
      } as ListMemoryModulesAction);
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }
}

export const MemoryModulesModule = getModule(MemoryModules);

export interface ListMemoryModulesAction {
  featureSetId?: string;
  knownOptionId?: string;
}

export interface DeleteMemoryModulesAction {
  id: string;
  featureSetId: string;
}

export interface SaveMemoryModulesAction extends MemoryModule {
  update: boolean;
}
