import {
  KnownOptionGroup,
  KnownOptionGroupPage,
  KnownOptionsGroupsManagementApi,
} from "@/apis/axon_configurations";
import { getIDTokenFromCurrentUser } from "@/plugins/firebase";
import store from "@/store";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";

export interface OptionGroupsState {
  page: KnownOptionGroupPage;
  all: KnownOptionGroup[];
  group: KnownOptionGroup;
}

@Module({
  dynamic: true,
  namespaced: true,
  name: "KnownOptionsGroups",
  store,
})
class KnownOptionsGroups extends VuexModule implements OptionGroupsState {
  public page: KnownOptionGroupPage = {} as KnownOptionGroupPage;
  public all: KnownOptionGroup[] = [];
  public group: KnownOptionGroup = {} as KnownOptionGroup;

  @Mutation
  setGroup(group: KnownOptionGroup) {
    this.group = group;
  }

  @Mutation
  setAll(all: KnownOptionGroup[]) {
    this.all = all;
  }

  @Mutation
  setPage(page: KnownOptionGroupPage) {
    this.page = page;
  }

  @Action
  async fetchKnownOptionGroups(action: ListGroupsAction) {
    const token = await getIDTokenFromCurrentUser();
    const api = new KnownOptionsGroupsManagementApi({
      basePath: "/api/configurations-service",
      accessToken: token,
    });
    try {
      this.context.commit("Loader/increase", null, { root: true });
      const page = (await api.getGroups(action.page, action.limit)).data;
      this.context.commit("setPage", page);
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }

  @Action
  async fetchAll() {
    const token = await getIDTokenFromCurrentUser();
    const api = new KnownOptionsGroupsManagementApi({
      basePath: "/api/configurations-service",
      accessToken: token,
    });
    try {
      this.context.commit("Loader/increase", null, { root: true });
      const page = (await api.getGroups(1, 1000)).data;
      this.context.commit("setAll", page.groups);
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }

  @Action
  async fetchKnownOptionGroupById(id: string) {
    const token = await getIDTokenFromCurrentUser();
    const api = new KnownOptionsGroupsManagementApi({
      basePath: "/api/configurations-service",
      accessToken: token,
    });
    try {
      this.context.commit("Loader/increase", null, { root: true });
      const group = (await api.getGroupById(id)).data;
      this.context.commit("setGroup", group);
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }

  @Action
  async saveKnownOptionGroup(model: KnownOptionGroup) {
    const token = await getIDTokenFromCurrentUser();
    const api = new KnownOptionsGroupsManagementApi({
      basePath: "/api/configurations-service",
      accessToken: token,
    });
    try {
      this.context.commit("Loader/increase", null, { root: true });
      if (model.groupId) {
        this.context.commit(
          "setGroup",
          (await api.updateGroup(model.groupId, model)).data
        );
      } else {
        this.context.commit("setGroup", (await api.createGroup(model)).data);
      }
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }

  @Action
  async deleteGroup(id: string) {
    const token = await getIDTokenFromCurrentUser();
    const api = new KnownOptionsGroupsManagementApi({
      basePath: "/api/configurations-service",
      accessToken: token,
    });
    try {
      this.context.commit("Loader/increase", null, { root: true });
      (await api.deleteGroup(id)).data;
      this.context.commit("setGroup", {});
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }
}

export const KnownOptionsGroupsModule = getModule(KnownOptionsGroups);

export class ListGroupsAction {
  limit = 10;
  page = 1;
}
