















































import { Component, Vue } from "vue-property-decorator";
import { AuthModule } from "@/store/modules/auth";
@Component({
  name: "AppMenu",
})
export default class AppMenu extends Vue {
  get items(): MenuSection[] {
    return [
      {
        name: "General",
        items: [
          {
            name: "Dashboard",
            path: "/home",
            icon: "mdi-home",
            authenticated: true,
            roles: ["ADMIN", "REPORTER"],
          },
        ],
      },
      {
        name: "Tools",
        items: [
          {
            name: "VIN Decoder",
            path: "/vin-decoder",
            icon: "mdi-code-json",
            authenticated: true,
            roles: ["ADMIN", "REPORTER"],
          },
        ],
      },
      {
        name: "Configurations",
        authenticated: true,
        roles: ["ADMIN"],
        items: [
          {
            name: "Known Options",
            path: "/known-options",
            icon: "mdi-cube",
            authenticated: true,
            roles: ["ADMIN"],
          },
          {
            name: "Feature Sets",
            path: "/feature-sets",
            icon: "mdi-car-lifted-pickup",
            authenticated: true,
            roles: ["ADMIN"],
          },
          {
            name: "Known Option Groups",
            path: "/known-option-groups",
            icon: "mdi-group",
            authenticated: true,
            roles: ["ADMIN"],
          },
        ],
      },
      {
        name: "Reports",
        authenticated: true,
        roles: ["ADMIN", "REPORTER"],
        items: [
          {
            name: "Diagnostics",
            path: "/diagnostics",
            icon: "mdi-file-document-multiple-outline",
            authenticated: true,
            roles: ["ADMIN", "REPORTER"],
          },
        ],
      },
      {
        name: "User Data",
        authenticated: true,
        roles: ["ADMIN"],
        items: [
          {
            name: "Users",
            path: "/users",
            icon: "mdi-account-group",
            authenticated: true,
            roles: ["ADMIN"],
          },
          {
            name: "Registered Devices",
            path: "/devices",
            icon: "mdi-bluetooth",
            authenticated: true,
            roles: ["ADMIN"],
          },
          {
            name: "Redeemable Codes",
            path: "/codes",
            icon: "mdi-barcode",
            authenticated: true,
            roles: ["ADMIN"],
          },
          {
            name: "Purchases / Transactions",
            path: "/transactions",
            icon: "mdi-chart-bar",
            authenticated: true,
            roles: ["ADMIN"],
          },
        ],
      },
      {
        name: "Others",
        items: [
          {
            name: "FAQs",
            path: "/faqs",
            icon: "mdi-help-circle",
            authenticated: true,
            roles: ["ADMIN"],
          },
          {
            name: "About",
            path: "/about",
            icon: "mdi-information-outline",
            authenticated: true,
            roles: ["ADMIN", "REPORTER"],
          },
        ],
      },
    ];
  }

  filterSections(items: MenuSection[]) {
    return items.filter(
      (x) =>
        !x.authenticated ||
        (x.authenticated &&
          this.user &&
          x.roles &&
          x.roles.indexOf(this.role) > -1)
    );
  }

  filterItems(items: MenuItem[]) {
    return items.filter(
      (x) =>
        !x.authenticated ||
        (x.authenticated &&
          this.user &&
          x.roles &&
          x.roles.indexOf(this.role) > -1)
    );
  }

  get user() {
    return AuthModule.currentUser;
  }

  get role() {
    return AuthModule.role;
  }
}

interface MenuSection {
  name: string;
  authenticated?: boolean;
  roles?: string[];
  items: MenuItem[];
}

interface MenuItem {
  name: string;
  path: string;
  icon: string;
  authenticated: boolean;
  roles: string[];
}
