






















import { Component, Vue, Watch } from "vue-property-decorator";
import { AuthModule } from "../store/modules/auth";

@Component({ name: "Welcome" })
export default class Welcome extends Vue {
  get currentUser() {
    return AuthModule.currentUser;
  }

  @Watch("currentUser")
  onCurrentUser() {
    if (AuthModule.currentUser) {
      this.$router.push({ name: "home" });
    }
  }
}
