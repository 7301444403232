

















































































import { Component, Vue } from "vue-property-decorator";
import { DiagnosticsModule } from "@/store/modules/diagnostics";
import { TransactionsHistoryModule } from "@/store/modules/transactions-history";
import { LoaderModule } from "@/store/modules/loader";
import moment from "moment";

@Component({ name: "Home" })
export default class Home extends Vue {
  latestDiagnosticsCount = 10;
  latestDays = 30;
  transactionDays = 30;

  created() {
    DiagnosticsModule.fetchLatestDiagnosticsData(this.latestDiagnosticsCount);
    DiagnosticsModule.fetchLatestActivity(this.latestDays);
    TransactionsHistoryModule.fetchTrends(this.transactionDays);
  }

  get loading() {
    return LoaderModule.loading;
  }

  get latestDiagnostics() {
    return DiagnosticsModule.latestDiagnosticsData;
  }

  get diagnosticsTrends() {
    return DiagnosticsModule.latestActivity;
  }

  get diagnosticsChartData() {
    const items = DiagnosticsModule.latestActivity.map((x) => [
      moment(x.date).fromNow(),
      x.count,
      "#0000AA",
    ]);
    return [["Date", "Received", { role: "style" }], ...items];
  }

  get diagnosticsChartOptions() {
    return {
      width: "100%",
      height: "300",
      title: "Diagnostics Activity // " + this.latestDays + " days",
      titleTextStyle: {
        color: "#001c71",
        fontSize: 20,
        bold: true,
      },
      hAxis: {
        textStyle: {
          fontSize: 10,
          bold: true,
          italic: true,
        },
      },
      vAxis: {
        minValue: 0,
        textStyle: {
          fontSize: 10,
          bold: true,
          italic: true,
        },
      },
      legend: {
        position: "top",
        textStyle: {
          fontSize: 10,
          bold: true,
        },
      },
      colors: ["#0000AA"],
    };
  }

  get transactionTrends() {
    return TransactionsHistoryModule.trends;
  }

  get transactionsChartData() {
    const items = TransactionsHistoryModule.trends.map((x) => [
      moment(x.date).fromNow(),
      x.accumulatedAmount,
      "#0000AA",
    ]);
    return [["Date", "Amount", { role: "style" }], ...items];
  }

  get transactionsChartOptions() {
    return {
      width: "100%",
      height: "400",
      title: "Purchases / Transactions // " + this.latestDays + " days",
      titleTextStyle: {
        color: "#001c71",
        fontSize: 20,
        bold: true,
      },
      vAxis: {
        minValue: 0,
        textStyle: {
          fontSize: 10,
          bold: true,
          italic: true,
        },
      },
      hAxis: {
        textStyle: {
          fontSize: 10,
          bold: true,
          italic: true,
        },
      },
      legend: {
        position: "top",
        textStyle: {
          fontSize: 10,
          bold: true,
        },
      },
      colors: ["#0000AA"],
    };
  }
}
