




































































import { Component, Vue } from "vue-property-decorator";
import { router } from "@/router";
import {
  CreateUserRequest,
  CreateUserRequestRoleEnum,
} from "@/apis/axon_user_device_info";
import { UserModule, CreateUserAction } from "@/store/modules/users";
import { VForm } from "@/types";

@Component({ name: "UserFormComponent" })
export default class UserFormComponent extends Vue {
  dialog = true;
  showPassword = false;
  roles = [CreateUserRequestRoleEnum.ADMIN, CreateUserRequestRoleEnum.REPORTER];
  rules = {
    required: (value: string) => !!value || "Required.",
    min: (v: string) => v.length >= 8 || "Min 8 characters",
    emailMatch: (v: string) => /.+@.+\..+/.test(v) || "E-mail must be valid",
  };

  get model() {
    return {
      email: "",
      password: "",
      name: "",
    } as CreateUserRequest;
  }

  onSave() {
    if ((this.$refs.form as VForm).validate()) {
      const action = {} as CreateUserAction;
      action.create = this.model;
      UserModule.createUser(action);
      router.go(-1);
    }
  }
}
