











































































import { Component, Vue } from "vue-property-decorator";
import { DiagnosticsModule } from "@/store/modules/diagnostics";
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";

@Component({
  name: "DiagnosticDetails",
  components: {
    VueJsonPretty,
  },
})
export default class DiagnosticDetails extends Vue {
  dialog = true;
  deep = 7;
  selected = {};
  selectedPath = "";

  get diagnosticData() {
    if (!DiagnosticsModule.diagnosticsDataPage.docs) return null;
    return DiagnosticsModule.diagnosticsDataPage.docs.find(
      (x) => x.id == this.$route.params.id
    );
  }

  copy(data: string) {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(data);
    }
  }

  onSelectedPath(itemPath: string, data: object) {
    if (typeof data != "object") {
      this.selected = data;
      this.selectedPath = itemPath + typeof data;
    } else {
      this.selectedPath = "";
      this.selected = {};
    }
  }
}
