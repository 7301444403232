


























































import { Component, Vue } from "vue-property-decorator";
import { FAQSModule } from "@/store/modules/faqs";
import { Faq } from "@/apis/axon_faqs";
import { router } from "@/router";
import draggable from "vuedraggable";

@Component({
  name: "FAQs",
  components: {
    draggable,
  },
})
export default class FAQs extends Vue {
  search = "";
  headers = [
    { text: "Order", value: "order", sortable: false },
    { text: "Question", value: "question", sortable: false },
    { text: "Created", value: "created", sortable: false },
    { text: "Updated", value: "updated", sortable: false },
    { text: "Actions", value: "actions", sortable: false },
  ];

  created() {
    FAQSModule.fetchFAQs();
  }

  get items() {
    if (this.search && this.search != "") {
      return FAQSModule.faqs.filter((v) => {
        return v.question.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
      });
    }
    return FAQSModule.faqs;
  }

  onItemClick(item: Faq) {
    if (item.id) {
      router.push({ name: "faqs-view", params: { id: item.id } });
    }
  }

  async chageFAQOrder(event: {
    moved: { element: Faq; newIndex: number; oldIndex: number };
  }) {
    const newElement = this.items[event.moved.newIndex];
    const oldElement = this.items[event.moved.oldIndex];
    this.items[event.moved.newIndex] = oldElement;
    this.items[event.moved.oldIndex] = newElement;
    this.items.forEach(async (v, i) => {
      if (v.order != i) {
        v.order = i;
        await FAQSModule.saveFaq(v);
      }
    });
  }
}
