var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3","no-gutters":""}},[_c('v-text-field',{attrs:{"label":"E-mail","color":"secondary","outlined":"","dense":"","clearable":"","required":""},model:{value:(_vm.action.email),callback:function ($$v) {_vm.$set(_vm.action, "email", $$v)},expression:"action.email"}})],1),_c('v-col',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.searchRemote}},[_vm._v("Search")])],1)],1)],1),_c('v-divider'),_c('v-data-table',{attrs:{"headers":_vm.headers,"page":_vm.action.page,"pageCount":_vm.page.items / _vm.page.limit,"server-items-length":_vm.page.total,"items":_vm.page.transactions,"items-per-page":_vm.action.limit,"search":_vm.search,"footer-props":{
        'items-per-page-options': [10, 20, 50],
      },"options":_vm.options},on:{"update:page":function($event){return _vm.$set(_vm.action, "page", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.action, "limit", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.action, "limit", $event)},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.amount)+" ")]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":"primary"}},[_vm._v(_vm._s(item.status))])]}},{key:"item.created",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.created,"from", "now"))+" ")]}},{key:"item.modified",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.modified,"from", "now"))+" ")]}}],null,true)})],1),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }