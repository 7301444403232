import {
  UserCapabilities,
  UserCapabilitiesHistory,
  UserCapabilitiesHistoryApi,
  UserCapabilitiesManagementApi,
} from "@/apis/axon_user_capabilities";
import { getIDTokenFromCurrentUser } from "@/plugins/firebase";
import store from "@/store";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";

export interface UserCapabilitiesState {
  capabilities: UserCapabilities;
}

@Module({
  dynamic: true,
  namespaced: true,
  name: "UsersCapabilities",
  store,
})
class UsersCapabilities extends VuexModule implements UserCapabilitiesState {
  public capabilities: UserCapabilities = {} as UserCapabilities;
  public history: UserCapabilitiesHistory[] = [];

  @Mutation
  setCapabilities(capabilities: UserCapabilities) {
    this.capabilities = capabilities;
  }

  @Mutation
  setHistory(history: UserCapabilitiesHistory[]) {
    this.history = history;
  }

  @Action
  async fetchCodes(userId: string) {
    const token = await getIDTokenFromCurrentUser();
    const api = new UserCapabilitiesManagementApi({
      basePath: "/api/capabilities-service",
      accessToken: token,
    });
    try {
      this.context.commit("Loader/increase", null, { root: true });
      const capabilities = (await api.getUserCapabilitiesById(userId)).data;
      this.context.commit("setCapabilities", capabilities);
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }

  @Action
  async fetchHistory(userId: string) {
    const token = await getIDTokenFromCurrentUser();
    const api = new UserCapabilitiesHistoryApi({
      basePath: "/api/capabilities-service",
      accessToken: token,
    });
    try {
      this.context.commit("Loader/increase", null, { root: true });
      const history = (await api.getUserCapabilitiesHistoryById(userId)).data;
      this.context.commit("setHistory", history);
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }

  @Action
  async updateUserCapabilities(action: UpdateUserCapabilitiesAction) {
    const token = await getIDTokenFromCurrentUser();
    const api = new UserCapabilitiesManagementApi({
      basePath: "/api/capabilities-service",
      accessToken: token,
    });
    try {
      this.context.commit("Loader/increase", null, { root: true });
      const capabilities = (
        await api.updateUserCapabilitiesById(action.userId, {
          allowedOptions: action.allowedOptions,
          points: action.points,
          unlocked: action.unlocked,
        })
      ).data;
      this.context.commit("setCapabilities", capabilities);
      this.context.dispatch("fetchHistory", action.userId);
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }
}

export interface UpdateUserCapabilitiesAction {
  userId: string;
  allowedOptions: string[];
  points: number;
  unlocked: boolean;
}

export const UsersCapabilitiesModule = getModule(UsersCapabilities);
