
















































































































































import { Component, Vue } from "vue-property-decorator";
import { FeatureSetsModule } from "@/store/modules/feature-sets";
import { MemoryModulesModule } from "@/store/modules/memory-modules";
import { MemoryModule } from "@/apis/axon_configurations";
import { router } from "@/router";

@Component({ name: "FeatureSetView" })
export default class FeatureSetView extends Vue {
  created() {
    FeatureSetsModule.fetchFeatureSet(this.$route.params.id);
    MemoryModulesModule.fetchMemoryModules({
      featureSetId: this.$route.params.id,
    });
  }

  get item() {
    return FeatureSetsModule.featureSet;
  }

  get memoryModules() {
    return MemoryModulesModule.memoryModules;
  }

  async onDelete() {
    if (confirm("Are you sure you want to delete this item?")) {
      FeatureSetsModule.deleteFeatureSet(this.$route.params.id);
      this.$router.go(-1);
    }
  }

  deleteMemoryModule(memoryModule: MemoryModule) {
    if (confirm("Are you sure you want to delete this item?")) {
      MemoryModulesModule.deleteMemoryModule(memoryModule);
    }
  }

  editMemoryModule(memoryModule: MemoryModule) {
    router.push({
      name: "memory-module-edit",
      params: {
        id: memoryModule.featureSetId,
        memoryModuleId: memoryModule.id,
      },
    });
  }
}
