
































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { DevicesModule, ListDevicesAction } from "@/store/modules/devices";
import { FeatureSetsModule } from "@/store/modules/feature-sets";

import { AxonDevice } from "@/apis/axon_user_device_info";
import { router } from "@/router";

@Component({ name: "DevicesListComponent" })
export default class DevicesListComponent extends Vue {
  search = "";
  listDevicesAction = new ListDevicesAction();

  public created() {
    FeatureSetsModule.fetchAll();
    this.searchRemote();
  }

  searchRemote() {
    this.listDevicesAction.page = 1;
    DevicesModule.fetchDevices(this.listDevicesAction);
  }

  getFeatureSet(id: string) {
    const set = FeatureSetsModule.all.find((x) => x.featureSetId == id);
    if (set) {
      return set.name;
    }
    return id;
  }

  headers = [
    { text: "Device Identifier", value: "deviceIdentifier", sortable: false },
    { text: "DeviceVersion", value: "deviceVersion", sortable: false },
    {
      text: "VIN",
      value: "vin",
      sortable: false,
    },
    { text: "Feature Set", value: "featureSetId", sortable: false },
    { text: "Last Usage", value: "lastUsageDate", sortable: false },
    { text: "Registration", value: "registrationDate", sortable: false },
    { text: "Status", value: "status", sortable: false },
    { text: "Restriction", value: "open", sortable: false },
    // { text: "User Id", value: "userId", sortable: false },
    { text: "Actions", value: "actions", sortable: false },
  ];

  @Watch("options")
  optionsChanged() {
    DevicesModule.fetchDevices(this.listDevicesAction);
  }

  options = {};

  get pageResult() {
    return DevicesModule.page;
  }

  onItemClick(item: AxonDevice) {
    router.push({ name: "device-details", params: { id: item.deviceId } });
  }
}
