






































import { Component, Vue } from "vue-property-decorator";
import { router } from "@/router";
import { Faq } from "@/apis/axon_faqs";
import { FAQSModule } from "@/store/modules/faqs";
import { VueEditor } from "vue2-editor";
import { VForm } from "@/types";

@Component({ name: "FAQSForm", components: { VueEditor } })
export default class FAQSForm extends Vue {
  dialog = true;
  createForm: Faq = {} as Faq;
  editorToolbar = [
    [{ font: [] }],
    [{ header: [false, 1, 2, 3, 4, 5, 6] }],
    ["bold", "italic", "underline", "strike"],
    [
      { align: "" },
      { align: "center" },
      { align: "right" },
      { align: "justify" },
    ],
    ["blockquote"],
    [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
    [{ script: "sub" }, { script: "super" }],
    ["link"],
    ["clean"],
  ];

  mounted() {
    if (this.$route.params.id) {
      FAQSModule.fetchFaqById(this.$route.params.id);
    }
  }

  get model() {
    if (this.$route.params.id) {
      return FAQSModule.faq;
    }
    return this.createForm;
  }

  onSave() {
    if ((this.$refs.form as VForm).validate()) {
      FAQSModule.saveFaq(this.model);
      router.go(-1);
    }
  }
}
