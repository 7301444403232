




























































import { Component, Vue } from "vue-property-decorator";
import { VINDecoderModule } from "@/store/modules/vin-decoder";
import VueJsonPretty from "vue-json-pretty";
import { VForm } from "@/types";

@Component({
  name: "VINDecoderComponent",
  components: {
    VueJsonPretty,
  },
})
export default class VINDecoderComponent extends Vue {
  vin = "";
  deep = 5;

  get results() {
    return VINDecoderModule.results;
  }

  searchAction() {
    if ((this.$refs.form as VForm).validate())
      VINDecoderModule.fetchResults(this.vin);
  }
}
