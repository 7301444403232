import {
  FeatureSet,
  FeatureSetManagementApi,
  FeatureSetPage,
} from "@/apis/axon_configurations";
import { getIDTokenFromCurrentUser } from "@/plugins/firebase";
import store from "@/store";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";

export interface FeatureSetsState {
  featureSetPage: FeatureSetPage;
  all: FeatureSet[];
  featureSet: FeatureSet;
}

@Module({ dynamic: true, namespaced: true, name: "FeatureSets", store })
class FeatureSets extends VuexModule implements FeatureSetsState {
  public featureSetPage: FeatureSetPage = {} as FeatureSetPage;
  public all: FeatureSet[] = [];
  public featureSet: FeatureSet = {} as FeatureSet;

  @Mutation
  setAll(all: FeatureSet[]) {
    this.all = all;
  }

  @Mutation
  setFeatureSet(featureSet: FeatureSet) {
    this.featureSet = featureSet;
  }

  @Mutation
  setPage(page: FeatureSetPage) {
    this.featureSetPage = page;
  }

  @Action
  async fetchFeatureSets(action: ListFeatureSetsAction) {
    const token = await getIDTokenFromCurrentUser();
    const api = new FeatureSetManagementApi({
      basePath: "/api/configurations-service",
      accessToken: token,
    });
    try {
      this.context.commit("Loader/increase", null, { root: true });
      const page = (await api.getFeatureSets(action.page, action.limit)).data;
      this.context.commit("setPage", page);
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }

  @Action
  async fetchAll() {
    const token = await getIDTokenFromCurrentUser();
    const api = new FeatureSetManagementApi({
      basePath: "/api/configurations-service",
      accessToken: token,
    });
    try {
      this.context.commit("Loader/increase", null, { root: true });
      const page = (await api.getFeatureSets(1, 1000)).data;
      this.context.commit("setAll", page.featureSets);
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }

  @Action
  async fetchFeatureSet(id: string) {
    const token = await getIDTokenFromCurrentUser();
    const api = new FeatureSetManagementApi({
      basePath: "/api/configurations-service",
      accessToken: token,
    });
    try {
      this.context.commit("Loader/increase", null, { root: true });
      const featureSet = (await api.getFeatureSetById(id)).data;
      this.context.commit("setFeatureSet", featureSet);
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }

  @Action
  async saveFeature(model: FeatureSet) {
    const token = await getIDTokenFromCurrentUser();
    const api = new FeatureSetManagementApi({
      basePath: "/api/configurations-service",
      accessToken: token,
    });
    try {
      this.context.commit("Loader/increase", null, { root: true });
      if (model.featureSetId) {
        this.context.commit(
          "setFeatureSet",
          (await api.updateFeatureSet(model.featureSetId, model)).data
        );
      } else {
        this.context.commit(
          "setFeatureSet",
          (await api.createFeatureSet(model)).data
        );
      }
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }

  @Action
  async deleteFeatureSet(id: string) {
    const token = await getIDTokenFromCurrentUser();
    const api = new FeatureSetManagementApi({
      basePath: "/api/configurations-service",
      accessToken: token,
    });
    try {
      this.context.commit("Loader/increase", null, { root: true });
      (await api.deleteFeatureSet(id)).data;
      this.context.commit("setFeatureSet", {});
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }
}

export const FeatureSetsModule = getModule(FeatureSets);

export class ListFeatureSetsAction {
  limit = 10;
  page = 1;
}
