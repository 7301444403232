import {
  KnownOption,
  KnownOptionPage,
  KnownOptionsManagementApi,
  OptionCommand,
} from "@/apis/axon_configurations";
import { getIDTokenFromCurrentUser } from "@/plugins/firebase";
import store from "@/store";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";

export interface OptionsState {
  page: KnownOptionPage;
  option: KnownOption;
}

@Module({ dynamic: true, namespaced: true, name: "KnownOptions", store })
class KnownOptions extends VuexModule implements OptionsState {
  public page: KnownOptionPage = {
    limit: 10,
    page: 1,
    total: 0,
    options: [],
  } as KnownOptionPage;

  public option: KnownOption = {
    optionCommands: [] as OptionCommand[],
  } as KnownOption;

  @Mutation
  setOption(option: KnownOption) {
    this.option = option;
  }

  @Mutation
  setPage(page: KnownOptionPage) {
    this.page = page;
  }

  @Action
  async fetchKnownOptions(action: ListKnownOptionsAction) {
    const token = await getIDTokenFromCurrentUser();
    const api = new KnownOptionsManagementApi({
      basePath: "/api/configurations-service",
      accessToken: token,
    });

    try {
      this.context.commit("Loader/increase", null, { root: true });
      const page = (
        await api.getOptions(
          action.featureSetIds,
          action.groupIds,
          action.page,
          action.limit
        )
      ).data;
      this.context.commit("setPage", page);
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }

  @Action
  async fetchOptionById(id: string) {
    const token = await getIDTokenFromCurrentUser();
    const api = new KnownOptionsManagementApi({
      basePath: "/api/configurations-service",
      accessToken: token,
    });
    try {
      this.context.commit("Loader/increase", null, { root: true });
      this.context.commit("setOption", (await api.getOptionById(id)).data);
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }

  @Action
  async createOption(model: KnownOption) {
    const token = await getIDTokenFromCurrentUser();
    const api = new KnownOptionsManagementApi({
      basePath: "/api/configurations-service",
      accessToken: token,
    });
    try {
      this.context.commit("Loader/increase", null, { root: true });
      const option = (
        await api.createOption({
          code: model.code,
          description: model.description,
          featureSetId: model.featureSetId,
          groupId: model.groupId,
          name: model.name,
          notes: model.notes ?? "N/A",
          sku: model.sku,
          visible: model.visible,
          points: model.points,
          queryable: model.queryable,
          reusable: model.reusable,
          youtubeVideoUrl: model.youtubeVideoUrl,
        })
      ).data;
      this.context.commit("setOption", option);
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }

  @Action
  async updateOption(model: KnownOption) {
    const token = await getIDTokenFromCurrentUser();
    const api = new KnownOptionsManagementApi({
      basePath: "/api/configurations-service",
      accessToken: token,
    });
    try {
      this.context.commit("Loader/increase", null, { root: true });
      const option = (
        await api.updateOption(model.knownOptionId, {
          code: model.code,
          description: model.description,
          featureSetId: model.featureSetId,
          groupId: model.groupId,
          name: model.name,
          notes: model.notes ?? "N/A",
          sku: model.sku,
          visible: model.visible,
          points: model.points,
          queryable: model.queryable,
          reusable: model.reusable,
          youtubeVideoUrl: model.youtubeVideoUrl,
        })
      ).data;
      this.context.commit("setOption", option);
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }

  @Action
  async deleteOption(knownOptionId: string) {
    const token = await getIDTokenFromCurrentUser();
    const api = new KnownOptionsManagementApi({
      basePath: "/api/configurations-service",
      accessToken: token,
    });
    try {
      this.context.commit("Loader/increase", null, { root: true });
      await api.deleteOption(knownOptionId);
      this.context.commit("setOption", { knownOptionId: knownOptionId });
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }
}

export class ListKnownOptionsAction {
  featureSetIds: string | undefined;
  groupIds: string | undefined;
  limit = 10;
  page = 1;
}

export const KnownOptionsModule = getModule(KnownOptions);
