




















































































import { Component, Vue } from "vue-property-decorator";
import { AxonDevice, AxonDeviceStatusEnum } from "@/apis/axon_user_device_info";
import { DevicesModule } from "@/store/modules/devices";
import { FeatureSetsModule } from "@/store/modules/feature-sets";
import { VForm } from "@/types";

@Component({ name: "DeviceUpdateFormComponent" })
export default class DeviceUpdateFormComponent extends Vue {
  dialog = true;
  statuses = [
    AxonDeviceStatusEnum.REGISTERED,
    AxonDeviceStatusEnum.LOCKED,
    AxonDeviceStatusEnum.DISABLED,
  ];

  created() {
    FeatureSetsModule.fetchAll();
  }

  get featureSets() {
    return FeatureSetsModule.all.map((x) => {
      return { text: x.name, value: x.featureSetId };
    });
  }

  get model() {
    if (DevicesModule.page.devices) {
      const found = DevicesModule.page.devices.find(
        (x) => x.deviceId == this.$route.params.id
      );
      if (found) return found;
    }
    return {} as AxonDevice;
  }

  async onSave() {
    if ((this.$refs.form as VForm).validate()) {
      await DevicesModule.updateDevice({
        id: this.$route.params.id,
        device: this.model,
      });
      this.$router.go(-1);
    }
  }
}
