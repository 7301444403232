























































































































import { Component, Vue } from "vue-property-decorator";
import { router } from "@/router";

import { KnownOptionsGroupsModule } from "@/store/modules/known-option-groups";
import { FeatureSetsModule } from "@/store/modules/feature-sets";
import { KnownOptionsModule } from "@/store/modules/known-options";
import { VForm } from "@/types";
import { KnownOption } from "@/apis/axon_configurations";

@Component({ name: "KnownOptionForm" })
export default class KnownOptionForm extends Vue {
  dialog = true;
  create = {} as KnownOption;

  created() {
    KnownOptionsGroupsModule.fetchAll();
    FeatureSetsModule.fetchAll();
    if (this.$route.params.id) {
      KnownOptionsModule.fetchOptionById(this.$route.params.id);
    }
  }

  get model() {
    return this.$route.params.id ? KnownOptionsModule.option : this.create;
  }

  get groups() {
    return KnownOptionsGroupsModule.all.map((x) => {
      return { text: x.name, value: x.groupId };
    });
  }

  get featureSets() {
    return FeatureSetsModule.all.map((x) => {
      return { text: x.name, value: x.featureSetId };
    });
  }

  onSave() {
    if ((this.$refs.form as VForm).validate()) {
      if (this.$route.params.id) {
        KnownOptionsModule.updateOption(this.model);
      } else {
        KnownOptionsModule.createOption(this.model);
      }
      router.go(-1);
    }
  }
}
