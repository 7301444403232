












































import { Component, Vue } from "vue-property-decorator";
import { router } from "@/router";
import { AuthModule } from "@/store/modules/auth";
@Component({ name: "ForgotPasswordForm" })
export default class ForgotPasswordForm extends Vue {
  valid = false;
  email = "";
  dialog = true;

  async resetPassword() {
    if (this.valid) {
      await AuthModule.resetPassword(this.email);
      router.go(-1);
    }
  }
}
