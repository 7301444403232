// tslint:disable
/**
 * User Device Info
 * This Service is intented for handling User and Device informations
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AxonDevice
 */
export interface AxonDevice {
    /**
     * 
     * @type {string}
     * @memberof AxonDevice
     */
    deviceId: string;
    /**
     * 
     * @type {string}
     * @memberof AxonDevice
     */
    deviceIdentifier: string;
    /**
     * 
     * @type {string}
     * @memberof AxonDevice
     */
    deviceVersion: string;
    /**
     * 
     * @type {string}
     * @memberof AxonDevice
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof AxonDevice
     */
    pushNotificationsToken: string;
    /**
     * 
     * @type {string}
     * @memberof AxonDevice
     */
    vin: string;
    /**
     * 
     * @type {string}
     * @memberof AxonDevice
     */
    featureSetId: string;
    /**
     * 
     * @type {string}
     * @memberof AxonDevice
     */
    status: AxonDeviceStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof AxonDevice
     */
    open: boolean;
    /**
     * 
     * @type {string}
     * @memberof AxonDevice
     */
    registrationDate: string;
    /**
     * 
     * @type {string}
     * @memberof AxonDevice
     */
    lastUsageDate: string;
    /**
     * 
     * @type {number}
     * @memberof AxonDevice
     */
    transferCount: number;
}

/**
    * @export
    * @enum {string}
    */
export enum AxonDeviceStatusEnum {
    VINNOTASSOCIATEDWITHDEVICE = 'VIN_NOT_ASSOCIATED_WITH_DEVICE',
    USERMISMATCH = 'USER_MISMATCH',
    NOTREGISTERED = 'NOT_REGISTERED',
    REGISTERED = 'REGISTERED',
    DISABLED = 'DISABLED',
    LOCKED = 'LOCKED'
}

/**
 * 
 * @export
 * @interface AxonDeviceMessage
 */
export interface AxonDeviceMessage {
    /**
     * 
     * @type {string}
     * @memberof AxonDeviceMessage
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof AxonDeviceMessage
     */
    message: string;
}
/**
 * 
 * @export
 * @interface AxonDevicePage
 */
export interface AxonDevicePage {
    /**
     * 
     * @type {Array<AxonDevice>}
     * @memberof AxonDevicePage
     */
    devices: Array<AxonDevice>;
    /**
     * 
     * @type {number}
     * @memberof AxonDevicePage
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof AxonDevicePage
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof AxonDevicePage
     */
    total: number;
}
/**
 * 
 * @export
 * @interface AxonDeviceRegistration
 */
export interface AxonDeviceRegistration {
    /**
     * 
     * @type {string}
     * @memberof AxonDeviceRegistration
     */
    deviceIdentifier: string;
    /**
     * 
     * @type {string}
     * @memberof AxonDeviceRegistration
     */
    deviceVersion: string;
    /**
     * 
     * @type {string}
     * @memberof AxonDeviceRegistration
     */
    vin: string;
    /**
     * 
     * @type {string}
     * @memberof AxonDeviceRegistration
     */
    featureSetId: string;
    /**
     * 
     * @type {string}
     * @memberof AxonDeviceRegistration
     */
    pushNotificationsToken: string;
}
/**
 * 
 * @export
 * @interface AxonDeviceRegistrationResult
 */
export interface AxonDeviceRegistrationResult {
    /**
     * 
     * @type {string}
     * @memberof AxonDeviceRegistrationResult
     */
    deviceId: string;
    /**
     * 
     * @type {string}
     * @memberof AxonDeviceRegistrationResult
     */
    status: AxonDeviceRegistrationResultStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AxonDeviceRegistrationResultStatusEnum {
    VINNOTASSOCIATEDWITHDEVICE = 'VIN_NOT_ASSOCIATED_WITH_DEVICE',
    USERMISMATCH = 'USER_MISMATCH',
    NOTREGISTERED = 'NOT_REGISTERED',
    REGISTERED = 'REGISTERED',
    DISABLED = 'DISABLED',
    LOCKED = 'LOCKED'
}

/**
 * 
 * @export
 * @interface AxonDeviceValidation
 */
export interface AxonDeviceValidation {
    /**
     * 
     * @type {string}
     * @memberof AxonDeviceValidation
     */
    deviceIdentifier: string;
    /**
     * 
     * @type {string}
     * @memberof AxonDeviceValidation
     */
    deviceVersion: string;
    /**
     * 
     * @type {string}
     * @memberof AxonDeviceValidation
     */
    vin: string;
    /**
     * 
     * @type {string}
     * @memberof AxonDeviceValidation
     */
    featureSetId: string;
    /**
     * 
     * @type {string}
     * @memberof AxonDeviceValidation
     */
    pushNotificationsToken: string;
}
/**
 * 
 * @export
 * @interface AxonDeviceValidationResult
 */
export interface AxonDeviceValidationResult {
    /**
     * 
     * @type {string}
     * @memberof AxonDeviceValidationResult
     */
    deviceId?: string;
    /**
     * 
     * @type {string}
     * @memberof AxonDeviceValidationResult
     */
    status: AxonDeviceValidationResultStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AxonDeviceValidationResultStatusEnum {
    VINNOTASSOCIATEDWITHDEVICE = 'VIN_NOT_ASSOCIATED_WITH_DEVICE',
    USERMISMATCH = 'USER_MISMATCH',
    NOTREGISTERED = 'NOT_REGISTERED',
    REGISTERED = 'REGISTERED',
    DISABLED = 'DISABLED',
    LOCKED = 'LOCKED'
}

/**
 * 
 * @export
 * @interface CreateTransactionHistory
 */
export interface CreateTransactionHistory {
    /**
     * 
     * @type {string}
     * @memberof CreateTransactionHistory
     */
    reference: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTransactionHistory
     */
    sku: string;
    /**
     * 
     * @type {number}
     * @memberof CreateTransactionHistory
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof CreateTransactionHistory
     */
    currency: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTransactionHistory
     */
    platform: string;
}
/**
 * 
 * @export
 * @interface CreateUserRequest
 */
export interface CreateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    role: CreateUserRequestRoleEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateUserRequestRoleEnum {
    ADMIN = 'ADMIN',
    REPORTER = 'REPORTER'
}

/**
 * 
 * @export
 * @interface MemoryModule
 */
export interface MemoryModule {
    /**
     * 
     * @type {string}
     * @memberof MemoryModule
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof MemoryModule
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MemoryModule
     */
    module: string;
    /**
     * 
     * @type {string}
     * @memberof MemoryModule
     */
    address: string;
    /**
     * 
     * @type {Array<PayloadItem>}
     * @memberof MemoryModule
     */
    payload: Array<PayloadItem>;
    /**
     * 
     * @type {string}
     * @memberof MemoryModule
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof MemoryModule
     */
    modified?: string;
}
/**
 * 
 * @export
 * @interface PayloadItem
 */
export interface PayloadItem {
    /**
     * 
     * @type {string}
     * @memberof PayloadItem
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof PayloadItem
     */
    block: string;
    /**
     * 
     * @type {string}
     * @memberof PayloadItem
     */
    line: string;
    /**
     * 
     * @type {string}
     * @memberof PayloadItem
     */
    value: string;
    /**
     * 
     * @type {number}
     * @memberof PayloadItem
     */
    valueLength: number;
}
/**
 * 
 * @export
 * @interface TransactionHistory
 */
export interface TransactionHistory {
    /**
     * 
     * @type {string}
     * @memberof TransactionHistory
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionHistory
     */
    reference: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionHistory
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionHistory
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionHistory
     */
    sku: string;
    /**
     * 
     * @type {number}
     * @memberof TransactionHistory
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionHistory
     */
    currency: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionHistory
     */
    platform: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionHistory
     */
    status: TransactionHistoryStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof TransactionHistory
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionHistory
     */
    modified?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum TransactionHistoryStatusEnum {
    PENDING = 'PENDING',
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
    CANCELED = 'CANCELED'
}

/**
 * 
 * @export
 * @interface TransactionHistoryPage
 */
export interface TransactionHistoryPage {
    /**
     * 
     * @type {Array<TransactionHistory>}
     * @memberof TransactionHistoryPage
     */
    transactions: Array<TransactionHistory>;
    /**
     * 
     * @type {number}
     * @memberof TransactionHistoryPage
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionHistoryPage
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionHistoryPage
     */
    total: number;
}
/**
 * 
 * @export
 * @interface TransactionHistoryTrend
 */
export interface TransactionHistoryTrend {
    /**
     * 
     * @type {string}
     * @memberof TransactionHistoryTrend
     */
    date: string;
    /**
     * 
     * @type {number}
     * @memberof TransactionHistoryTrend
     */
    accumulatedAmount: number;
}
/**
 * 
 * @export
 * @interface UpdateUserRequest
 */
export interface UpdateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    role: UpdateUserRequestRoleEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserRequest
     */
    disabled: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateUserRequestRoleEnum {
    ADMIN = 'ADMIN',
    REPORTER = 'REPORTER'
}

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    role: UserRoleEnum;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    verified: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    disabled: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum UserRoleEnum {
    ADMIN = 'ADMIN',
    REPORTER = 'REPORTER'
}

/**
 * 
 * @export
 * @interface UserBackup
 */
export interface UserBackup {
    /**
     * 
     * @type {string}
     * @memberof UserBackup
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserBackup
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UserBackup
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof UserBackup
     */
    vin: string;
    /**
     * 
     * @type {Array<MemoryModule>}
     * @memberof UserBackup
     */
    memoryModules: Array<MemoryModule>;
    /**
     * 
     * @type {string}
     * @memberof UserBackup
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof UserBackup
     */
    modified?: string;
}
/**
 * 
 * @export
 * @interface UserPage
 */
export interface UserPage {
    /**
     * 
     * @type {Array<User>}
     * @memberof UserPage
     */
    users: Array<User>;
    /**
     * 
     * @type {number}
     * @memberof UserPage
     */
    limit: number;
    /**
     * 
     * @type {string}
     * @memberof UserPage
     */
    pageToken: string;
}

/**
 * TransactionsHistoryManagementApi - axios parameter creator
 * @export
 */
export const TransactionsHistoryManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [email] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactions: async (email?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/transactions-history/_page`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} lastDays 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trendsByDays: async (lastDays: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'lastDays' is not null or undefined
            if (lastDays === null || lastDays === undefined) {
                throw new RequiredError('lastDays','Required parameter lastDays was null or undefined when calling trendsByDays.');
            }
            const localVarPath = `/transactions-history/_trends`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lastDays !== undefined) {
                localVarQueryParameter['lastDays'] = lastDays;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransactionsHistoryManagementApi - functional programming interface
 * @export
 */
export const TransactionsHistoryManagementApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [email] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransactions(email?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TransactionHistoryPage>>> {
            const localVarAxiosArgs = await TransactionsHistoryManagementApiAxiosParamCreator(configuration).getTransactions(email, page, limit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} lastDays 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trendsByDays(lastDays: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TransactionHistoryTrend>>> {
            const localVarAxiosArgs = await TransactionsHistoryManagementApiAxiosParamCreator(configuration).trendsByDays(lastDays, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TransactionsHistoryManagementApi - factory interface
 * @export
 */
export const TransactionsHistoryManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} [email] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactions(email?: string, page?: number, limit?: number, options?: any): AxiosPromise<Array<TransactionHistoryPage>> {
            return TransactionsHistoryManagementApiFp(configuration).getTransactions(email, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} lastDays 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trendsByDays(lastDays: number, options?: any): AxiosPromise<Array<TransactionHistoryTrend>> {
            return TransactionsHistoryManagementApiFp(configuration).trendsByDays(lastDays, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TransactionsHistoryManagementApi - object-oriented interface
 * @export
 * @class TransactionsHistoryManagementApi
 * @extends {BaseAPI}
 */
export class TransactionsHistoryManagementApi extends BaseAPI {
    /**
     * 
     * @param {string} [email] 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsHistoryManagementApi
     */
    public getTransactions(email?: string, page?: number, limit?: number, options?: any) {
        return TransactionsHistoryManagementApiFp(this.configuration).getTransactions(email, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} lastDays 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsHistoryManagementApi
     */
    public trendsByDays(lastDays: number, options?: any) {
        return TransactionsHistoryManagementApiFp(this.configuration).trendsByDays(lastDays, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * UserBackupsApi - axios parameter creator
 * @export
 */
export const UserBackupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {UserBackup} userBackup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBackup: async (userBackup: UserBackup, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userBackup' is not null or undefined
            if (userBackup === null || userBackup === undefined) {
                throw new RequiredError('userBackup','Required parameter userBackup was null or undefined when calling createBackup.');
            }
            const localVarPath = `/user-backups`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof userBackup !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(userBackup !== undefined ? userBackup : {}) : (userBackup || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBackup: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteBackup.');
            }
            const localVarPath = `/user-backups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} vin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBackups: async (vin: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'vin' is not null or undefined
            if (vin === null || vin === undefined) {
                throw new RequiredError('vin','Required parameter vin was null or undefined when calling getBackups.');
            }
            const localVarPath = `/user-backups`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (vin !== undefined) {
                localVarQueryParameter['vin'] = vin;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UserBackup} userBackup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBackup: async (id: string, userBackup: UserBackup, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateBackup.');
            }
            // verify required parameter 'userBackup' is not null or undefined
            if (userBackup === null || userBackup === undefined) {
                throw new RequiredError('userBackup','Required parameter userBackup was null or undefined when calling updateBackup.');
            }
            const localVarPath = `/user-backups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof userBackup !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(userBackup !== undefined ? userBackup : {}) : (userBackup || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserBackupsApi - functional programming interface
 * @export
 */
export const UserBackupsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {UserBackup} userBackup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBackup(userBackup: UserBackup, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserBackup>> {
            const localVarAxiosArgs = await UserBackupsApiAxiosParamCreator(configuration).createBackup(userBackup, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBackup(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UserBackupsApiAxiosParamCreator(configuration).deleteBackup(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} vin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBackups(vin: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserBackup>>> {
            const localVarAxiosArgs = await UserBackupsApiAxiosParamCreator(configuration).getBackups(vin, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UserBackup} userBackup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBackup(id: string, userBackup: UserBackup, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserBackup>> {
            const localVarAxiosArgs = await UserBackupsApiAxiosParamCreator(configuration).updateBackup(id, userBackup, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UserBackupsApi - factory interface
 * @export
 */
export const UserBackupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {UserBackup} userBackup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBackup(userBackup: UserBackup, options?: any): AxiosPromise<UserBackup> {
            return UserBackupsApiFp(configuration).createBackup(userBackup, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBackup(id: string, options?: any): AxiosPromise<void> {
            return UserBackupsApiFp(configuration).deleteBackup(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} vin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBackups(vin: string, options?: any): AxiosPromise<Array<UserBackup>> {
            return UserBackupsApiFp(configuration).getBackups(vin, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UserBackup} userBackup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBackup(id: string, userBackup: UserBackup, options?: any): AxiosPromise<UserBackup> {
            return UserBackupsApiFp(configuration).updateBackup(id, userBackup, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserBackupsApi - object-oriented interface
 * @export
 * @class UserBackupsApi
 * @extends {BaseAPI}
 */
export class UserBackupsApi extends BaseAPI {
    /**
     * 
     * @param {UserBackup} userBackup 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserBackupsApi
     */
    public createBackup(userBackup: UserBackup, options?: any) {
        return UserBackupsApiFp(this.configuration).createBackup(userBackup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserBackupsApi
     */
    public deleteBackup(id: string, options?: any) {
        return UserBackupsApiFp(this.configuration).deleteBackup(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} vin 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserBackupsApi
     */
    public getBackups(vin: string, options?: any) {
        return UserBackupsApiFp(this.configuration).getBackups(vin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UserBackup} userBackup 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserBackupsApi
     */
    public updateBackup(id: string, userBackup: UserBackup, options?: any) {
        return UserBackupsApiFp(this.configuration).updateBackup(id, userBackup, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * UserDeviceInfoApi - axios parameter creator
 * @export
 */
export const UserDeviceInfoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get Devices for Current User
         * @summary Get Devices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyDevices: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/device-info/_list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Register Device for User
         * @summary Register Device for User
         * @param {AxonDeviceRegistration} axonDeviceRegistration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerAxonDevice: async (axonDeviceRegistration: AxonDeviceRegistration, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'axonDeviceRegistration' is not null or undefined
            if (axonDeviceRegistration === null || axonDeviceRegistration === undefined) {
                throw new RequiredError('axonDeviceRegistration','Required parameter axonDeviceRegistration was null or undefined when calling registerAxonDevice.');
            }
            const localVarPath = `/device-info/axon-registration`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof axonDeviceRegistration !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(axonDeviceRegistration !== undefined ? axonDeviceRegistration : {}) : (axonDeviceRegistration || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Transfer Device to User
         * @summary Transfer Device
         * @param {string} id 
         * @param {string} [email] 
         * @param {string} [vin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transferDevice: async (id: string, email?: string, vin?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling transferDevice.');
            }
            const localVarPath = `/device-info/{id}/_transfer`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (vin !== undefined) {
                localVarQueryParameter['vin'] = vin;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Check Status / Permission of User to use this Device
         * @summary User - Device Validation
         * @param {AxonDeviceValidation} axonDeviceValidation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateAxonDevice: async (axonDeviceValidation: AxonDeviceValidation, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'axonDeviceValidation' is not null or undefined
            if (axonDeviceValidation === null || axonDeviceValidation === undefined) {
                throw new RequiredError('axonDeviceValidation','Required parameter axonDeviceValidation was null or undefined when calling validateAxonDevice.');
            }
            const localVarPath = `/device-info/axon-validation`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof axonDeviceValidation !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(axonDeviceValidation !== undefined ? axonDeviceValidation : {}) : (axonDeviceValidation || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserDeviceInfoApi - functional programming interface
 * @export
 */
export const UserDeviceInfoApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Get Devices for Current User
         * @summary Get Devices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyDevices(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AxonDevice>>> {
            const localVarAxiosArgs = await UserDeviceInfoApiAxiosParamCreator(configuration).getMyDevices(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Register Device for User
         * @summary Register Device for User
         * @param {AxonDeviceRegistration} axonDeviceRegistration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerAxonDevice(axonDeviceRegistration: AxonDeviceRegistration, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxonDeviceRegistrationResult>> {
            const localVarAxiosArgs = await UserDeviceInfoApiAxiosParamCreator(configuration).registerAxonDevice(axonDeviceRegistration, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Transfer Device to User
         * @summary Transfer Device
         * @param {string} id 
         * @param {string} [email] 
         * @param {string} [vin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transferDevice(id: string, email?: string, vin?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxonDevice>> {
            const localVarAxiosArgs = await UserDeviceInfoApiAxiosParamCreator(configuration).transferDevice(id, email, vin, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Check Status / Permission of User to use this Device
         * @summary User - Device Validation
         * @param {AxonDeviceValidation} axonDeviceValidation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateAxonDevice(axonDeviceValidation: AxonDeviceValidation, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxonDeviceValidationResult>> {
            const localVarAxiosArgs = await UserDeviceInfoApiAxiosParamCreator(configuration).validateAxonDevice(axonDeviceValidation, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UserDeviceInfoApi - factory interface
 * @export
 */
export const UserDeviceInfoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Get Devices for Current User
         * @summary Get Devices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyDevices(options?: any): AxiosPromise<Array<AxonDevice>> {
            return UserDeviceInfoApiFp(configuration).getMyDevices(options).then((request) => request(axios, basePath));
        },
        /**
         * Register Device for User
         * @summary Register Device for User
         * @param {AxonDeviceRegistration} axonDeviceRegistration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerAxonDevice(axonDeviceRegistration: AxonDeviceRegistration, options?: any): AxiosPromise<AxonDeviceRegistrationResult> {
            return UserDeviceInfoApiFp(configuration).registerAxonDevice(axonDeviceRegistration, options).then((request) => request(axios, basePath));
        },
        /**
         * Transfer Device to User
         * @summary Transfer Device
         * @param {string} id 
         * @param {string} [email] 
         * @param {string} [vin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transferDevice(id: string, email?: string, vin?: string, options?: any): AxiosPromise<AxonDevice> {
            return UserDeviceInfoApiFp(configuration).transferDevice(id, email, vin, options).then((request) => request(axios, basePath));
        },
        /**
         * Check Status / Permission of User to use this Device
         * @summary User - Device Validation
         * @param {AxonDeviceValidation} axonDeviceValidation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateAxonDevice(axonDeviceValidation: AxonDeviceValidation, options?: any): AxiosPromise<AxonDeviceValidationResult> {
            return UserDeviceInfoApiFp(configuration).validateAxonDevice(axonDeviceValidation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserDeviceInfoApi - object-oriented interface
 * @export
 * @class UserDeviceInfoApi
 * @extends {BaseAPI}
 */
export class UserDeviceInfoApi extends BaseAPI {
    /**
     * Get Devices for Current User
     * @summary Get Devices
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDeviceInfoApi
     */
    public getMyDevices(options?: any) {
        return UserDeviceInfoApiFp(this.configuration).getMyDevices(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Register Device for User
     * @summary Register Device for User
     * @param {AxonDeviceRegistration} axonDeviceRegistration 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDeviceInfoApi
     */
    public registerAxonDevice(axonDeviceRegistration: AxonDeviceRegistration, options?: any) {
        return UserDeviceInfoApiFp(this.configuration).registerAxonDevice(axonDeviceRegistration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Transfer Device to User
     * @summary Transfer Device
     * @param {string} id 
     * @param {string} [email] 
     * @param {string} [vin] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDeviceInfoApi
     */
    public transferDevice(id: string, email?: string, vin?: string, options?: any) {
        return UserDeviceInfoApiFp(this.configuration).transferDevice(id, email, vin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Check Status / Permission of User to use this Device
     * @summary User - Device Validation
     * @param {AxonDeviceValidation} axonDeviceValidation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDeviceInfoApi
     */
    public validateAxonDevice(axonDeviceValidation: AxonDeviceValidation, options?: any) {
        return UserDeviceInfoApiFp(this.configuration).validateAxonDevice(axonDeviceValidation, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * UserDeviceManagementApi - axios parameter creator
 * @export
 */
export const UserDeviceManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get all Users Devices
         * @summary Get all Users Devices
         * @param {string} [email] 
         * @param {string} [deviceIdentifier] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevices: async (email?: string, deviceIdentifier?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users-devices`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (deviceIdentifier !== undefined) {
                localVarQueryParameter['deviceIdentifier'] = deviceIdentifier;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Send Message / Notification
         * @summary Send Message / Notification
         * @param {string} id 
         * @param {AxonDeviceMessage} axonDeviceMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMessage: async (id: string, axonDeviceMessage: AxonDeviceMessage, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling sendMessage.');
            }
            // verify required parameter 'axonDeviceMessage' is not null or undefined
            if (axonDeviceMessage === null || axonDeviceMessage === undefined) {
                throw new RequiredError('axonDeviceMessage','Required parameter axonDeviceMessage was null or undefined when calling sendMessage.');
            }
            const localVarPath = `/users-devices/{id}/_message`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof axonDeviceMessage !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(axonDeviceMessage !== undefined ? axonDeviceMessage : {}) : (axonDeviceMessage || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update Device Info
         * @summary Update Device Info
         * @param {string} id 
         * @param {AxonDevice} axonDevice 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDevice: async (id: string, axonDevice: AxonDevice, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateDevice.');
            }
            // verify required parameter 'axonDevice' is not null or undefined
            if (axonDevice === null || axonDevice === undefined) {
                throw new RequiredError('axonDevice','Required parameter axonDevice was null or undefined when calling updateDevice.');
            }
            const localVarPath = `/users-devices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof axonDevice !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(axonDevice !== undefined ? axonDevice : {}) : (axonDevice || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserDeviceManagementApi - functional programming interface
 * @export
 */
export const UserDeviceManagementApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Get all Users Devices
         * @summary Get all Users Devices
         * @param {string} [email] 
         * @param {string} [deviceIdentifier] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDevices(email?: string, deviceIdentifier?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxonDevicePage>> {
            const localVarAxiosArgs = await UserDeviceManagementApiAxiosParamCreator(configuration).getDevices(email, deviceIdentifier, page, limit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Send Message / Notification
         * @summary Send Message / Notification
         * @param {string} id 
         * @param {AxonDeviceMessage} axonDeviceMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendMessage(id: string, axonDeviceMessage: AxonDeviceMessage, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxonDeviceMessage>> {
            const localVarAxiosArgs = await UserDeviceManagementApiAxiosParamCreator(configuration).sendMessage(id, axonDeviceMessage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update Device Info
         * @summary Update Device Info
         * @param {string} id 
         * @param {AxonDevice} axonDevice 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDevice(id: string, axonDevice: AxonDevice, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxonDevice>> {
            const localVarAxiosArgs = await UserDeviceManagementApiAxiosParamCreator(configuration).updateDevice(id, axonDevice, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UserDeviceManagementApi - factory interface
 * @export
 */
export const UserDeviceManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Get all Users Devices
         * @summary Get all Users Devices
         * @param {string} [email] 
         * @param {string} [deviceIdentifier] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevices(email?: string, deviceIdentifier?: string, page?: number, limit?: number, options?: any): AxiosPromise<AxonDevicePage> {
            return UserDeviceManagementApiFp(configuration).getDevices(email, deviceIdentifier, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Send Message / Notification
         * @summary Send Message / Notification
         * @param {string} id 
         * @param {AxonDeviceMessage} axonDeviceMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMessage(id: string, axonDeviceMessage: AxonDeviceMessage, options?: any): AxiosPromise<AxonDeviceMessage> {
            return UserDeviceManagementApiFp(configuration).sendMessage(id, axonDeviceMessage, options).then((request) => request(axios, basePath));
        },
        /**
         * Update Device Info
         * @summary Update Device Info
         * @param {string} id 
         * @param {AxonDevice} axonDevice 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDevice(id: string, axonDevice: AxonDevice, options?: any): AxiosPromise<AxonDevice> {
            return UserDeviceManagementApiFp(configuration).updateDevice(id, axonDevice, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserDeviceManagementApi - object-oriented interface
 * @export
 * @class UserDeviceManagementApi
 * @extends {BaseAPI}
 */
export class UserDeviceManagementApi extends BaseAPI {
    /**
     * Get all Users Devices
     * @summary Get all Users Devices
     * @param {string} [email] 
     * @param {string} [deviceIdentifier] 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDeviceManagementApi
     */
    public getDevices(email?: string, deviceIdentifier?: string, page?: number, limit?: number, options?: any) {
        return UserDeviceManagementApiFp(this.configuration).getDevices(email, deviceIdentifier, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Send Message / Notification
     * @summary Send Message / Notification
     * @param {string} id 
     * @param {AxonDeviceMessage} axonDeviceMessage 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDeviceManagementApi
     */
    public sendMessage(id: string, axonDeviceMessage: AxonDeviceMessage, options?: any) {
        return UserDeviceManagementApiFp(this.configuration).sendMessage(id, axonDeviceMessage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update Device Info
     * @summary Update Device Info
     * @param {string} id 
     * @param {AxonDevice} axonDevice 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDeviceManagementApi
     */
    public updateDevice(id: string, axonDevice: AxonDevice, options?: any) {
        return UserDeviceManagementApiFp(this.configuration).updateDevice(id, axonDevice, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * UserInfoApi - axios parameter creator
 * @export
 */
export const UserInfoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Current User Info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/_current`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserInfoApi - functional programming interface
 * @export
 */
export const UserInfoApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Current User Info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await UserInfoApiAxiosParamCreator(configuration).getUserInfo(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UserInfoApi - factory interface
 * @export
 */
export const UserInfoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get Current User Info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfo(options?: any): AxiosPromise<User> {
            return UserInfoApiFp(configuration).getUserInfo(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserInfoApi - object-oriented interface
 * @export
 * @class UserInfoApi
 * @extends {BaseAPI}
 */
export class UserInfoApi extends BaseAPI {
    /**
     * 
     * @summary Get Current User Info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInfoApi
     */
    public getUserInfo(options?: any) {
        return UserInfoApiFp(this.configuration).getUserInfo(options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * UserManagementApi - axios parameter creator
 * @export
 */
export const UserManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create User
         * @param {CreateUserRequest} createUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (createUserRequest: CreateUserRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserRequest' is not null or undefined
            if (createUserRequest === null || createUserRequest === undefined) {
                throw new RequiredError('createUserRequest','Required parameter createUserRequest was null or undefined when calling createUser.');
            }
            const localVarPath = `/users`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createUserRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createUserRequest !== undefined ? createUserRequest : {}) : (createUserRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get User By Email
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserByEmail: async (email: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            if (email === null || email === undefined) {
                throw new RequiredError('email','Required parameter email was null or undefined when calling getUserByEmail.');
            }
            const localVarPath = `/users/_search`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get User By ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getUserById.');
            }
            const localVarPath = `/users/{id}/details`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Users
         * @param {string} [pageToken] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (pageToken?: string, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['pageToken'] = pageToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update User
         * @param {string} id 
         * @param {UpdateUserRequest} updateUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (id: string, updateUserRequest: UpdateUserRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateUser.');
            }
            // verify required parameter 'updateUserRequest' is not null or undefined
            if (updateUserRequest === null || updateUserRequest === undefined) {
                throw new RequiredError('updateUserRequest','Required parameter updateUserRequest was null or undefined when calling updateUser.');
            }
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateUserRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateUserRequest !== undefined ? updateUserRequest : {}) : (updateUserRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserManagementApi - functional programming interface
 * @export
 */
export const UserManagementApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create User
         * @param {CreateUserRequest} createUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(createUserRequest: CreateUserRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await UserManagementApiAxiosParamCreator(configuration).createUser(createUserRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get User By Email
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserByEmail(email: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await UserManagementApiAxiosParamCreator(configuration).getUserByEmail(email, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get User By ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserById(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await UserManagementApiAxiosParamCreator(configuration).getUserById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get All Users
         * @param {string} [pageToken] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(pageToken?: string, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPage>> {
            const localVarAxiosArgs = await UserManagementApiAxiosParamCreator(configuration).getUsers(pageToken, limit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update User
         * @param {string} id 
         * @param {UpdateUserRequest} updateUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(id: string, updateUserRequest: UpdateUserRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await UserManagementApiAxiosParamCreator(configuration).updateUser(id, updateUserRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UserManagementApi - factory interface
 * @export
 */
export const UserManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create User
         * @param {CreateUserRequest} createUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(createUserRequest: CreateUserRequest, options?: any): AxiosPromise<User> {
            return UserManagementApiFp(configuration).createUser(createUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get User By Email
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserByEmail(email: string, options?: any): AxiosPromise<User> {
            return UserManagementApiFp(configuration).getUserByEmail(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get User By ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById(id: string, options?: any): AxiosPromise<User> {
            return UserManagementApiFp(configuration).getUserById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get All Users
         * @param {string} [pageToken] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(pageToken?: string, limit?: number, options?: any): AxiosPromise<UserPage> {
            return UserManagementApiFp(configuration).getUsers(pageToken, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update User
         * @param {string} id 
         * @param {UpdateUserRequest} updateUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(id: string, updateUserRequest: UpdateUserRequest, options?: any): AxiosPromise<User> {
            return UserManagementApiFp(configuration).updateUser(id, updateUserRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserManagementApi - object-oriented interface
 * @export
 * @class UserManagementApi
 * @extends {BaseAPI}
 */
export class UserManagementApi extends BaseAPI {
    /**
     * 
     * @summary Create User
     * @param {CreateUserRequest} createUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public createUser(createUserRequest: CreateUserRequest, options?: any) {
        return UserManagementApiFp(this.configuration).createUser(createUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get User By Email
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public getUserByEmail(email: string, options?: any) {
        return UserManagementApiFp(this.configuration).getUserByEmail(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get User By ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public getUserById(id: string, options?: any) {
        return UserManagementApiFp(this.configuration).getUserById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get All Users
     * @param {string} [pageToken] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public getUsers(pageToken?: string, limit?: number, options?: any) {
        return UserManagementApiFp(this.configuration).getUsers(pageToken, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update User
     * @param {string} id 
     * @param {UpdateUserRequest} updateUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public updateUser(id: string, updateUserRequest: UpdateUserRequest, options?: any) {
        return UserManagementApiFp(this.configuration).updateUser(id, updateUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * UserTransactionHistoryApi - axios parameter creator
 * @export
 */
export const UserTransactionHistoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateTransactionHistory} createTransactionHistory 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerTransaction: async (createTransactionHistory: CreateTransactionHistory, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTransactionHistory' is not null or undefined
            if (createTransactionHistory === null || createTransactionHistory === undefined) {
                throw new RequiredError('createTransactionHistory','Required parameter createTransactionHistory was null or undefined when calling registerTransaction.');
            }
            const localVarPath = `/transactions-history/_create`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createTransactionHistory !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createTransactionHistory !== undefined ? createTransactionHistory : {}) : (createTransactionHistory || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserTransactionHistoryApi - functional programming interface
 * @export
 */
export const UserTransactionHistoryApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateTransactionHistory} createTransactionHistory 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerTransaction(createTransactionHistory: CreateTransactionHistory, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionHistory>> {
            const localVarAxiosArgs = await UserTransactionHistoryApiAxiosParamCreator(configuration).registerTransaction(createTransactionHistory, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UserTransactionHistoryApi - factory interface
 * @export
 */
export const UserTransactionHistoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {CreateTransactionHistory} createTransactionHistory 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerTransaction(createTransactionHistory: CreateTransactionHistory, options?: any): AxiosPromise<TransactionHistory> {
            return UserTransactionHistoryApiFp(configuration).registerTransaction(createTransactionHistory, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserTransactionHistoryApi - object-oriented interface
 * @export
 * @class UserTransactionHistoryApi
 * @extends {BaseAPI}
 */
export class UserTransactionHistoryApi extends BaseAPI {
    /**
     * 
     * @param {CreateTransactionHistory} createTransactionHistory 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserTransactionHistoryApi
     */
    public registerTransaction(createTransactionHistory: CreateTransactionHistory, options?: any) {
        return UserTransactionHistoryApiFp(this.configuration).registerTransaction(createTransactionHistory, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * WebhooksApi - axios parameter creator
 * @export
 */
export const WebhooksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appStoreHook: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/webhooks/billing/_app-store`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playStoreHook: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/webhooks/billing/_play-store`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebhooksApi - functional programming interface
 * @export
 */
export const WebhooksApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appStoreHook(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await WebhooksApiAxiosParamCreator(configuration).appStoreHook(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async playStoreHook(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await WebhooksApiAxiosParamCreator(configuration).playStoreHook(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * WebhooksApi - factory interface
 * @export
 */
export const WebhooksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appStoreHook(options?: any): AxiosPromise<void> {
            return WebhooksApiFp(configuration).appStoreHook(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playStoreHook(options?: any): AxiosPromise<void> {
            return WebhooksApiFp(configuration).playStoreHook(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WebhooksApi - object-oriented interface
 * @export
 * @class WebhooksApi
 * @extends {BaseAPI}
 */
export class WebhooksApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public appStoreHook(options?: any) {
        return WebhooksApiFp(this.configuration).appStoreHook(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public playStoreHook(options?: any) {
        return WebhooksApiFp(this.configuration).playStoreHook(options).then((request) => request(this.axios, this.basePath));
    }

}


