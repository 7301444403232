import { render, staticRenderFns } from "./KnownOptionGroupView.vue?vue&type=template&id=3a0c6b9b&"
import script from "./KnownOptionGroupView.vue?vue&type=script&lang=ts&"
export * from "./KnownOptionGroupView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports