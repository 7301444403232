// tslint:disable
/**
 * User Codes
 * This Service is intented for handling Codes for Users
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AxonCode
 */
export interface AxonCode {
    /**
     * 
     * @type {string}
     * @memberof AxonCode
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AxonCode
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof AxonCode
     */
    status: AxonCodeStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AxonCode
     */
    redeemDate: string;
    /**
     * 
     * @type {string}
     * @memberof AxonCode
     */
    redeemUser: string;
    /**
     * 
     * @type {boolean}
     * @memberof AxonCode
     */
    unlocked: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AxonCode
     */
    transfer: boolean;
    /**
     * 
     * @type {number}
     * @memberof AxonCode
     */
    points: number;
    /**
     * 
     * @type {string}
     * @memberof AxonCode
     */
    notes: string;
    /**
     * 
     * @type {string}
     * @memberof AxonCode
     */
    channel: AxonCodeChannelEnum;
    /**
     * 
     * @type {number}
     * @memberof AxonCode
     */
    batchNo: number;
    /**
     * 
     * @type {string}
     * @memberof AxonCode
     */
    created: string;
    /**
     * 
     * @type {string}
     * @memberof AxonCode
     */
    modified: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AxonCodeStatusEnum {
    OK = 'OK',
    RESERVED = 'RESERVED',
    REDEEMED = 'REDEEMED',
    CANCELED = 'CANCELED'
}
/**
    * @export
    * @enum {string}
    */
export enum AxonCodeChannelEnum {
    NONE = 'NONE',
    WEB = 'WEB',
    MOBILE = 'MOBILE',
    RETAIL = 'RETAIL'
}

/**
 * 
 * @export
 * @interface CodesPage
 */
export interface CodesPage {
    /**
     * 
     * @type {Array<AxonCode>}
     * @memberof CodesPage
     */
    codes: Array<AxonCode>;
    /**
     * 
     * @type {number}
     * @memberof CodesPage
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof CodesPage
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof CodesPage
     */
    total: number;
}
/**
 * 
 * @export
 * @interface CreateAxonCodes
 */
export interface CreateAxonCodes {
    /**
     * 
     * @type {boolean}
     * @memberof CreateAxonCodes
     */
    unlocked: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateAxonCodes
     */
    points: number;
    /**
     * 
     * @type {number}
     * @memberof CreateAxonCodes
     */
    codes: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAxonCodes
     */
    transfer: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateAxonCodes
     */
    notes: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAxonCodes
     */
    channel: CreateAxonCodesChannelEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateAxonCodesChannelEnum {
    NONE = 'NONE',
    WEB = 'WEB',
    MOBILE = 'MOBILE',
    RETAIL = 'RETAIL'
}

/**
 * 
 * @export
 * @interface RedeemCodeRequest
 */
export interface RedeemCodeRequest {
    /**
     * 
     * @type {string}
     * @memberof RedeemCodeRequest
     */
    code: string;
}
/**
 * 
 * @export
 * @interface UpdateAxonCode
 */
export interface UpdateAxonCode {
    /**
     * 
     * @type {string}
     * @memberof UpdateAxonCode
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAxonCode
     */
    notes: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAxonCode
     */
    channel: UpdateAxonCodeChannelEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateAxonCodeChannelEnum {
    NONE = 'NONE',
    WEB = 'WEB',
    MOBILE = 'MOBILE',
    RETAIL = 'RETAIL'
}


/**
 * CodesApi - axios parameter creator
 * @export
 */
export const CodesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {RedeemCodeRequest} redeemCodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redeemCode: async (redeemCodeRequest: RedeemCodeRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'redeemCodeRequest' is not null or undefined
            if (redeemCodeRequest === null || redeemCodeRequest === undefined) {
                throw new RequiredError('redeemCodeRequest','Required parameter redeemCodeRequest was null or undefined when calling redeemCode.');
            }
            const localVarPath = `/codes/_redeem`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof redeemCodeRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(redeemCodeRequest !== undefined ? redeemCodeRequest : {}) : (redeemCodeRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CodesApi - functional programming interface
 * @export
 */
export const CodesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {RedeemCodeRequest} redeemCodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async redeemCode(redeemCodeRequest: RedeemCodeRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxonCode>> {
            const localVarAxiosArgs = await CodesApiAxiosParamCreator(configuration).redeemCode(redeemCodeRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CodesApi - factory interface
 * @export
 */
export const CodesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {RedeemCodeRequest} redeemCodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redeemCode(redeemCodeRequest: RedeemCodeRequest, options?: any): AxiosPromise<AxonCode> {
            return CodesApiFp(configuration).redeemCode(redeemCodeRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CodesApi - object-oriented interface
 * @export
 * @class CodesApi
 * @extends {BaseAPI}
 */
export class CodesApi extends BaseAPI {
    /**
     * 
     * @param {RedeemCodeRequest} redeemCodeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodesApi
     */
    public redeemCode(redeemCodeRequest: RedeemCodeRequest, options?: any) {
        return CodesApiFp(this.configuration).redeemCode(redeemCodeRequest, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * CodesManagementApi - axios parameter creator
 * @export
 */
export const CodesManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateAxonCodes} createAxonCodes 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateCodes: async (createAxonCodes: CreateAxonCodes, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAxonCodes' is not null or undefined
            if (createAxonCodes === null || createAxonCodes === undefined) {
                throw new RequiredError('createAxonCodes','Required parameter createAxonCodes was null or undefined when calling generateCodes.');
            }
            const localVarPath = `/codes/_generate`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createAxonCodes !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createAxonCodes !== undefined ? createAxonCodes : {}) : (createAxonCodes || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCode: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getCode.');
            }
            const localVarPath = `/codes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [code] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCodes: async (code?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/codes`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAxonCode} updateAxonCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCode: async (id: string, updateAxonCode: UpdateAxonCode, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateCode.');
            }
            // verify required parameter 'updateAxonCode' is not null or undefined
            if (updateAxonCode === null || updateAxonCode === undefined) {
                throw new RequiredError('updateAxonCode','Required parameter updateAxonCode was null or undefined when calling updateCode.');
            }
            const localVarPath = `/codes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateAxonCode !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateAxonCode !== undefined ? updateAxonCode : {}) : (updateAxonCode || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CodesManagementApi - functional programming interface
 * @export
 */
export const CodesManagementApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateAxonCodes} createAxonCodes 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateCodes(createAxonCodes: CreateAxonCodes, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CodesManagementApiAxiosParamCreator(configuration).generateCodes(createAxonCodes, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCode(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxonCode>> {
            const localVarAxiosArgs = await CodesManagementApiAxiosParamCreator(configuration).getCode(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [code] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCodes(code?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CodesPage>> {
            const localVarAxiosArgs = await CodesManagementApiAxiosParamCreator(configuration).listCodes(code, page, limit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAxonCode} updateAxonCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCode(id: string, updateAxonCode: UpdateAxonCode, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CodesPage>> {
            const localVarAxiosArgs = await CodesManagementApiAxiosParamCreator(configuration).updateCode(id, updateAxonCode, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CodesManagementApi - factory interface
 * @export
 */
export const CodesManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {CreateAxonCodes} createAxonCodes 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateCodes(createAxonCodes: CreateAxonCodes, options?: any): AxiosPromise<void> {
            return CodesManagementApiFp(configuration).generateCodes(createAxonCodes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCode(id: string, options?: any): AxiosPromise<AxonCode> {
            return CodesManagementApiFp(configuration).getCode(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [code] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCodes(code?: string, page?: number, limit?: number, options?: any): AxiosPromise<CodesPage> {
            return CodesManagementApiFp(configuration).listCodes(code, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAxonCode} updateAxonCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCode(id: string, updateAxonCode: UpdateAxonCode, options?: any): AxiosPromise<CodesPage> {
            return CodesManagementApiFp(configuration).updateCode(id, updateAxonCode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CodesManagementApi - object-oriented interface
 * @export
 * @class CodesManagementApi
 * @extends {BaseAPI}
 */
export class CodesManagementApi extends BaseAPI {
    /**
     * 
     * @param {CreateAxonCodes} createAxonCodes 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodesManagementApi
     */
    public generateCodes(createAxonCodes: CreateAxonCodes, options?: any) {
        return CodesManagementApiFp(this.configuration).generateCodes(createAxonCodes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodesManagementApi
     */
    public getCode(id: string, options?: any) {
        return CodesManagementApiFp(this.configuration).getCode(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [code] 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodesManagementApi
     */
    public listCodes(code?: string, page?: number, limit?: number, options?: any) {
        return CodesManagementApiFp(this.configuration).listCodes(code, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateAxonCode} updateAxonCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodesManagementApi
     */
    public updateCode(id: string, updateAxonCode: UpdateAxonCode, options?: any) {
        return CodesManagementApiFp(this.configuration).updateCode(id, updateAxonCode, options).then((request) => request(this.axios, this.basePath));
    }

}


