var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-card-actions',[_c('v-btn',{attrs:{"color":"secondary","text":"","small":"","to":{ name: 'known-option-group-create' }}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" New ")],1)],1),_c('v-divider'),_c('v-data-table',{attrs:{"headers":_vm.headers,"page":_vm.action.page,"pageCount":_vm.page.items / _vm.page.limit,"server-items-length":_vm.page.total,"items":_vm.page.groups,"items-per-page":_vm.action.limit,"search":_vm.search,"footer-props":{
        'items-per-page-options': [10, 20, 50],
      },"options":_vm.options},on:{"update:page":function($event){return _vm.$set(_vm.action, "page", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.action, "limit", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.action, "limit", $event)},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.groupId",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(item.groupId))])]}},{key:"item.created",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.created,"from", "now"))+" ")]}},{key:"item.updated",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.updated,"from", "now"))+" ")]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","text":""},on:{"click":function($event){return _vm.onItemClick(item)}}},[_c('v-icon',[_vm._v("mdi-eye")]),_vm._v(" View ")],1)]}}],null,true)})],1),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }