var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-card-actions',[_c('v-btn',{attrs:{"color":"secondary","text":"","small":"","to":{ name: 'known-option-create' }}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" New ")],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-combobox',{attrs:{"color":"secondary","item-color":"secondary","items":_vm.featureSets,"label":"Feature Set","multiple":"","clearable":"","outlined":"","dense":""},model:{value:(_vm.searchFeatureSets),callback:function ($$v) {_vm.searchFeatureSets=$$v},expression:"searchFeatureSets"}})],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-combobox',{attrs:{"color":"secondary","item-color":"secondary","items":_vm.groups,"label":"Group","multiple":"","clearable":"","outlined":"","dense":""},model:{value:(_vm.searchGroups),callback:function ($$v) {_vm.searchGroups=$$v},expression:"searchGroups"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.searchAction}},[_vm._v("Search")])],1)],1)],1),_c('v-divider'),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.page.options,"page":_vm.action.page,"search":_vm.search,"items-per-page":_vm.action.limit,"server-items-length":_vm.page.total,"footer-props":{
        'items-per-page-options': [10, 20, 50],
      },"options":_vm.options,"item-key":"knownOptionId","show-expand":""},on:{"update:page":function($event){return _vm.$set(_vm.action, "page", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.action, "limit", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.action, "limit", $event)},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
      var item = ref.item;
return [_c('span',[_c('v-icon',{attrs:{"small":"","color":item.visible ? '' : 'grey darken-3'}},[_vm._v("mdi-eye ")])],1),_c('span',{staticClass:"ml-2"},[_c('v-icon',{attrs:{"small":"","color":item.reusable ? '' : 'grey darken-3'}},[_vm._v(" mdi-recycle ")])],1),_c('span',{staticClass:"mx-2"},[_c('v-icon',{attrs:{"small":"","color":item.queryable ? '' : 'grey darken-3'}},[_vm._v(" mdi-magnify ")])],1),_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.sku",fn:function(ref){
      var item = ref.item;
return [_c('code',[_vm._v(_vm._s(item.sku))])]}},{key:"expanded-item",fn:function(ref){
      var headers = ref.headers;
      var item = ref.item;
return [_c('td',{staticClass:"dark ma-1 pa-1",attrs:{"colspan":headers.length,"no-gutters":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-card',{attrs:{"elevation":"0","light":""}},[_c('v-card-title',{staticClass:"font-weight-bold"},[_vm._v("Details "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"primary","to":{
                      name: 'known-option-edit',
                      params: { id: item.knownOptionId },
                    }}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.deleteOption(item)}}},[_c('v-icon',[_vm._v(" mdi-trash-can ")])],1)],1),_c('v-card-text',[_c('KnownOptionDisplay',{attrs:{"item":item}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"9","no-gutters":""}},[_c('v-card',{staticClass:"ml-1",attrs:{"elevation":0,"light":""}},[_c('v-card-title',{staticClass:"font-weight-bold"},[_vm._v("Commands "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":"","to":{
                      name: 'known-option-add-command',
                      params: { id: item.knownOptionId },
                    }}},[_c('v-icon',[_vm._v(" mdi-plus ")]),_vm._v(" New ")],1)],1),_c('v-card-text',{staticClass:"pl-0"},[_c('KnownOptionCommandsDisplay',{attrs:{"knownOptionId":item.knownOptionId,"commands":item.optionCommands}})],1)],1)],1)],1)],1)]}},{key:"item.created",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.created,"from", "now"))+" ")]}},{key:"item.updated",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.updated,"from", "now"))+" ")]}},{key:"item.knownOptionId",fn:function(ref){
                    var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(item.knownOptionId))])]}},{key:"item.featureSetId",fn:function(ref){
                    var item = ref.item;
return [_c('v-chip',{staticClass:"black--text",attrs:{"small":"","color":"secondary"},domProps:{"textContent":_vm._s(_vm.getFeatureSetName(item.featureSetId))}})]}},{key:"item.groupId",fn:function(ref){
                    var item = ref.item;
return [_c('v-chip',{staticClass:"black--text",attrs:{"small":"","color":"secondary"},domProps:{"textContent":_vm._s(_vm.getGroupName(item.groupId))}})]}}],null,true)})],1),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }