














































import { Component, Vue } from "vue-property-decorator";
import { DevicesModule } from "@/store/modules/devices";
import { VForm } from "@/types";

@Component({ name: "SendDeviceMessageFormComponent" })
export default class SendDeviceMessageFormComponent extends Vue {
  dialog = true;
  message = "";
  subject = "";

  async onSend() {
    if ((this.$refs.form as VForm).validate()) {
      await DevicesModule.sendMessage({
        id: this.$route.params.id,
        subject: this.subject,
        message: this.message,
      });
      this.$router.go(-1);
    }
  }
}
