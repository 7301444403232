















import { Component, Prop, Vue } from "vue-property-decorator";
import { KnownOption } from "@/apis/axon_configurations";
import moment from "moment";

@Component({
  name: "KnownOptionsDisplayComponent",
})
export default class KnownOptionsDisplayComponent extends Vue {
  @Prop() item: KnownOption | undefined;

  get fields() {
    if (!this.item) return [];
    return [
      {
        text: "ID",
        value: this.item.knownOptionId,
      },
      {
        text: "Code",
        value: this.item.code,
      },
      { text: "SKU", value: this.item.sku },
      {
        text: "Name",
        value: this.item.name,
      },
      { text: "Youtube Video Url", value: this.item.youtubeVideoUrl },

      {
        text: "Description",
        value: this.item.description,
      },
      {
        text: "Notes",
        value: this.item.notes,
      },
      {
        text: "Visible",
        value: this.item.visible,
      },
      {
        text: "Points",
        value: this.item.points,
      },
      {
        text: "Created",
        value: moment(this.item.created).fromNow(),
      },
      {
        text: "Updated",
        value: moment(this.item.updated).fromNow(),
      },
    ];
  }
}
