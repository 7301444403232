























































import { Component, Vue } from "vue-property-decorator";
import { KnownOptionGroup } from "@/apis/axon_configurations";
import { router } from "@/router";
import { KnownOptionsGroupsModule } from "@/store/modules/known-option-groups";
import { VForm } from "@/types";

@Component({ name: "KnownOptionGroupForm" })
export default class KnownOptionGroupForm extends Vue {
  dialog = true;
  created() {
    if (this.$route.params.id) {
      KnownOptionsGroupsModule.fetchKnownOptionGroupById(this.$route.params.id);
    }
  }

  get model() {
    if (this.$route.params.id) {
      return KnownOptionsGroupsModule.group;
    }
    return {} as KnownOptionGroup;
  }

  async onSave() {
    if ((this.$refs.form as VForm).validate()) {
      await KnownOptionsGroupsModule.saveKnownOptionGroup(this.model);
      router.go(-1);
    }
  }
}
