import Vue from "vue";
import Vuetify, { UserVuetifyPreset } from "vuetify";

Vue.use(Vuetify);

const opts: UserVuetifyPreset = {
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: "#001C71",
        secondary: "#FEDE00",
        accent: "#2196f3",
        error: "#f44336",
        warning: "#ff5722",
        info: "#009688",
        success: "#4caf50",
      },
      light: {
        primary: "#001C71",
        secondary: "#FEDE00",
        accent: "#2196f3",
        error: "#f44336",
        warning: "#ff5722",
        info: "#009688",
        success: "#4caf50",
      },
    },
  },
};

export default new Vuetify(opts);
