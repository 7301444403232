



































































import { Component, Vue, Watch } from "vue-property-decorator";
import { AppMenu } from "./components/";
import { router } from "./router";
import { AuthModule } from "./store/modules/auth";
import { LoaderModule } from "./store/modules/loader";
@Component({
  name: "app",
  components: {
    AppMenu,
  },
})
export default class Home extends Vue {
  drawer = null;
  snackbar = false;

  created() {
    AuthModule.checkIfSignedIn();
  }

  get loading() {
    return LoaderModule.loading > 0;
  }

  get count() {
    if (LoaderModule.loading > 0) {
      return ((1 / LoaderModule.loading) * 100).toFixed(0) + "%";
    }
    return "";
  }

  get message() {
    return LoaderModule.message;
  }

  get user() {
    return AuthModule.currentUser;
  }

  @Watch("message")
  onMessage() {
    if (this.message) this.snackbar = true;
  }

  dismissSnackbar() {
    this.snackbar = false;
    LoaderModule.setMessage("");
  }

  login() {
    if (AuthModule.currentUser) {
      router.push({ name: "home" });
    } else {
      router.push({ path: "login" });
    }
  }

  logout() {
    AuthModule.doLogout();
    router.push({ name: "welcome" });
  }
}
