import {
  CreateOptionCommandValue,
  KnownOptionCommandValuesManagementApi,
  OptionCommandValue,
  UpdateOptionCommandValue,
} from "@/apis/axon_configurations";
import { getIDTokenFromCurrentUser } from "@/plugins/firebase";
import store from "@/store";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";

export interface OptionCommandValuesState {
  value: OptionCommandValue;
}

@Module({
  dynamic: true,
  namespaced: true,
  name: "KnownOptionCommandValues",
  store,
})
class KnownOptionCommandValues extends VuexModule {
  public value: OptionCommandValue = {} as OptionCommandValue;

  @Mutation
  setValue(value: OptionCommandValue) {
    this.value = value;
  }

  @Action
  async fetchCommandValueBy(id: string) {
    const token = await getIDTokenFromCurrentUser();
    const api = new KnownOptionCommandValuesManagementApi({
      basePath: "/api/configurations-service",
      accessToken: token,
    });
    try {
      this.context.commit("Loader/increase", null, { root: true });
      const value = (await api.getCommandValueById(id)).data;
      this.context.commit("setValue", value);
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }

  @Action
  async updateValue(model: UpdateOptionCommandValueAction) {
    const token = await getIDTokenFromCurrentUser();
    const api = new KnownOptionCommandValuesManagementApi({
      basePath: "/api/configurations-service",
      accessToken: token,
    });
    try {
      this.context.commit("Loader/increase", null, { root: true });
      const { data } = await api.updateCommandValue(model.valueId, model);
      this.context.commit("setValue", data);
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }

  @Action
  async saveValue(form: CreateOptionCommandValue) {
    const token = await getIDTokenFromCurrentUser();
    const api = new KnownOptionCommandValuesManagementApi({
      basePath: "/api/configurations-service",
      accessToken: token,
    });
    try {
      this.context.commit("Loader/increase", null, { root: true });
      const value = (await api.createCommandValue(form)).data;
      this.context.commit("setValue", value);
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }

  @Action
  async deleteCommandValueById(valueId: string) {
    const token = await getIDTokenFromCurrentUser();
    const api = new KnownOptionCommandValuesManagementApi({
      basePath: "/api/configurations-service",
      accessToken: token,
    });
    try {
      this.context.commit("Loader/increase", null, { root: true });
      await api.deleteCommandValue(valueId);
      this.context.commit("setValue", { valueId: valueId });
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }
}

export interface UpdateOptionCommandValueAction
  extends UpdateOptionCommandValue {
  valueId: string;
}

export const KnownOptionCommandValuesModule = getModule(
  KnownOptionCommandValues
);
