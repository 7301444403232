










































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { CodesModule, ListCodesAction } from "@/store/modules/codes";
import {
  AxonCode,
  AxonCodeStatusEnum,
  CodesPage,
} from "@/apis/axon_user_codes";

@Component({ name: "Codes" })
export default class Codes extends Vue {
  search = "";
  action = new ListCodesAction();
  options = {};
  headers = [
    { text: "Code", value: "code", sortable: false },
    { text: "Channel", value: "channel", sortable: false },
    { text: "Type", value: "unlocked", sortable: false },
    { text: "Points", value: "points", sortable: false },
    { text: "Transfer", value: "transfer", sortable: false },
    { text: "Notes", value: "notes", sortable: false },
    { text: "Batch NO", value: "batchNo", sortable: false },
    { text: "Status", value: "status", sortable: false },
    { text: "Created", value: "created", sortable: false },
    { text: "Updated", value: "modified", sortable: false },
    { text: "Redeem User", value: "redeemUser", sortable: false },
    { text: "Redeem Date", value: "redeemDate", sortable: false },
    { text: "Actions", value: "actions", sortable: false },
  ];

  @Watch("options")
  optionsChanged() {
    this.searchRemote();
  }

  @Watch("reload")
  reloadData() {
    this.searchRemote();
  }

  get reload() {
    return CodesModule.reload;
  }

  get page() {
    return CodesModule.page as CodesPage;
  }

  searchRemote() {
    CodesModule.fetchCodes(this.action);
  }

  async reserveCode(item: AxonCode) {
    item.status = AxonCodeStatusEnum.RESERVED;
    await CodesModule.updateCode(item);
  }
}
