import App from "@/App.vue";
import { vuetify } from "@/plugins";
import { router } from "@/router";
import store from "@/store";
import Vue from "vue";

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
