// tslint:disable
/**
 * User Capabilities
 * This Service is intented for operations withing User Capabilities
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface RegisterOptionAction
 */
export interface RegisterOptionAction {
    /**
     * 
     * @type {string}
     * @memberof RegisterOptionAction
     */
    optionCode: string;
    /**
     * 
     * @type {number}
     * @memberof RegisterOptionAction
     */
    points: number;
}
/**
 * 
 * @export
 * @interface RequestCapabilityAction
 */
export interface RequestCapabilityAction {
    /**
     * 
     * @type {string}
     * @memberof RequestCapabilityAction
     */
    codeId?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestCapabilityAction
     */
    transactionId?: string;
    /**
     * 
     * @type {number}
     * @memberof RequestCapabilityAction
     */
    points: number;
    /**
     * 
     * @type {boolean}
     * @memberof RequestCapabilityAction
     */
    unlocked: boolean;
}
/**
 * 
 * @export
 * @interface UpdateUserCapabilities
 */
export interface UpdateUserCapabilities {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserCapabilities
     */
    unlocked: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateUserCapabilities
     */
    points: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateUserCapabilities
     */
    allowedOptions: Array<string>;
}
/**
 * 
 * @export
 * @interface UserCapabilities
 */
export interface UserCapabilities {
    /**
     * 
     * @type {string}
     * @memberof UserCapabilities
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserCapabilities
     */
    unlocked: boolean;
    /**
     * 
     * @type {number}
     * @memberof UserCapabilities
     */
    points: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserCapabilities
     */
    allowedOptions: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UserCapabilities
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCapabilities
     */
    modified?: string;
}
/**
 * 
 * @export
 * @interface UserCapabilitiesHistory
 */
export interface UserCapabilitiesHistory {
    /**
     * 
     * @type {string}
     * @memberof UserCapabilitiesHistory
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserCapabilitiesHistory
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof UserCapabilitiesHistory
     */
    source: string;
    /**
     * 
     * @type {string}
     * @memberof UserCapabilitiesHistory
     */
    sourceType: string;
    /**
     * 
     * @type {string}
     * @memberof UserCapabilitiesHistory
     */
    action: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserCapabilitiesHistory
     */
    unlocked: boolean;
    /**
     * 
     * @type {number}
     * @memberof UserCapabilitiesHistory
     */
    points: number;
    /**
     * 
     * @type {number}
     * @memberof UserCapabilitiesHistory
     */
    balanceAfter: number;
    /**
     * 
     * @type {number}
     * @memberof UserCapabilitiesHistory
     */
    balanceBefore: number;
    /**
     * 
     * @type {string}
     * @memberof UserCapabilitiesHistory
     */
    created: string;
    /**
     * 
     * @type {string}
     * @memberof UserCapabilitiesHistory
     */
    modified: string;
}

/**
 * UserCapabilitiesApi - axios parameter creator
 * @export
 */
export const UserCapabilitiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCapabilities: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/capabilities/_current-user`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterOptionAction} registerOptionAction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerSelectedOption: async (registerOptionAction: RegisterOptionAction, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerOptionAction' is not null or undefined
            if (registerOptionAction === null || registerOptionAction === undefined) {
                throw new RequiredError('registerOptionAction','Required parameter registerOptionAction was null or undefined when calling registerSelectedOption.');
            }
            const localVarPath = `/capabilities/_register-option`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof registerOptionAction !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(registerOptionAction !== undefined ? registerOptionAction : {}) : (registerOptionAction || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RequestCapabilityAction} requestCapabilityAction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestUpdateCapabilities: async (requestCapabilityAction: RequestCapabilityAction, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestCapabilityAction' is not null or undefined
            if (requestCapabilityAction === null || requestCapabilityAction === undefined) {
                throw new RequiredError('requestCapabilityAction','Required parameter requestCapabilityAction was null or undefined when calling requestUpdateCapabilities.');
            }
            const localVarPath = `/capabilities/_request-update`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestCapabilityAction !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestCapabilityAction !== undefined ? requestCapabilityAction : {}) : (requestCapabilityAction || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserCapabilitiesApi - functional programming interface
 * @export
 */
export const UserCapabilitiesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCapabilities(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCapabilities>> {
            const localVarAxiosArgs = await UserCapabilitiesApiAxiosParamCreator(configuration).getCapabilities(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {RegisterOptionAction} registerOptionAction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerSelectedOption(registerOptionAction: RegisterOptionAction, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCapabilities>> {
            const localVarAxiosArgs = await UserCapabilitiesApiAxiosParamCreator(configuration).registerSelectedOption(registerOptionAction, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {RequestCapabilityAction} requestCapabilityAction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestUpdateCapabilities(requestCapabilityAction: RequestCapabilityAction, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCapabilities>> {
            const localVarAxiosArgs = await UserCapabilitiesApiAxiosParamCreator(configuration).requestUpdateCapabilities(requestCapabilityAction, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UserCapabilitiesApi - factory interface
 * @export
 */
export const UserCapabilitiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCapabilities(options?: any): AxiosPromise<UserCapabilities> {
            return UserCapabilitiesApiFp(configuration).getCapabilities(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RegisterOptionAction} registerOptionAction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerSelectedOption(registerOptionAction: RegisterOptionAction, options?: any): AxiosPromise<UserCapabilities> {
            return UserCapabilitiesApiFp(configuration).registerSelectedOption(registerOptionAction, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RequestCapabilityAction} requestCapabilityAction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestUpdateCapabilities(requestCapabilityAction: RequestCapabilityAction, options?: any): AxiosPromise<UserCapabilities> {
            return UserCapabilitiesApiFp(configuration).requestUpdateCapabilities(requestCapabilityAction, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserCapabilitiesApi - object-oriented interface
 * @export
 * @class UserCapabilitiesApi
 * @extends {BaseAPI}
 */
export class UserCapabilitiesApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserCapabilitiesApi
     */
    public getCapabilities(options?: any) {
        return UserCapabilitiesApiFp(this.configuration).getCapabilities(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegisterOptionAction} registerOptionAction 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserCapabilitiesApi
     */
    public registerSelectedOption(registerOptionAction: RegisterOptionAction, options?: any) {
        return UserCapabilitiesApiFp(this.configuration).registerSelectedOption(registerOptionAction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RequestCapabilityAction} requestCapabilityAction 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserCapabilitiesApi
     */
    public requestUpdateCapabilities(requestCapabilityAction: RequestCapabilityAction, options?: any) {
        return UserCapabilitiesApiFp(this.configuration).requestUpdateCapabilities(requestCapabilityAction, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * UserCapabilitiesHistoryApi - axios parameter creator
 * @export
 */
export const UserCapabilitiesHistoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCapabilitiesHistoryById: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getUserCapabilitiesHistoryById.');
            }
            const localVarPath = `/capabilities/{userId}/_history`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserCapabilitiesHistoryApi - functional programming interface
 * @export
 */
export const UserCapabilitiesHistoryApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserCapabilitiesHistoryById(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserCapabilitiesHistory>>> {
            const localVarAxiosArgs = await UserCapabilitiesHistoryApiAxiosParamCreator(configuration).getUserCapabilitiesHistoryById(userId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UserCapabilitiesHistoryApi - factory interface
 * @export
 */
export const UserCapabilitiesHistoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCapabilitiesHistoryById(userId: string, options?: any): AxiosPromise<Array<UserCapabilitiesHistory>> {
            return UserCapabilitiesHistoryApiFp(configuration).getUserCapabilitiesHistoryById(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserCapabilitiesHistoryApi - object-oriented interface
 * @export
 * @class UserCapabilitiesHistoryApi
 * @extends {BaseAPI}
 */
export class UserCapabilitiesHistoryApi extends BaseAPI {
    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserCapabilitiesHistoryApi
     */
    public getUserCapabilitiesHistoryById(userId: string, options?: any) {
        return UserCapabilitiesHistoryApiFp(this.configuration).getUserCapabilitiesHistoryById(userId, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * UserCapabilitiesManagementApi - axios parameter creator
 * @export
 */
export const UserCapabilitiesManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCapabilitiesById: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getUserCapabilitiesById.');
            }
            const localVarPath = `/capabilities/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {UpdateUserCapabilities} updateUserCapabilities 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserCapabilitiesById: async (userId: string, updateUserCapabilities: UpdateUserCapabilities, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling updateUserCapabilitiesById.');
            }
            // verify required parameter 'updateUserCapabilities' is not null or undefined
            if (updateUserCapabilities === null || updateUserCapabilities === undefined) {
                throw new RequiredError('updateUserCapabilities','Required parameter updateUserCapabilities was null or undefined when calling updateUserCapabilitiesById.');
            }
            const localVarPath = `/capabilities/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateUserCapabilities !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateUserCapabilities !== undefined ? updateUserCapabilities : {}) : (updateUserCapabilities || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserCapabilitiesManagementApi - functional programming interface
 * @export
 */
export const UserCapabilitiesManagementApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserCapabilitiesById(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCapabilities>> {
            const localVarAxiosArgs = await UserCapabilitiesManagementApiAxiosParamCreator(configuration).getUserCapabilitiesById(userId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {UpdateUserCapabilities} updateUserCapabilities 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserCapabilitiesById(userId: string, updateUserCapabilities: UpdateUserCapabilities, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCapabilities>> {
            const localVarAxiosArgs = await UserCapabilitiesManagementApiAxiosParamCreator(configuration).updateUserCapabilitiesById(userId, updateUserCapabilities, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UserCapabilitiesManagementApi - factory interface
 * @export
 */
export const UserCapabilitiesManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCapabilitiesById(userId: string, options?: any): AxiosPromise<UserCapabilities> {
            return UserCapabilitiesManagementApiFp(configuration).getUserCapabilitiesById(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {UpdateUserCapabilities} updateUserCapabilities 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserCapabilitiesById(userId: string, updateUserCapabilities: UpdateUserCapabilities, options?: any): AxiosPromise<UserCapabilities> {
            return UserCapabilitiesManagementApiFp(configuration).updateUserCapabilitiesById(userId, updateUserCapabilities, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserCapabilitiesManagementApi - object-oriented interface
 * @export
 * @class UserCapabilitiesManagementApi
 * @extends {BaseAPI}
 */
export class UserCapabilitiesManagementApi extends BaseAPI {
    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserCapabilitiesManagementApi
     */
    public getUserCapabilitiesById(userId: string, options?: any) {
        return UserCapabilitiesManagementApiFp(this.configuration).getUserCapabilitiesById(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {UpdateUserCapabilities} updateUserCapabilities 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserCapabilitiesManagementApi
     */
    public updateUserCapabilitiesById(userId: string, updateUserCapabilities: UpdateUserCapabilities, options?: any) {
        return UserCapabilitiesManagementApiFp(this.configuration).updateUserCapabilitiesById(userId, updateUserCapabilities, options).then((request) => request(this.axios, this.basePath));
    }

}


