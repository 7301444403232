





























































































import { Component, PropSync, Vue, Watch } from "vue-property-decorator";

@Component({ name: "AddressMasker" })
export default class AddressMasker extends Vue {
  @PropSync("selectedValueSize", { required: true })
  selectedValueSizeSync!: number;
  @PropSync("selectedStaticValues", { required: true })
  selectedStaticValuesSync!: string[];
  @PropSync("selectedDynamicValues", { required: true })
  selectedDynamicValuesSync!: string[];
  @PropSync("generatedMask", { required: true })
  generatedMaskSync!: string[];

  get addressSizes() {
    return [
      { size: 2, label: "2 Bytes" },
      { size: 4, label: "4 Bytes" },
      { size: 6, label: "6 Bytes" },
    ];
  }
  get dict() {
    return ["X", "*", "S", "%"];
  }
  get staticValues() {
    return [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
    ];
  }

  get dynamicValues() {
    return [
      "N0_SpeedSyncV1",
      "N1_SpeedSyncV1",
      "N2_SpeedSyncV1",
      "N3_SpeedSyncV1",
      "N0_SpeedSyncV2",
      "N1_SpeedSyncV2",
      "N2_SpeedSyncV2",
      "N3_SpeedSyncV2",
    ];
  }

  toggleNibble(index: number) {
    const posInDict = this.dict.indexOf(this.generatedMaskSync[index]) + 1;
    if (posInDict >= this.dict.length) {
      this.generatedMaskSync.splice(index, 1, this.dict[0]);
    } else {
      this.generatedMaskSync.splice(index, 1, this.dict[posInDict]);
    }
  }

  mounted() {
    this.onByteSizeChanged();
  }

  @Watch("selectedValueSize")
  onByteSizeChanged() {
    if (this.generatedMaskSync.length != this.selectedValueSizeSync * 2) {
      this.generatedMaskSync.splice(0, this.generatedMaskSync.length);
      for (let i = 0; i < this.selectedValueSizeSync * 2; i++) {
        this.generatedMaskSync.push("X");
      }
    }
  }

  get resultMask() {
    const res: string[] = [];
    for (let i = 0; i < this.generatedMaskSync.length; i++) {
      const v = this.generatedMaskSync[i];
      if (v == "S") res.push(String(this.selectedStaticValuesSync[i] ?? "S"));
      else res.push(v);
      if ((i + 1) % 4 == 0) res.push(" ");
    }
    return res.join("");
  }

  getNibbleColor(type: string) {
    if (type == "S") return "blue";
    if (type == "%") return "green";
    if (type == "*") return "orange";
    if (type == "X") return "primary darken-1";
    return "";
  }
}
