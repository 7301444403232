
















































































































































































import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import { OptionCommand, OptionCommandValue } from "@/apis/axon_configurations";
import { KnownOptionCommandValuesModule } from "@/store/modules/known-option-command-values";
import { KnownOptionCommandsModule } from "@/store/modules/known-option-commands";
import draggable from "vuedraggable";
import moment from "moment";

@Component({
  name: "KnownOptionsDisplayComponent",
  components: {
    draggable,
  },
})
export default class KnownOptionsDisplayComponent extends Vue {
  @Prop() knownOptionId: string | undefined;
  @PropSync("commands") commandsSynced!: OptionCommand[];
  item = 0;

  @Watch("item")
  onItemChanged() {
    if (this.item == undefined) this.item = 0;
  }

  get selectedCommand() {
    if (this.commandsSynced && this.commandsSynced.length > 0) {
      return this.commandsSynced[this.item ?? 0];
    }
    return null;
  }

  get commandFields() {
    if (!this.commandsSynced || this.commandsSynced.length == 0) {
      return [];
    }
    const c = this.commandsSynced[this.item];
    return [
      { text: "ID", value: c.commandId },
      {
        text: "Address-Block-Line",
        value: c.address + "-" + c.block + "-" + c.line,
      },

      { text: "Module", value: c.module },
      {
        text: "Value Template",
        value: c.valueTemplate ? this.getInterpretedValue(c) : "N/A",
      },
      { text: "User Input", value: c.userInput ? "Yes" : "No" },
      { text: "Input Label", value: c.inputLabel },
      { text: "Control Type", value: c.controlType },
      { text: "Security Index", value: c.securityKey },
      { text: "Created", value: moment(c.created).fromNow() },
      { text: "Updated", value: moment(c.updated).fromNow() },
    ];
  }

  async deleteCommandValue(value: OptionCommandValue) {
    if (confirm(`Are you sure to delete value "${value.label}"?`)) {
      await KnownOptionCommandValuesModule.deleteCommandValueById(
        value.valueId
      );
    }
  }

  async deleteCommand(command: OptionCommand) {
    if (confirm(`Are you sure to delete command "${command.address}"?`)) {
      await KnownOptionCommandsModule.deleteCommandById(command.commandId);
      this.item = 0;
    }
  }

  getInterpretedValue(item: OptionCommand, value?: OptionCommandValue) {
    const res: string[] = [];
    item.valueTemplate.forEach((x, i) => {
      if (x == "S") res.push(item.staticValueVariables[i]);
      else if (x == "*" && value) res.push(value.valueHex.charAt(i) ?? "?");
      else res.push(x);
      if ((i + 1) % 4 == 0) res.push(" ");
    });
    return res.join("");
  }

  _maskValue(
    v: number,
    byte: number,
    nibble: number,
    userInput: boolean,
    value?: OptionCommandValue
  ) {
    const mask = v
      .toString(16)
      .toUpperCase()
      .padStart(2, "X")
      .replace("0", "X");
    const result = [mask.charAt(0), mask.charAt(1)];
    if (byte * 2 - 1 == nibble + 1 && userInput) {
      result[1] = value?.valueHex ?? "*";
    } else if (byte * 2 == nibble + 1 && userInput) {
      result[0] = value?.valueHex ?? "*";
    } else if (byte * 2 - 1 == nibble + 1) {
      result[1] = "0";
    } else if (byte * 2 == nibble + 1) {
      result[0] = "0";
    }
    return result.join("");
  }

  changeCommandOrder(event: {
    moved: { element: OptionCommand; newIndex: number; oldIndex: number };
  }) {
    const command = this.commandsSynced[event.moved.oldIndex];
    const swapped = this.commandsSynced[event.moved.newIndex];
    swapped.commandOrder = command.commandOrder;
    command.commandOrder = event.moved.newIndex;
    KnownOptionCommandsModule.updateCommand(swapped);
    KnownOptionCommandsModule.updateCommand(command);
  }

  changeValueOrder() {
    const command = this.selectedCommand;
    if (command) {
      command.commandValues.forEach((v, i) => {
        if (v.valueOrder != i) {
          v.valueOrder = i;
          KnownOptionCommandValuesModule.updateValue(v);
        }
      });
    }
  }
}
