// tslint:disable
/**
 * Diagnostics
 * This Service is intented for handling Diagnostic Data
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface DiagnosticDetail
 */
export interface DiagnosticDetail {
    /**
     * 
     * @type {string}
     * @memberof DiagnosticDetail
     */
    started: string;
    /**
     * 
     * @type {string}
     * @memberof DiagnosticDetail
     */
    ended: string;
    /**
     * 
     * @type {string}
     * @memberof DiagnosticDetail
     */
    status: DiagnosticDetailStatusEnum;
    /**
     * 
     * @type {Array<DiagnosticsLine>}
     * @memberof DiagnosticDetail
     */
    lines: Array<DiagnosticsLine>;
}

/**
    * @export
    * @enum {string}
    */
export enum DiagnosticDetailStatusEnum {
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED'
}

/**
 * 
 * @export
 * @interface DiagnosticTrend
 */
export interface DiagnosticTrend {
    /**
     * 
     * @type {number}
     * @memberof DiagnosticTrend
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof DiagnosticTrend
     */
    date: string;
}
/**
 * 
 * @export
 * @interface DiagnosticsData
 */
export interface DiagnosticsData {
    /**
     * 
     * @type {string}
     * @memberof DiagnosticsData
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof DiagnosticsData
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof DiagnosticsData
     */
    event?: string;
    /**
     * 
     * @type {string}
     * @memberof DiagnosticsData
     */
    vin?: string;
    /**
     * 
     * @type {string}
     * @memberof DiagnosticsData
     */
    deviceId?: string;
    /**
     * 
     * @type {string}
     * @memberof DiagnosticsData
     */
    deviceVersion?: string;
    /**
     * 
     * @type {string}
     * @memberof DiagnosticsData
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof DiagnosticsData
     */
    message: string;
    /**
     * 
     * @type {Array<DiagnosticDetail>}
     * @memberof DiagnosticsData
     */
    details: Array<DiagnosticDetail>;
    /**
     * 
     * @type {string}
     * @memberof DiagnosticsData
     */
    executionDate?: string;
    /**
     * 
     * @type {string}
     * @memberof DiagnosticsData
     */
    registrationDate: string;
}
/**
 * 
 * @export
 * @interface DiagnosticsDataPage
 */
export interface DiagnosticsDataPage {
    /**
     * 
     * @type {Array<DiagnosticsData>}
     * @memberof DiagnosticsDataPage
     */
    docs: Array<DiagnosticsData>;
    /**
     * 
     * @type {number}
     * @memberof DiagnosticsDataPage
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof DiagnosticsDataPage
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof DiagnosticsDataPage
     */
    page: number;
}
/**
 * 
 * @export
 * @interface DiagnosticsLine
 */
export interface DiagnosticsLine {
    /**
     * 
     * @type {InputParam}
     * @memberof DiagnosticsLine
     */
    input: InputParam;
    /**
     * 
     * @type {OutputParam}
     * @memberof DiagnosticsLine
     */
    output: OutputParam;
}
/**
 * 
 * @export
 * @interface InputParam
 */
export interface InputParam {
    /**
     * 
     * @type {string}
     * @memberof InputParam
     */
    payload: string;
}
/**
 * 
 * @export
 * @interface OutputParam
 */
export interface OutputParam {
    /**
     * 
     * @type {string}
     * @memberof OutputParam
     */
    response: string;
}

/**
 * DiagnosticsApi - axios parameter creator
 * @export
 */
export const DiagnosticsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DiagnosticsData} diagnosticsData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDiagnostics: async (diagnosticsData: DiagnosticsData, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'diagnosticsData' is not null or undefined
            if (diagnosticsData === null || diagnosticsData === undefined) {
                throw new RequiredError('diagnosticsData','Required parameter diagnosticsData was null or undefined when calling createDiagnostics.');
            }
            const localVarPath = `/diagnostics`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof diagnosticsData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(diagnosticsData !== undefined ? diagnosticsData : {}) : (diagnosticsData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDiagnostics: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteDiagnostics.');
            }
            const localVarPath = `/diagnostics/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} top 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatests: async (top: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'top' is not null or undefined
            if (top === null || top === undefined) {
                throw new RequiredError('top','Required parameter top was null or undefined when calling getLatests.');
            }
            const localVarPath = `/diagnostics/_latest`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (top !== undefined) {
                localVarQueryParameter['top'] = top;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [userId] 
         * @param {string} [vin] 
         * @param {string} [deviceId] 
         * @param {string} [email] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchDiagnostics: async (userId?: string, vin?: string, deviceId?: string, email?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/diagnostics/_search`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (vin !== undefined) {
                localVarQueryParameter['vin'] = vin;
            }

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} lastDays 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trendsByDays: async (lastDays: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'lastDays' is not null or undefined
            if (lastDays === null || lastDays === undefined) {
                throw new RequiredError('lastDays','Required parameter lastDays was null or undefined when calling trendsByDays.');
            }
            const localVarPath = `/diagnostics/_trends`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lastDays !== undefined) {
                localVarQueryParameter['lastDays'] = lastDays;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DiagnosticsApi - functional programming interface
 * @export
 */
export const DiagnosticsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DiagnosticsData} diagnosticsData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDiagnostics(diagnosticsData: DiagnosticsData, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiagnosticsData>> {
            const localVarAxiosArgs = await DiagnosticsApiAxiosParamCreator(configuration).createDiagnostics(diagnosticsData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDiagnostics(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DiagnosticsApiAxiosParamCreator(configuration).deleteDiagnostics(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} top 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLatests(top: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DiagnosticsData>>> {
            const localVarAxiosArgs = await DiagnosticsApiAxiosParamCreator(configuration).getLatests(top, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [userId] 
         * @param {string} [vin] 
         * @param {string} [deviceId] 
         * @param {string} [email] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchDiagnostics(userId?: string, vin?: string, deviceId?: string, email?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiagnosticsDataPage>> {
            const localVarAxiosArgs = await DiagnosticsApiAxiosParamCreator(configuration).searchDiagnostics(userId, vin, deviceId, email, page, limit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} lastDays 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trendsByDays(lastDays: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DiagnosticTrend>>> {
            const localVarAxiosArgs = await DiagnosticsApiAxiosParamCreator(configuration).trendsByDays(lastDays, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DiagnosticsApi - factory interface
 * @export
 */
export const DiagnosticsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {DiagnosticsData} diagnosticsData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDiagnostics(diagnosticsData: DiagnosticsData, options?: any): AxiosPromise<DiagnosticsData> {
            return DiagnosticsApiFp(configuration).createDiagnostics(diagnosticsData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDiagnostics(id: string, options?: any): AxiosPromise<void> {
            return DiagnosticsApiFp(configuration).deleteDiagnostics(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} top 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatests(top: number, options?: any): AxiosPromise<Array<DiagnosticsData>> {
            return DiagnosticsApiFp(configuration).getLatests(top, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [userId] 
         * @param {string} [vin] 
         * @param {string} [deviceId] 
         * @param {string} [email] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchDiagnostics(userId?: string, vin?: string, deviceId?: string, email?: string, page?: number, limit?: number, options?: any): AxiosPromise<DiagnosticsDataPage> {
            return DiagnosticsApiFp(configuration).searchDiagnostics(userId, vin, deviceId, email, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} lastDays 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trendsByDays(lastDays: number, options?: any): AxiosPromise<Array<DiagnosticTrend>> {
            return DiagnosticsApiFp(configuration).trendsByDays(lastDays, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DiagnosticsApi - object-oriented interface
 * @export
 * @class DiagnosticsApi
 * @extends {BaseAPI}
 */
export class DiagnosticsApi extends BaseAPI {
    /**
     * 
     * @param {DiagnosticsData} diagnosticsData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosticsApi
     */
    public createDiagnostics(diagnosticsData: DiagnosticsData, options?: any) {
        return DiagnosticsApiFp(this.configuration).createDiagnostics(diagnosticsData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosticsApi
     */
    public deleteDiagnostics(id: string, options?: any) {
        return DiagnosticsApiFp(this.configuration).deleteDiagnostics(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} top 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosticsApi
     */
    public getLatests(top: number, options?: any) {
        return DiagnosticsApiFp(this.configuration).getLatests(top, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [userId] 
     * @param {string} [vin] 
     * @param {string} [deviceId] 
     * @param {string} [email] 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosticsApi
     */
    public searchDiagnostics(userId?: string, vin?: string, deviceId?: string, email?: string, page?: number, limit?: number, options?: any) {
        return DiagnosticsApiFp(this.configuration).searchDiagnostics(userId, vin, deviceId, email, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} lastDays 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosticsApi
     */
    public trendsByDays(lastDays: number, options?: any) {
        return DiagnosticsApiFp(this.configuration).trendsByDays(lastDays, options).then((request) => request(this.axios, this.basePath));
    }

}


