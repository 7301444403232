import {
  AxonCode,
  CodesManagementApi,
  CodesPage,
  CreateAxonCodes,
  UpdateAxonCodeChannelEnum,
} from "@/apis/axon_user_codes";
import { getIDTokenFromCurrentUser } from "@/plugins/firebase";
import store from "@/store";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";

export interface CodesState {
  page: CodesPage;
  reload: boolean;
}

@Module({
  dynamic: true,
  namespaced: true,
  name: "Codes",
  store,
})
class Codes extends VuexModule implements CodesState {
  public page: CodesPage = {} as CodesPage;
  public reload = false;

  @Mutation
  setPage(page: CodesPage) {
    this.page = page;
  }

  @Mutation
  setReload(reload: boolean) {
    this.reload = reload;
  }

  @Action
  async fetchCodes(action: ListCodesAction) {
    const token = await getIDTokenFromCurrentUser();
    const api = new CodesManagementApi({
      basePath: "/api/codes-service",
      accessToken: token,
    });
    try {
      this.context.commit("Loader/increase", null, { root: true });
      const page = (await api.listCodes(action.code, action.page, action.limit))
        .data;
      this.context.commit("setPage", page);
      this.context.commit("setReload", false);
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }

  @Action
  async generateCodes(model: CreateAxonCodes) {
    const token = await getIDTokenFromCurrentUser();
    const api = new CodesManagementApi({
      basePath: "/api/codes-service",
      accessToken: token,
    });
    try {
      this.context.commit("Loader/increase", null, { root: true });
      await api.generateCodes(model);
      this.context.commit("setReload", true);
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }
  @Action
  async updateCode(item: AxonCode) {
    const token = await getIDTokenFromCurrentUser();
    const api = new CodesManagementApi({
      basePath: "/api/codes-service",
      accessToken: token,
    });
    try {
      this.context.commit("Loader/increase", null, { root: true });
      await api.updateCode(item.id, {
        channel:
          [
            UpdateAxonCodeChannelEnum.NONE,
            UpdateAxonCodeChannelEnum.WEB,
            UpdateAxonCodeChannelEnum.MOBILE,
            UpdateAxonCodeChannelEnum.RETAIL,
          ].find((x) => x.toString() == item.channel.toString()) ??
          UpdateAxonCodeChannelEnum.NONE,
        notes: item.notes,
        status: item.status,
      });
      this.context.commit("setReload", true);
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }
}

export class ListCodesAction {
  code: string | undefined;
  limit = 10;
  page = 1;
}

export const CodesModule = getModule(Codes);
