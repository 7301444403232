



























































































import { Component, Vue } from "vue-property-decorator";
import { FeatureSet } from "@/apis/axon_configurations";
import { FeatureSetsModule } from "@/store/modules/feature-sets";
import { VForm } from "@/types";

@Component({ name: "FeatureSetForm" })
export default class FeatureSetForm extends Vue {
  dialog = true;

  get model() {
    if (this.$route.params.id) {
      return FeatureSetsModule.featureSetPage.featureSets.find(
        (x) => x.featureSetId == this.$route.params.id
      ) as FeatureSet;
    } else {
      return {} as FeatureSet;
    }
  }

  async onSave() {
    if ((this.$refs.form as VForm).validate()) {
      await FeatureSetsModule.saveFeature(this.model);
      this.$router.go(-1);
    }
  }
}
