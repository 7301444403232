import { UserInfoApi } from "@/apis/axon_user_device_info";
import { firebase } from "@/plugins";
import store from "@/store";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";

export interface AuthState {
  currentUser?: firebase.User | null;
  role: string;
}

@Module({
  dynamic: true,
  namespaced: true,
  name: "Auth",
  store,
})
class Auth extends VuexModule implements AuthState {
  public currentUser?: firebase.User | null = firebase.auth().currentUser;
  public role = "";

  @Mutation
  setCurrentUser(user: firebase.User | null) {
    this.currentUser = user;
  }

  @Mutation
  setRole(role: string) {
    this.role = role;
  }

  @Action
  checkIfSignedIn() {
    firebase.auth().onAuthStateChanged(async (user) => {
      this.context.commit("Loader/increase", null, { root: true });
      this.context.commit("setCurrentUser", user);
      const token = await user?.getIdToken();
      if (token) {
        const api = new UserInfoApi({
          basePath: "/api/user-device-info-service",
          accessToken: token,
        });
        const info = (await api.getUserInfo()).data;
        this.context.commit("setRole", info.role);
      }
      this.context.commit("Loader/decrease", null, { root: true });
    });
  }

  @Action
  async resetPassword(email: string) {
    this.context.commit("Loader/increase", null, { root: true });
    await firebase.auth().sendPasswordResetEmail(email);
    this.context.commit("Loader/decrease", null, { root: true });
  }

  @Action
  async doLogin(loginRequest: LoginRequest): Promise<firebase.User | null> {
    try {
      this.context.commit("Loader/increase", null, { root: true });
      const credentials = await firebase
        .auth()
        .signInWithEmailAndPassword(
          loginRequest.username,
          loginRequest.password
        );
      if (credentials.user) {
        const token = await credentials.user.getIdTokenResult();
        if (["ADMIN", "REPORTER"].indexOf(token.claims.role) > -1) {
          this.context.commit("setCurrentUser", credentials.user);
          this.context.commit("Loader/decrease", null, { root: true });
          return credentials.user;
        } else {
          this.context.commit("Loader/setMessage", "Access denied", {
            root: true,
          });
          await firebase.auth().signOut();
        }
      }
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
      throw e;
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
    return null;
  }

  @Action({ commit: "setCurrentUser", rawError: true })
  async doLogout(): Promise<firebase.User | null> {
    try {
      this.context.commit("Loader/increase", null, { root: true });
      await firebase.auth().signOut();
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
    return null;
  }
}

export const AuthModule = getModule(Auth);

export class LoginRequest {
  username = "";
  password = "";
}
