import {
  AxonDevice,
  AxonDevicePage,
  UserDeviceManagementApi,
} from "@/apis/axon_user_device_info";
import { getIDTokenFromCurrentUser } from "@/plugins/firebase";
import store from "@/store";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";

export interface DevicesState {
  page: AxonDevicePage;
}

@Module({ dynamic: true, namespaced: true, name: "Devices", store })
class Devices extends VuexModule implements DevicesState {
  public page: AxonDevicePage = {} as AxonDevicePage;

  @Mutation
  setPage(page: AxonDevicePage) {
    this.page = page;
  }

  @Action
  async fetchDevices(action: ListDevicesAction) {
    const token = await getIDTokenFromCurrentUser();
    const api = new UserDeviceManagementApi({
      basePath: "/api/user-device-info-service",
      accessToken: token,
    });
    try {
      this.context.commit("Loader/increase", null, { root: true });
      const page = (
        await api.getDevices(
          action.email,
          action.deviceIdentifier,
          action.page,
          action.limit
        )
      ).data;
      this.context.commit("setPage", page);
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }

  @Action
  async updateDevice(action: UpdateDeviceAction): Promise<AxonDevice> {
    const token = await getIDTokenFromCurrentUser();
    const api = new UserDeviceManagementApi({
      basePath: "/api/user-device-info-service",
      accessToken: token,
    });
    try {
      this.context.commit("Loader/increase", null, { root: true });
      const res = await api.updateDevice(action.id, action.device);
      return res.data;
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
      throw e;
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }

  @Action
  async sendMessage(action: SendDeviceMessageAction) {
    const token = await getIDTokenFromCurrentUser();
    const api = new UserDeviceManagementApi({
      basePath: "/api/user-device-info-service",
      accessToken: token,
    });
    try {
      this.context.commit("Loader/increase", null, { root: true });
      const res = await api.sendMessage(action.id, {
        message: action.message,
        subject: action.subject,
      });
      return res.data;
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
      throw e;
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }
}

export class ListDevicesAction {
  email: string | undefined;
  deviceIdentifier: string | undefined;
  limit = 10;
  page = 1;
}

export interface UpdateDeviceAction {
  id: string;
  device: AxonDevice;
}

export interface SendDeviceMessageAction {
  id: string;
  subject: string;
  message: string;
}

export const DevicesModule = getModule(Devices);
