import store from "@/store";
import {
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";

export interface LoaderState {
  loading: number;
  message: string;
}

@Module({
  dynamic: true,
  namespaced: true,
  name: "Loader",
  store,
})
class Loader extends VuexModule implements LoaderState {
  public loading = 0;
  public message = "";

  @Mutation
  increase() {
    this.loading++;
  }

  @Mutation
  decrease() {
    this.loading--;
  }

  @Mutation
  setMessage(message: string) {
    this.message = message;
  }
}

export const LoaderModule = getModule(Loader);
