import { Faq, FAQSApi } from "@/apis/axon_faqs";
import { getIDTokenFromCurrentUser } from "@/plugins/firebase";
import store from "@/store";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";

export interface FAQSState {
  faqs: Faq[];
  faq: Faq;
}

@Module({ dynamic: true, namespaced: true, name: "FAQs", store })
class FAQs extends VuexModule implements FAQSState {
  public faqs: Faq[] = [];
  public faq: Faq = {} as Faq;

  @Mutation
  insertFaq(faq: Faq) {
    this.faqs.push(faq);
  }

  @Mutation
  removeFaq(id: string) {
    const faq = this.faqs.find((x) => x.id == id) as Faq;
    if (faq) {
      const index = this.faqs.indexOf(faq);
      if (index >= 0) {
        this.faqs.splice(index, -1);
      }
    }
  }

  @Mutation
  setFaq(faq: Faq) {
    this.faq = faq;
    const index = this.faqs.indexOf(faq);
    if (index >= 0) {
      this.faqs[index] = faq;
    }
  }

  @Mutation
  setFaqs(faqs: Faq[]) {
    this.faqs = faqs;
  }

  @Action
  async fetchFAQs() {
    const api = new FAQSApi({ basePath: "/api/faqs-service" });
    try {
      this.context.commit("Loader/increase", null, { root: true });
      this.context.commit("setFaqs", (await api.getFAQs()).data);
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }

  @Action
  async fetchFaqById(id: string) {
    const token = await getIDTokenFromCurrentUser();
    const api = new FAQSApi({
      basePath: "/api/faqs-service",
      accessToken: token,
    });
    try {
      this.context.commit("Loader/increase", null, { root: true });
      this.context.commit("setFaq", (await api.getFAQById(id)).data);
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }

  @Action
  async deleteFaq(id: string) {
    const token = await getIDTokenFromCurrentUser();
    const api = new FAQSApi({
      basePath: "/api/faqs-service",
      accessToken: token,
    });
    try {
      this.context.commit("Loader/increase", null, { root: true });
      await api.deleteFAQ(id);
      this.context.commit("removeFaq", id);
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }

  @Action
  async saveFaq(model: Faq) {
    const token = await getIDTokenFromCurrentUser();
    const api = new FAQSApi({
      basePath: "/api/faqs-service",
      accessToken: token,
    });
    try {
      this.context.commit("Loader/increase", null, { root: true });
      let item: Faq;
      if (model.id) {
        item = (await api.updateFAQ(model.id, model)).data;
      } else {
        item = (await api.createFAQ(model)).data;
        this.context.commit("insertFaq", item);
      }
      this.context.commit("setFaq", item);
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }
}

export const FAQSModule = getModule(FAQs);
