import { VehiclesApi, VINDecodedResult } from "@/apis/axon_configurations";
import { getIDTokenFromCurrentUser } from "@/plugins/firebase";
import store from "@/store";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";

export interface VINDecoderState {
  results: VINDecodedResult;
}

@Module({ dynamic: true, namespaced: true, name: "VINDecoder", store })
class VINDecoder extends VuexModule implements VINDecoderState {
  public results: VINDecodedResult = {} as VINDecodedResult;

  @Mutation
  updateResults(results: VINDecodedResult) {
    this.results = results;
  }

  @Action
  async fetchResults(vin: string) {
    const token = await getIDTokenFromCurrentUser();
    const api = new VehiclesApi({
      basePath: "/api/configurations-service",
      accessToken: token,
    });
    try {
      this.context.commit("Loader/increase", null, { root: true });
      const page = (await api.decodeVIN(vin)).data;
      this.context.commit("updateResults", page);
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }
}

export const VINDecoderModule = getModule(VINDecoder);
