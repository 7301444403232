








































































































import { Component, Vue } from "vue-property-decorator";
import { UpdateUserRequest, UserRoleEnum } from "@/apis/axon_user_device_info";
import { UserModule, UpdateUserAction } from "@/store/modules/users";
import {
  UsersCapabilitiesModule,
  UpdateUserCapabilitiesAction,
} from "@/store/modules/user-capabilities";
import { VForm } from "@/types";

@Component({ name: "UserUpdateFormComponent" })
export default class UserUpdateFormComponent extends Vue {
  dialog = true;
  roles = [UserRoleEnum.ADMIN, UserRoleEnum.REPORTER];

  get user() {
    return UserModule.selectedUser;
  }

  get capabilities() {
    return UsersCapabilitiesModule.capabilities;
  }

  get model() {
    const u = UserModule.selectedUser;
    return {
      name: u.name,
      role: u.role ? u.role.toString() : null,
      disabled: u.disabled,
    } as UpdateUserRequest;
  }

  get modelCapabilities() {
    const c = UsersCapabilitiesModule.capabilities;
    return {
      userId: this.$route.params.id,
      allowedOptions: c.allowedOptions ?? [],
      points: c.points,
      unlocked: c.unlocked,
    } as UpdateUserCapabilitiesAction;
  }

  onSave() {
    if ((this.$refs.formDetails as VForm).validate()) {
      const action = {} as UpdateUserAction;
      action.id = this.$route.params.id;
      action.update = this.model as UpdateUserRequest;
      UserModule.updateUser(action);
      this.$router.go(-1);
    }
  }

  incPoints() {
    // if (this.modelCapabilities.unlocked) return;
    this.capabilities.points++;
  }

  decPoints() {
    if (this.modelCapabilities.unlocked && this.capabilities.points > 0) return;
    this.capabilities.points--;
  }

  onSaveCapabilities() {
    UsersCapabilitiesModule.updateUserCapabilities(this.modelCapabilities);
    this.$router.go(-1);
  }
}
