

































import Vue from "vue";
export default Vue.extend({
  data: () => {
    return {
      items: [
        {
          title: "Home",
          desc: "Dashboard for latest and trends of Diagnostics.",
          role: ["ADMIN", "REPORTER"],
        },
        {
          title: "VIN Decoder",
          desc:
            "Tool for decoding VIN information including image displayed in App.",
          role: ["ADMIN", "REPORTER"],
        },
        {
          title: "Known Options",
          desc:
            "Management for Known Option for a particular Feature Set and Known Option Group.",
          role: ["ADMIN"],
        },
        {
          title: "Feature Sets",
          desc:
            "Management for Feature Sets. A Feature Set is related to a single or multiple vehicles based on Model/Trim/Family and Range Years which can share the same Known Options.",
          role: ["ADMIN"],
        },
        {
          title: "Known Options Groups",
          desc:
            "Management for the Known Option Groups which a Known Option belongs to.",
          role: ["ADMIN"],
        },
        {
          title: "Diagnostics",
          desc:
            "Tool for search Diagnostics Data from the Users to help and troubleshoot any behaviour for a Known Option",
          role: ["ADMIN", "REPORTER"],
        },
        {
          title: "Users",
          desc: "Management for App and Management Users",
          role: ["ADMIN"],
        },
        {
          title: "Registered Devices",
          desc:
            "Management of Registered Axon Devices as the are being registered by the end user. (Not an Inventory)",
          role: ["ADMIN"],
        },
        {
          title: "FAQS",
          desc:
            "Management for the FAQS displayed in App, providing an information in a RichTextFormat.",
          role: ["ADMIN"],
        },
      ],
    };
  },
});
