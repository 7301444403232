import {
  DiagnosticsApi,
  DiagnosticsData,
  DiagnosticsDataPage,
  DiagnosticTrend,
} from "@/apis/axon_diagnostics";
import { getIDTokenFromCurrentUser } from "@/plugins/firebase";
import store from "@/store";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";

export interface DiagnosticsState {
  diagnosticsDataPage: DiagnosticsDataPage;
  latestDiagnosticsData: DiagnosticsData[];
  latestActivity: DiagnosticTrend[];
}

@Module({
  dynamic: true,
  namespaced: true,
  name: "Diagnostics",
  store,
})
class Diagnostics extends VuexModule implements DiagnosticsState {
  public diagnosticsDataPage: DiagnosticsDataPage = {} as DiagnosticsDataPage;
  public latestDiagnosticsData: DiagnosticsData[] = [];
  public latestActivity: DiagnosticTrend[] = [];

  @Mutation
  setDiagnosticsDataPage(diagnosticsDataPage: DiagnosticsDataPage) {
    this.diagnosticsDataPage = diagnosticsDataPage;
  }

  @Mutation
  setLatestDiagnosticsData(latestDiagnosticsData: DiagnosticsData[]) {
    this.latestDiagnosticsData = latestDiagnosticsData;
  }

  @Mutation
  setLatestActivity(latestActivity: DiagnosticTrend[]) {
    this.latestActivity = latestActivity;
  }

  @Action
  async fetchDiagnosticsData(search: SearchDiagnostics) {
    const token = await getIDTokenFromCurrentUser();
    const api = new DiagnosticsApi({
      basePath: "/api/diagnostics-service",
      accessToken: token,
    });
    try {
      this.context.commit("Loader/increase", null, { root: true });
      this.context.commit(
        "setDiagnosticsDataPage",
        (
          await api.searchDiagnostics(
            search.userId,
            search.vin,
            search.deviceId,
            search.email,
            search.page,
            search.limit
          )
        ).data
      );
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }

  @Action
  async fetchLatestDiagnosticsData(itemsCount: number) {
    const token = await getIDTokenFromCurrentUser();
    const api = new DiagnosticsApi({
      basePath: "/api/diagnostics-service",
      accessToken: token,
    });
    try {
      this.context.commit("Loader/increase", null, { root: true });
      this.context.commit(
        "setLatestDiagnosticsData",
        (await api.getLatests(itemsCount)).data
      );
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }

  @Action
  async fetchLatestActivity(itemsCount: number) {
    const token = await getIDTokenFromCurrentUser();
    const api = new DiagnosticsApi({
      basePath: "/api/diagnostics-service",
      accessToken: token,
    });
    try {
      this.context.commit("Loader/increase", null, { root: true });
      this.context.commit(
        "setLatestActivity",
        (await api.trendsByDays(itemsCount)).data
      );
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }

  @Action
  async deleteDiagnostic(id: string) {
    const token = await getIDTokenFromCurrentUser();
    const api = new DiagnosticsApi({
      basePath: "/api/diagnostics-service",
      accessToken: token,
    });
    try {
      this.context.commit("Loader/increase", null, { root: true });
      await api.deleteDiagnostics(id);
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }
}

export const DiagnosticsModule = getModule(Diagnostics);

export class SearchDiagnostics {
  userId?: string;
  vin?: string;
  email?: string;
  deviceId?: string;
  page = 1;
  limit = 10;
}
