var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-card-actions',[_c('v-btn',{attrs:{"color":"secondary","text":"","small":"","to":{ name: 'users-create' }}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("New")],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"width":"100","label":"E-mail","required":"","color":"secondary","outlined":"","dense":"","clearable":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),(_vm.message)?_c('v-alert',{attrs:{"dense":"","type":"warning"}},[_vm._v(" "+_vm._s(_vm.message)+" ")]):_vm._e()],1),_c('v-col',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.searchRemote}},[_vm._v("Search")])],1)],1)],1),_c('v-divider'),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.page.users,"items-per-page":1000,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(item.id))])]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [(item.role)?_c('span',[_vm._v(_vm._s(item.role))]):_c('span',[_vm._v("USER")])]}},{key:"item.disabled",fn:function(ref){
var item = ref.item;
return [(item.disabled)?_c('span',[_c('v-chip',{attrs:{"small":"","color":"error"}},[_vm._v("DISABLED")])],1):_c('span',[_c('v-chip',{attrs:{"small":"","color":"success"}},[_vm._v("ENABLED")])],1)]}},{key:"item.verified",fn:function(ref){
var item = ref.item;
return [(item.verified)?_c('span',[_c('v-chip',{attrs:{"small":"","color":"success"}},[_vm._v("YES")])],1):_c('span',[_c('v-chip',{attrs:{"small":"","color":"error"}},[_vm._v("NO")])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","text":""},on:{"click":function($event){return _vm.onItemClick(item)}}},[_c('v-icon',[_vm._v("mdi-eye")]),_vm._v(" View ")],1)]}}],null,true)}),(_vm.page.pageToken)?_c('v-toolbar',[_c('v-spacer'),_c('v-btn',{on:{"click":_vm.loadMore}},[_c('v-icon',[_vm._v(" mdi-plus ")]),_vm._v(" Load More ")],1),_c('v-spacer')],1):_vm._e()],1),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }