


























































































































import { Component, Vue } from "vue-property-decorator";
import { AxonDevice } from "@/apis/axon_user_device_info";
import { DevicesModule } from "@/store/modules/devices";

@Component({ name: "DevicesViewComponent" })
export default class DevicesViewComponent extends Vue {
  get item() {
    return DevicesModule.page.devices.find(
      (x) => x.deviceId == this.$route.params.id
    ) as AxonDevice;
  }
}
