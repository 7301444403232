// tslint:disable
/**
 * Vehicle KnownOptions Configs
 * This Service is intented for querying Available KnownOptions setups
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface CreateKnownOption
 */
export interface CreateKnownOption {
    /**
     * 
     * @type {string}
     * @memberof CreateKnownOption
     */
    groupId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateKnownOption
     */
    featureSetId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateKnownOption
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof CreateKnownOption
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateKnownOption
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof CreateKnownOption
     */
    notes: string;
    /**
     * 
     * @type {string}
     * @memberof CreateKnownOption
     */
    sku: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateKnownOption
     */
    visible: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateKnownOption
     */
    points: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateKnownOption
     */
    reusable: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateKnownOption
     */
    queryable: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateKnownOption
     */
    youtubeVideoUrl?: string;
}
/**
 * 
 * @export
 * @interface CreateOptionCommand
 */
export interface CreateOptionCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateOptionCommand
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOptionCommand
     */
    block: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOptionCommand
     */
    line: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOptionCommand
     */
    module: string;
    /**
     * 
     * @type {number}
     * @memberof CreateOptionCommand
     */
    valueLength: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOptionCommand
     */
    commandOrder: number;
    /**
     * 
     * @type {string}
     * @memberof CreateOptionCommand
     */
    securityKey?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOptionCommand
     */
    inputLabel?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOptionCommand
     */
    userInput: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateOptionCommand
     */
    controlType: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOptionCommand
     */
    knownOptionId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateOptionCommand
     */
    valueTemplate: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateOptionCommand
     */
    staticValueVariables: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateOptionCommand
     */
    dynamicValueVariables: Array<string>;
}
/**
 * 
 * @export
 * @interface CreateOptionCommandValue
 */
export interface CreateOptionCommandValue {
    /**
     * 
     * @type {string}
     * @memberof CreateOptionCommandValue
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOptionCommandValue
     */
    valueHex: string;
    /**
     * 
     * @type {number}
     * @memberof CreateOptionCommandValue
     */
    valueOrder: number;
    /**
     * 
     * @type {string}
     * @memberof CreateOptionCommandValue
     */
    commandId: string;
}
/**
 * 
 * @export
 * @interface FeatureSet
 */
export interface FeatureSet {
    /**
     * 
     * @type {string}
     * @memberof FeatureSet
     */
    featureSetId: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureSet
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof FeatureSet
     */
    startYear: number;
    /**
     * 
     * @type {number}
     * @memberof FeatureSet
     */
    endYear: number;
    /**
     * 
     * @type {string}
     * @memberof FeatureSet
     */
    model: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureSet
     */
    manufacturer: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureSet
     */
    family: string;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureSet
     */
    allowSpeedCalibration: boolean;
    /**
     * 
     * @type {Array<MemoryModule>}
     * @memberof FeatureSet
     */
    memoryModules: Array<MemoryModule>;
    /**
     * 
     * @type {string}
     * @memberof FeatureSet
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureSet
     */
    updated?: string;
}
/**
 * 
 * @export
 * @interface FeatureSetPage
 */
export interface FeatureSetPage {
    /**
     * 
     * @type {Array<FeatureSet>}
     * @memberof FeatureSetPage
     */
    featureSets: Array<FeatureSet>;
    /**
     * 
     * @type {number}
     * @memberof FeatureSetPage
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof FeatureSetPage
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof FeatureSetPage
     */
    total: number;
}
/**
 * 
 * @export
 * @interface KnownOption
 */
export interface KnownOption {
    /**
     * 
     * @type {string}
     * @memberof KnownOption
     */
    knownOptionId: string;
    /**
     * 
     * @type {string}
     * @memberof KnownOption
     */
    groupId: string;
    /**
     * 
     * @type {string}
     * @memberof KnownOption
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof KnownOption
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof KnownOption
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof KnownOption
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof KnownOption
     */
    sku: string;
    /**
     * 
     * @type {Array<OptionCommand>}
     * @memberof KnownOption
     */
    optionCommands: Array<OptionCommand>;
    /**
     * 
     * @type {string}
     * @memberof KnownOption
     */
    featureSetId: string;
    /**
     * 
     * @type {boolean}
     * @memberof KnownOption
     */
    visible: boolean;
    /**
     * 
     * @type {number}
     * @memberof KnownOption
     */
    points: number;
    /**
     * 
     * @type {boolean}
     * @memberof KnownOption
     */
    reusable: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnownOption
     */
    queryable: boolean;
    /**
     * 
     * @type {string}
     * @memberof KnownOption
     */
    youtubeVideoUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof KnownOption
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof KnownOption
     */
    updated?: string;
}
/**
 * 
 * @export
 * @interface KnownOptionGroup
 */
export interface KnownOptionGroup {
    /**
     * 
     * @type {string}
     * @memberof KnownOptionGroup
     */
    groupId: string;
    /**
     * 
     * @type {string}
     * @memberof KnownOptionGroup
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof KnownOptionGroup
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof KnownOptionGroup
     */
    notes: string;
    /**
     * 
     * @type {number}
     * @memberof KnownOptionGroup
     */
    knownOptionsCount: number;
    /**
     * 
     * @type {string}
     * @memberof KnownOptionGroup
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof KnownOptionGroup
     */
    updated?: string;
}
/**
 * 
 * @export
 * @interface KnownOptionGroupPage
 */
export interface KnownOptionGroupPage {
    /**
     * 
     * @type {Array<KnownOptionGroup>}
     * @memberof KnownOptionGroupPage
     */
    groups: Array<KnownOptionGroup>;
    /**
     * 
     * @type {number}
     * @memberof KnownOptionGroupPage
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof KnownOptionGroupPage
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof KnownOptionGroupPage
     */
    limit: number;
}
/**
 * 
 * @export
 * @interface KnownOptionPage
 */
export interface KnownOptionPage {
    /**
     * 
     * @type {Array<KnownOption>}
     * @memberof KnownOptionPage
     */
    options: Array<KnownOption>;
    /**
     * 
     * @type {number}
     * @memberof KnownOptionPage
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof KnownOptionPage
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof KnownOptionPage
     */
    limit: number;
}
/**
 * 
 * @export
 * @interface MemoryBackupMap
 */
export interface MemoryBackupMap {
    /**
     * 
     * @type {string}
     * @memberof MemoryBackupMap
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof MemoryBackupMap
     */
    line: string;
    /**
     * 
     * @type {string}
     * @memberof MemoryBackupMap
     */
    block: string;
    /**
     * 
     * @type {number}
     * @memberof MemoryBackupMap
     */
    valueLength: number;
}
/**
 * 
 * @export
 * @interface MemoryModule
 */
export interface MemoryModule {
    /**
     * 
     * @type {string}
     * @memberof MemoryModule
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MemoryModule
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof MemoryModule
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MemoryModule
     */
    address: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MemoryModule
     */
    lines: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MemoryModule
     */
    blocks: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MemoryModule
     */
    canChannel: string;
    /**
     * 
     * @type {string}
     * @memberof MemoryModule
     */
    featureSetId: string;
    /**
     * 
     * @type {string}
     * @memberof MemoryModule
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof MemoryModule
     */
    modified?: string;
}
/**
 * 
 * @export
 * @interface OptionCommand
 */
export interface OptionCommand {
    /**
     * 
     * @type {string}
     * @memberof OptionCommand
     */
    commandId: string;
    /**
     * 
     * @type {string}
     * @memberof OptionCommand
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof OptionCommand
     */
    block: string;
    /**
     * 
     * @type {string}
     * @memberof OptionCommand
     */
    line: string;
    /**
     * 
     * @type {string}
     * @memberof OptionCommand
     */
    module: string;
    /**
     * 
     * @type {number}
     * @memberof OptionCommand
     */
    valueLength: number;
    /**
     * 
     * @type {number}
     * @memberof OptionCommand
     */
    commandOrder: number;
    /**
     * 
     * @type {string}
     * @memberof OptionCommand
     */
    securityKey?: string;
    /**
     * 
     * @type {string}
     * @memberof OptionCommand
     */
    inputLabel?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OptionCommand
     */
    userInput: boolean;
    /**
     * 
     * @type {string}
     * @memberof OptionCommand
     */
    controlType: string;
    /**
     * 
     * @type {Array<OptionCommandValue>}
     * @memberof OptionCommand
     */
    commandValues: Array<OptionCommandValue>;
    /**
     * 
     * @type {string}
     * @memberof OptionCommand
     */
    knownOptionId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionCommand
     */
    valueTemplate: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionCommand
     */
    staticValueVariables: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionCommand
     */
    dynamicValueVariables: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OptionCommand
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof OptionCommand
     */
    updated?: string;
}
/**
 * 
 * @export
 * @interface OptionCommandValue
 */
export interface OptionCommandValue {
    /**
     * 
     * @type {string}
     * @memberof OptionCommandValue
     */
    valueId: string;
    /**
     * 
     * @type {string}
     * @memberof OptionCommandValue
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof OptionCommandValue
     */
    valueHex: string;
    /**
     * 
     * @type {number}
     * @memberof OptionCommandValue
     */
    valueOrder: number;
    /**
     * 
     * @type {string}
     * @memberof OptionCommandValue
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof OptionCommandValue
     */
    updated?: string;
}
/**
 * 
 * @export
 * @interface UpdateKnownOption
 */
export interface UpdateKnownOption {
    /**
     * 
     * @type {string}
     * @memberof UpdateKnownOption
     */
    groupId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateKnownOption
     */
    featureSetId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateKnownOption
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateKnownOption
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateKnownOption
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateKnownOption
     */
    notes: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateKnownOption
     */
    sku: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateKnownOption
     */
    visible: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateKnownOption
     */
    points: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateKnownOption
     */
    reusable: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateKnownOption
     */
    queryable: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateKnownOption
     */
    youtubeVideoUrl?: string;
}
/**
 * 
 * @export
 * @interface UpdateOptionCommand
 */
export interface UpdateOptionCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateOptionCommand
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOptionCommand
     */
    block: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOptionCommand
     */
    line: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOptionCommand
     */
    module: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateOptionCommand
     */
    valueLength: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateOptionCommand
     */
    commandOrder: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateOptionCommand
     */
    securityKey?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOptionCommand
     */
    inputLabel?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateOptionCommand
     */
    userInput: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateOptionCommand
     */
    controlType: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOptionCommand
     */
    knownOptionId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateOptionCommand
     */
    valueTemplate: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateOptionCommand
     */
    staticValueVariables: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateOptionCommand
     */
    dynamicValueVariables: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateOptionCommandValue
 */
export interface UpdateOptionCommandValue {
    /**
     * 
     * @type {string}
     * @memberof UpdateOptionCommandValue
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOptionCommandValue
     */
    valueHex: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateOptionCommandValue
     */
    valueOrder: number;
}
/**
 * 
 * @export
 * @interface VINDecodedResult
 */
export interface VINDecodedResult {
    /**
     * 
     * @type {number}
     * @memberof VINDecodedResult
     */
    year: number;
    /**
     * 
     * @type {string}
     * @memberof VINDecodedResult
     */
    model: string;
    /**
     * 
     * @type {string}
     * @memberof VINDecodedResult
     */
    manufacturer: string;
    /**
     * 
     * @type {string}
     * @memberof VINDecodedResult
     */
    family: string;
    /**
     * 
     * @type {string}
     * @memberof VINDecodedResult
     */
    imageUrl: string;
    /**
     * 
     * @type {string}
     * @memberof VINDecodedResult
     */
    vin: string;
    /**
     * 
     * @type {string}
     * @memberof VINDecodedResult
     */
    trim: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof VINDecodedResult
     */
    otherImageUrls: Array<string>;
}

/**
 * FeatureSetApi - axios parameter creator
 * @export
 */
export const FeatureSetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get Available of Know Options Groups for Feature Set
         * @summary Get Available Groups for Feature Set
         * @param {string} featureSetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroupsForFeatureSet: async (featureSetId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'featureSetId' is not null or undefined
            if (featureSetId === null || featureSetId === undefined) {
                throw new RequiredError('featureSetId','Required parameter featureSetId was null or undefined when calling getGroupsForFeatureSet.');
            }
            const localVarPath = `/feature-set/{featureSetId}/groups`
                .replace(`{${"featureSetId"}}`, encodeURIComponent(String(featureSetId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Memory Backup Map for FeatureSet
         * @summary Get Memory Backup Map for FeatureSet
         * @param {string} featureSetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemoryBackupMap: async (featureSetId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'featureSetId' is not null or undefined
            if (featureSetId === null || featureSetId === undefined) {
                throw new RequiredError('featureSetId','Required parameter featureSetId was null or undefined when calling getMemoryBackupMap.');
            }
            const localVarPath = `/feature-set/{featureSetId}/memory-backup-map`
                .replace(`{${"featureSetId"}}`, encodeURIComponent(String(featureSetId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Available of Memory Modules for Feature Set
         * @summary Get Available Memory Modules for Feature Set
         * @param {string} featureSetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemoryModulesForFeatureSet: async (featureSetId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'featureSetId' is not null or undefined
            if (featureSetId === null || featureSetId === undefined) {
                throw new RequiredError('featureSetId','Required parameter featureSetId was null or undefined when calling getMemoryModulesForFeatureSet.');
            }
            const localVarPath = `/feature-set/{featureSetId}/memory-modules`
                .replace(`{${"featureSetId"}}`, encodeURIComponent(String(featureSetId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Search Available Feature Sets for specific Vehicle
         * @summary Search Feature Sets
         * @param {number} year 
         * @param {string} model 
         * @param {string} family 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchFeatureSets: async (year: number, model: string, family: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'year' is not null or undefined
            if (year === null || year === undefined) {
                throw new RequiredError('year','Required parameter year was null or undefined when calling searchFeatureSets.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling searchFeatureSets.');
            }
            // verify required parameter 'family' is not null or undefined
            if (family === null || family === undefined) {
                throw new RequiredError('family','Required parameter family was null or undefined when calling searchFeatureSets.');
            }
            const localVarPath = `/feature-set/_search`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (model !== undefined) {
                localVarQueryParameter['model'] = model;
            }

            if (family !== undefined) {
                localVarQueryParameter['family'] = family;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeatureSetApi - functional programming interface
 * @export
 */
export const FeatureSetApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Get Available of Know Options Groups for Feature Set
         * @summary Get Available Groups for Feature Set
         * @param {string} featureSetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGroupsForFeatureSet(featureSetId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KnownOptionGroup>>> {
            const localVarAxiosArgs = await FeatureSetApiAxiosParamCreator(configuration).getGroupsForFeatureSet(featureSetId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get Memory Backup Map for FeatureSet
         * @summary Get Memory Backup Map for FeatureSet
         * @param {string} featureSetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMemoryBackupMap(featureSetId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MemoryBackupMap>>> {
            const localVarAxiosArgs = await FeatureSetApiAxiosParamCreator(configuration).getMemoryBackupMap(featureSetId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get Available of Memory Modules for Feature Set
         * @summary Get Available Memory Modules for Feature Set
         * @param {string} featureSetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMemoryModulesForFeatureSet(featureSetId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MemoryModule>>> {
            const localVarAxiosArgs = await FeatureSetApiAxiosParamCreator(configuration).getMemoryModulesForFeatureSet(featureSetId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Search Available Feature Sets for specific Vehicle
         * @summary Search Feature Sets
         * @param {number} year 
         * @param {string} model 
         * @param {string} family 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchFeatureSets(year: number, model: string, family: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FeatureSet>>> {
            const localVarAxiosArgs = await FeatureSetApiAxiosParamCreator(configuration).searchFeatureSets(year, model, family, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * FeatureSetApi - factory interface
 * @export
 */
export const FeatureSetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Get Available of Know Options Groups for Feature Set
         * @summary Get Available Groups for Feature Set
         * @param {string} featureSetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroupsForFeatureSet(featureSetId: string, options?: any): AxiosPromise<Array<KnownOptionGroup>> {
            return FeatureSetApiFp(configuration).getGroupsForFeatureSet(featureSetId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Memory Backup Map for FeatureSet
         * @summary Get Memory Backup Map for FeatureSet
         * @param {string} featureSetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemoryBackupMap(featureSetId: string, options?: any): AxiosPromise<Array<MemoryBackupMap>> {
            return FeatureSetApiFp(configuration).getMemoryBackupMap(featureSetId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Available of Memory Modules for Feature Set
         * @summary Get Available Memory Modules for Feature Set
         * @param {string} featureSetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemoryModulesForFeatureSet(featureSetId: string, options?: any): AxiosPromise<Array<MemoryModule>> {
            return FeatureSetApiFp(configuration).getMemoryModulesForFeatureSet(featureSetId, options).then((request) => request(axios, basePath));
        },
        /**
         * Search Available Feature Sets for specific Vehicle
         * @summary Search Feature Sets
         * @param {number} year 
         * @param {string} model 
         * @param {string} family 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchFeatureSets(year: number, model: string, family: string, options?: any): AxiosPromise<Array<FeatureSet>> {
            return FeatureSetApiFp(configuration).searchFeatureSets(year, model, family, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeatureSetApi - object-oriented interface
 * @export
 * @class FeatureSetApi
 * @extends {BaseAPI}
 */
export class FeatureSetApi extends BaseAPI {
    /**
     * Get Available of Know Options Groups for Feature Set
     * @summary Get Available Groups for Feature Set
     * @param {string} featureSetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureSetApi
     */
    public getGroupsForFeatureSet(featureSetId: string, options?: any) {
        return FeatureSetApiFp(this.configuration).getGroupsForFeatureSet(featureSetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Memory Backup Map for FeatureSet
     * @summary Get Memory Backup Map for FeatureSet
     * @param {string} featureSetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureSetApi
     */
    public getMemoryBackupMap(featureSetId: string, options?: any) {
        return FeatureSetApiFp(this.configuration).getMemoryBackupMap(featureSetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Available of Memory Modules for Feature Set
     * @summary Get Available Memory Modules for Feature Set
     * @param {string} featureSetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureSetApi
     */
    public getMemoryModulesForFeatureSet(featureSetId: string, options?: any) {
        return FeatureSetApiFp(this.configuration).getMemoryModulesForFeatureSet(featureSetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Search Available Feature Sets for specific Vehicle
     * @summary Search Feature Sets
     * @param {number} year 
     * @param {string} model 
     * @param {string} family 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureSetApi
     */
    public searchFeatureSets(year: number, model: string, family: string, options?: any) {
        return FeatureSetApiFp(this.configuration).searchFeatureSets(year, model, family, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * FeatureSetManagementApi - axios parameter creator
 * @export
 */
export const FeatureSetManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {FeatureSet} featureSet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFeatureSet: async (featureSet: FeatureSet, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'featureSet' is not null or undefined
            if (featureSet === null || featureSet === undefined) {
                throw new RequiredError('featureSet','Required parameter featureSet was null or undefined when calling createFeatureSet.');
            }
            const localVarPath = `/management/feature-sets`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof featureSet !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(featureSet !== undefined ? featureSet : {}) : (featureSet || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeatureSet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteFeatureSet.');
            }
            const localVarPath = `/management/feature-sets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureSetById: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getFeatureSetById.');
            }
            const localVarPath = `/management/feature-sets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureSets: async (page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/management/feature-sets`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {FeatureSet} featureSet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFeatureSet: async (id: string, featureSet: FeatureSet, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateFeatureSet.');
            }
            // verify required parameter 'featureSet' is not null or undefined
            if (featureSet === null || featureSet === undefined) {
                throw new RequiredError('featureSet','Required parameter featureSet was null or undefined when calling updateFeatureSet.');
            }
            const localVarPath = `/management/feature-sets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof featureSet !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(featureSet !== undefined ? featureSet : {}) : (featureSet || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeatureSetManagementApi - functional programming interface
 * @export
 */
export const FeatureSetManagementApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {FeatureSet} featureSet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFeatureSet(featureSet: FeatureSet, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeatureSetPage>> {
            const localVarAxiosArgs = await FeatureSetManagementApiAxiosParamCreator(configuration).createFeatureSet(featureSet, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFeatureSet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await FeatureSetManagementApiAxiosParamCreator(configuration).deleteFeatureSet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeatureSetById(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeatureSet>> {
            const localVarAxiosArgs = await FeatureSetManagementApiAxiosParamCreator(configuration).getFeatureSetById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeatureSets(page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeatureSetPage>> {
            const localVarAxiosArgs = await FeatureSetManagementApiAxiosParamCreator(configuration).getFeatureSets(page, limit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {FeatureSet} featureSet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFeatureSet(id: string, featureSet: FeatureSet, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeatureSet>> {
            const localVarAxiosArgs = await FeatureSetManagementApiAxiosParamCreator(configuration).updateFeatureSet(id, featureSet, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * FeatureSetManagementApi - factory interface
 * @export
 */
export const FeatureSetManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {FeatureSet} featureSet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFeatureSet(featureSet: FeatureSet, options?: any): AxiosPromise<FeatureSetPage> {
            return FeatureSetManagementApiFp(configuration).createFeatureSet(featureSet, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeatureSet(id: string, options?: any): AxiosPromise<void> {
            return FeatureSetManagementApiFp(configuration).deleteFeatureSet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureSetById(id: string, options?: any): AxiosPromise<FeatureSet> {
            return FeatureSetManagementApiFp(configuration).getFeatureSetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureSets(page?: number, limit?: number, options?: any): AxiosPromise<FeatureSetPage> {
            return FeatureSetManagementApiFp(configuration).getFeatureSets(page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {FeatureSet} featureSet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFeatureSet(id: string, featureSet: FeatureSet, options?: any): AxiosPromise<FeatureSet> {
            return FeatureSetManagementApiFp(configuration).updateFeatureSet(id, featureSet, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeatureSetManagementApi - object-oriented interface
 * @export
 * @class FeatureSetManagementApi
 * @extends {BaseAPI}
 */
export class FeatureSetManagementApi extends BaseAPI {
    /**
     * 
     * @param {FeatureSet} featureSet 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureSetManagementApi
     */
    public createFeatureSet(featureSet: FeatureSet, options?: any) {
        return FeatureSetManagementApiFp(this.configuration).createFeatureSet(featureSet, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureSetManagementApi
     */
    public deleteFeatureSet(id: string, options?: any) {
        return FeatureSetManagementApiFp(this.configuration).deleteFeatureSet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureSetManagementApi
     */
    public getFeatureSetById(id: string, options?: any) {
        return FeatureSetManagementApiFp(this.configuration).getFeatureSetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureSetManagementApi
     */
    public getFeatureSets(page?: number, limit?: number, options?: any) {
        return FeatureSetManagementApiFp(this.configuration).getFeatureSets(page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {FeatureSet} featureSet 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureSetManagementApi
     */
    public updateFeatureSet(id: string, featureSet: FeatureSet, options?: any) {
        return FeatureSetManagementApiFp(this.configuration).updateFeatureSet(id, featureSet, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * KnownOptionCommandValuesManagementApi - axios parameter creator
 * @export
 */
export const KnownOptionCommandValuesManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateOptionCommandValue} createOptionCommandValue 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommandValue: async (createOptionCommandValue: CreateOptionCommandValue, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOptionCommandValue' is not null or undefined
            if (createOptionCommandValue === null || createOptionCommandValue === undefined) {
                throw new RequiredError('createOptionCommandValue','Required parameter createOptionCommandValue was null or undefined when calling createCommandValue.');
            }
            const localVarPath = `/management/option-command-values`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createOptionCommandValue !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createOptionCommandValue !== undefined ? createOptionCommandValue : {}) : (createOptionCommandValue || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCommandValue: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteCommandValue.');
            }
            const localVarPath = `/management/option-command-values/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommandValueById: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getCommandValueById.');
            }
            const localVarPath = `/management/option-command-values/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateOptionCommandValue} updateOptionCommandValue 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCommandValue: async (id: string, updateOptionCommandValue: UpdateOptionCommandValue, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateCommandValue.');
            }
            // verify required parameter 'updateOptionCommandValue' is not null or undefined
            if (updateOptionCommandValue === null || updateOptionCommandValue === undefined) {
                throw new RequiredError('updateOptionCommandValue','Required parameter updateOptionCommandValue was null or undefined when calling updateCommandValue.');
            }
            const localVarPath = `/management/option-command-values/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateOptionCommandValue !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateOptionCommandValue !== undefined ? updateOptionCommandValue : {}) : (updateOptionCommandValue || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KnownOptionCommandValuesManagementApi - functional programming interface
 * @export
 */
export const KnownOptionCommandValuesManagementApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateOptionCommandValue} createOptionCommandValue 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCommandValue(createOptionCommandValue: CreateOptionCommandValue, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OptionCommandValue>> {
            const localVarAxiosArgs = await KnownOptionCommandValuesManagementApiAxiosParamCreator(configuration).createCommandValue(createOptionCommandValue, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCommandValue(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await KnownOptionCommandValuesManagementApiAxiosParamCreator(configuration).deleteCommandValue(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommandValueById(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OptionCommandValue>> {
            const localVarAxiosArgs = await KnownOptionCommandValuesManagementApiAxiosParamCreator(configuration).getCommandValueById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateOptionCommandValue} updateOptionCommandValue 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCommandValue(id: string, updateOptionCommandValue: UpdateOptionCommandValue, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OptionCommandValue>> {
            const localVarAxiosArgs = await KnownOptionCommandValuesManagementApiAxiosParamCreator(configuration).updateCommandValue(id, updateOptionCommandValue, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * KnownOptionCommandValuesManagementApi - factory interface
 * @export
 */
export const KnownOptionCommandValuesManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {CreateOptionCommandValue} createOptionCommandValue 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommandValue(createOptionCommandValue: CreateOptionCommandValue, options?: any): AxiosPromise<OptionCommandValue> {
            return KnownOptionCommandValuesManagementApiFp(configuration).createCommandValue(createOptionCommandValue, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCommandValue(id: string, options?: any): AxiosPromise<void> {
            return KnownOptionCommandValuesManagementApiFp(configuration).deleteCommandValue(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommandValueById(id: string, options?: any): AxiosPromise<OptionCommandValue> {
            return KnownOptionCommandValuesManagementApiFp(configuration).getCommandValueById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateOptionCommandValue} updateOptionCommandValue 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCommandValue(id: string, updateOptionCommandValue: UpdateOptionCommandValue, options?: any): AxiosPromise<OptionCommandValue> {
            return KnownOptionCommandValuesManagementApiFp(configuration).updateCommandValue(id, updateOptionCommandValue, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KnownOptionCommandValuesManagementApi - object-oriented interface
 * @export
 * @class KnownOptionCommandValuesManagementApi
 * @extends {BaseAPI}
 */
export class KnownOptionCommandValuesManagementApi extends BaseAPI {
    /**
     * 
     * @param {CreateOptionCommandValue} createOptionCommandValue 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnownOptionCommandValuesManagementApi
     */
    public createCommandValue(createOptionCommandValue: CreateOptionCommandValue, options?: any) {
        return KnownOptionCommandValuesManagementApiFp(this.configuration).createCommandValue(createOptionCommandValue, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnownOptionCommandValuesManagementApi
     */
    public deleteCommandValue(id: string, options?: any) {
        return KnownOptionCommandValuesManagementApiFp(this.configuration).deleteCommandValue(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnownOptionCommandValuesManagementApi
     */
    public getCommandValueById(id: string, options?: any) {
        return KnownOptionCommandValuesManagementApiFp(this.configuration).getCommandValueById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateOptionCommandValue} updateOptionCommandValue 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnownOptionCommandValuesManagementApi
     */
    public updateCommandValue(id: string, updateOptionCommandValue: UpdateOptionCommandValue, options?: any) {
        return KnownOptionCommandValuesManagementApiFp(this.configuration).updateCommandValue(id, updateOptionCommandValue, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * KnownOptionCommandsManagementApi - axios parameter creator
 * @export
 */
export const KnownOptionCommandsManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateOptionCommand} createOptionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommand: async (createOptionCommand: CreateOptionCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOptionCommand' is not null or undefined
            if (createOptionCommand === null || createOptionCommand === undefined) {
                throw new RequiredError('createOptionCommand','Required parameter createOptionCommand was null or undefined when calling createCommand.');
            }
            const localVarPath = `/management/option-commands`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createOptionCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createOptionCommand !== undefined ? createOptionCommand : {}) : (createOptionCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCommand: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteCommand.');
            }
            const localVarPath = `/management/option-commands/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommandById: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getCommandById.');
            }
            const localVarPath = `/management/option-commands/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommandValuesById: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getCommandValuesById.');
            }
            const localVarPath = `/management/option-commands/{id}/values`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateOptionCommand} updateOptionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCommand: async (id: string, updateOptionCommand: UpdateOptionCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateCommand.');
            }
            // verify required parameter 'updateOptionCommand' is not null or undefined
            if (updateOptionCommand === null || updateOptionCommand === undefined) {
                throw new RequiredError('updateOptionCommand','Required parameter updateOptionCommand was null or undefined when calling updateCommand.');
            }
            const localVarPath = `/management/option-commands/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateOptionCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateOptionCommand !== undefined ? updateOptionCommand : {}) : (updateOptionCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KnownOptionCommandsManagementApi - functional programming interface
 * @export
 */
export const KnownOptionCommandsManagementApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateOptionCommand} createOptionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCommand(createOptionCommand: CreateOptionCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OptionCommand>> {
            const localVarAxiosArgs = await KnownOptionCommandsManagementApiAxiosParamCreator(configuration).createCommand(createOptionCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCommand(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await KnownOptionCommandsManagementApiAxiosParamCreator(configuration).deleteCommand(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommandById(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OptionCommand>> {
            const localVarAxiosArgs = await KnownOptionCommandsManagementApiAxiosParamCreator(configuration).getCommandById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommandValuesById(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OptionCommandValue>>> {
            const localVarAxiosArgs = await KnownOptionCommandsManagementApiAxiosParamCreator(configuration).getCommandValuesById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateOptionCommand} updateOptionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCommand(id: string, updateOptionCommand: UpdateOptionCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OptionCommand>> {
            const localVarAxiosArgs = await KnownOptionCommandsManagementApiAxiosParamCreator(configuration).updateCommand(id, updateOptionCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * KnownOptionCommandsManagementApi - factory interface
 * @export
 */
export const KnownOptionCommandsManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {CreateOptionCommand} createOptionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommand(createOptionCommand: CreateOptionCommand, options?: any): AxiosPromise<OptionCommand> {
            return KnownOptionCommandsManagementApiFp(configuration).createCommand(createOptionCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCommand(id: string, options?: any): AxiosPromise<void> {
            return KnownOptionCommandsManagementApiFp(configuration).deleteCommand(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommandById(id: string, options?: any): AxiosPromise<OptionCommand> {
            return KnownOptionCommandsManagementApiFp(configuration).getCommandById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommandValuesById(id: string, options?: any): AxiosPromise<Array<OptionCommandValue>> {
            return KnownOptionCommandsManagementApiFp(configuration).getCommandValuesById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateOptionCommand} updateOptionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCommand(id: string, updateOptionCommand: UpdateOptionCommand, options?: any): AxiosPromise<OptionCommand> {
            return KnownOptionCommandsManagementApiFp(configuration).updateCommand(id, updateOptionCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KnownOptionCommandsManagementApi - object-oriented interface
 * @export
 * @class KnownOptionCommandsManagementApi
 * @extends {BaseAPI}
 */
export class KnownOptionCommandsManagementApi extends BaseAPI {
    /**
     * 
     * @param {CreateOptionCommand} createOptionCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnownOptionCommandsManagementApi
     */
    public createCommand(createOptionCommand: CreateOptionCommand, options?: any) {
        return KnownOptionCommandsManagementApiFp(this.configuration).createCommand(createOptionCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnownOptionCommandsManagementApi
     */
    public deleteCommand(id: string, options?: any) {
        return KnownOptionCommandsManagementApiFp(this.configuration).deleteCommand(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnownOptionCommandsManagementApi
     */
    public getCommandById(id: string, options?: any) {
        return KnownOptionCommandsManagementApiFp(this.configuration).getCommandById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnownOptionCommandsManagementApi
     */
    public getCommandValuesById(id: string, options?: any) {
        return KnownOptionCommandsManagementApiFp(this.configuration).getCommandValuesById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateOptionCommand} updateOptionCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnownOptionCommandsManagementApi
     */
    public updateCommand(id: string, updateOptionCommand: UpdateOptionCommand, options?: any) {
        return KnownOptionCommandsManagementApiFp(this.configuration).updateCommand(id, updateOptionCommand, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * KnownOptionsApi - axios parameter creator
 * @export
 */
export const KnownOptionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get KnownOption By Code
         * @summary Get KnownOption By Code
         * @param {string} featureSetId 
         * @param {string} knownOptionCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKnownOptionByCode: async (featureSetId: string, knownOptionCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'featureSetId' is not null or undefined
            if (featureSetId === null || featureSetId === undefined) {
                throw new RequiredError('featureSetId','Required parameter featureSetId was null or undefined when calling getKnownOptionByCode.');
            }
            // verify required parameter 'knownOptionCode' is not null or undefined
            if (knownOptionCode === null || knownOptionCode === undefined) {
                throw new RequiredError('knownOptionCode','Required parameter knownOptionCode was null or undefined when calling getKnownOptionByCode.');
            }
            const localVarPath = `/known_options/_by-code/{knownOptionCode}`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (featureSetId !== undefined) {
                localVarQueryParameter['featureSetId'] = featureSetId;
            }

            if (knownOptionCode !== undefined) {
                localVarQueryParameter['knownOptionCode'] = knownOptionCode;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Search compatible knownOptions with Terms
         * @summary Search KnownOptions 
         * @param {string} terms 
         * @param {string} featureSetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchKnownOptions: async (terms: string, featureSetId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'terms' is not null or undefined
            if (terms === null || terms === undefined) {
                throw new RequiredError('terms','Required parameter terms was null or undefined when calling searchKnownOptions.');
            }
            // verify required parameter 'featureSetId' is not null or undefined
            if (featureSetId === null || featureSetId === undefined) {
                throw new RequiredError('featureSetId','Required parameter featureSetId was null or undefined when calling searchKnownOptions.');
            }
            const localVarPath = `/known_options/_search`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (terms !== undefined) {
                localVarQueryParameter['terms'] = terms;
            }

            if (featureSetId !== undefined) {
                localVarQueryParameter['featureSetId'] = featureSetId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KnownOptionsApi - functional programming interface
 * @export
 */
export const KnownOptionsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Get KnownOption By Code
         * @summary Get KnownOption By Code
         * @param {string} featureSetId 
         * @param {string} knownOptionCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getKnownOptionByCode(featureSetId: string, knownOptionCode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnownOption>> {
            const localVarAxiosArgs = await KnownOptionsApiAxiosParamCreator(configuration).getKnownOptionByCode(featureSetId, knownOptionCode, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Search compatible knownOptions with Terms
         * @summary Search KnownOptions 
         * @param {string} terms 
         * @param {string} featureSetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchKnownOptions(terms: string, featureSetId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KnownOption>>> {
            const localVarAxiosArgs = await KnownOptionsApiAxiosParamCreator(configuration).searchKnownOptions(terms, featureSetId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * KnownOptionsApi - factory interface
 * @export
 */
export const KnownOptionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Get KnownOption By Code
         * @summary Get KnownOption By Code
         * @param {string} featureSetId 
         * @param {string} knownOptionCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKnownOptionByCode(featureSetId: string, knownOptionCode: string, options?: any): AxiosPromise<KnownOption> {
            return KnownOptionsApiFp(configuration).getKnownOptionByCode(featureSetId, knownOptionCode, options).then((request) => request(axios, basePath));
        },
        /**
         * Search compatible knownOptions with Terms
         * @summary Search KnownOptions 
         * @param {string} terms 
         * @param {string} featureSetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchKnownOptions(terms: string, featureSetId: string, options?: any): AxiosPromise<Array<KnownOption>> {
            return KnownOptionsApiFp(configuration).searchKnownOptions(terms, featureSetId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KnownOptionsApi - object-oriented interface
 * @export
 * @class KnownOptionsApi
 * @extends {BaseAPI}
 */
export class KnownOptionsApi extends BaseAPI {
    /**
     * Get KnownOption By Code
     * @summary Get KnownOption By Code
     * @param {string} featureSetId 
     * @param {string} knownOptionCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnownOptionsApi
     */
    public getKnownOptionByCode(featureSetId: string, knownOptionCode: string, options?: any) {
        return KnownOptionsApiFp(this.configuration).getKnownOptionByCode(featureSetId, knownOptionCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Search compatible knownOptions with Terms
     * @summary Search KnownOptions 
     * @param {string} terms 
     * @param {string} featureSetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnownOptionsApi
     */
    public searchKnownOptions(terms: string, featureSetId: string, options?: any) {
        return KnownOptionsApiFp(this.configuration).searchKnownOptions(terms, featureSetId, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * KnownOptionsGroupsApi - axios parameter creator
 * @export
 */
export const KnownOptionsGroupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns an specific Group given a GroupID
         * @summary Get Details of a Group
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroupById: async (groupId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling getGroupById.');
            }
            const localVarPath = `/groups/{groupId}`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return a list of KnownOptions based on a GroupID.
         * @summary Returns a list of KnownOptions based on a GroupID
         * @param {string} groupId 
         * @param {string} featureSetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroupKnownOptionsById: async (groupId: string, featureSetId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling getGroupKnownOptionsById.');
            }
            // verify required parameter 'featureSetId' is not null or undefined
            if (featureSetId === null || featureSetId === undefined) {
                throw new RequiredError('featureSetId','Required parameter featureSetId was null or undefined when calling getGroupKnownOptionsById.');
            }
            const localVarPath = `/groups/{groupId}/known_options`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (featureSetId !== undefined) {
                localVarQueryParameter['featureSetId'] = featureSetId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KnownOptionsGroupsApi - functional programming interface
 * @export
 */
export const KnownOptionsGroupsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Returns an specific Group given a GroupID
         * @summary Get Details of a Group
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGroupById(groupId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnownOptionGroup>> {
            const localVarAxiosArgs = await KnownOptionsGroupsApiAxiosParamCreator(configuration).getGroupById(groupId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Return a list of KnownOptions based on a GroupID.
         * @summary Returns a list of KnownOptions based on a GroupID
         * @param {string} groupId 
         * @param {string} featureSetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGroupKnownOptionsById(groupId: string, featureSetId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KnownOption>>> {
            const localVarAxiosArgs = await KnownOptionsGroupsApiAxiosParamCreator(configuration).getGroupKnownOptionsById(groupId, featureSetId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * KnownOptionsGroupsApi - factory interface
 * @export
 */
export const KnownOptionsGroupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Returns an specific Group given a GroupID
         * @summary Get Details of a Group
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroupById(groupId: string, options?: any): AxiosPromise<KnownOptionGroup> {
            return KnownOptionsGroupsApiFp(configuration).getGroupById(groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * Return a list of KnownOptions based on a GroupID.
         * @summary Returns a list of KnownOptions based on a GroupID
         * @param {string} groupId 
         * @param {string} featureSetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroupKnownOptionsById(groupId: string, featureSetId: string, options?: any): AxiosPromise<Array<KnownOption>> {
            return KnownOptionsGroupsApiFp(configuration).getGroupKnownOptionsById(groupId, featureSetId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KnownOptionsGroupsApi - object-oriented interface
 * @export
 * @class KnownOptionsGroupsApi
 * @extends {BaseAPI}
 */
export class KnownOptionsGroupsApi extends BaseAPI {
    /**
     * Returns an specific Group given a GroupID
     * @summary Get Details of a Group
     * @param {string} groupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnownOptionsGroupsApi
     */
    public getGroupById(groupId: string, options?: any) {
        return KnownOptionsGroupsApiFp(this.configuration).getGroupById(groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return a list of KnownOptions based on a GroupID.
     * @summary Returns a list of KnownOptions based on a GroupID
     * @param {string} groupId 
     * @param {string} featureSetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnownOptionsGroupsApi
     */
    public getGroupKnownOptionsById(groupId: string, featureSetId: string, options?: any) {
        return KnownOptionsGroupsApiFp(this.configuration).getGroupKnownOptionsById(groupId, featureSetId, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * KnownOptionsGroupsManagementApi - axios parameter creator
 * @export
 */
export const KnownOptionsGroupsManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {KnownOptionGroup} knownOptionGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroup: async (knownOptionGroup: KnownOptionGroup, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'knownOptionGroup' is not null or undefined
            if (knownOptionGroup === null || knownOptionGroup === undefined) {
                throw new RequiredError('knownOptionGroup','Required parameter knownOptionGroup was null or undefined when calling createGroup.');
            }
            const localVarPath = `/management/groups`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof knownOptionGroup !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(knownOptionGroup !== undefined ? knownOptionGroup : {}) : (knownOptionGroup || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroup: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteGroup.');
            }
            const localVarPath = `/management/groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroupById: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getGroupById.');
            }
            const localVarPath = `/management/groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroups: async (page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/management/groups`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {KnownOptionGroup} knownOptionGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroup: async (id: string, knownOptionGroup: KnownOptionGroup, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateGroup.');
            }
            // verify required parameter 'knownOptionGroup' is not null or undefined
            if (knownOptionGroup === null || knownOptionGroup === undefined) {
                throw new RequiredError('knownOptionGroup','Required parameter knownOptionGroup was null or undefined when calling updateGroup.');
            }
            const localVarPath = `/management/groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof knownOptionGroup !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(knownOptionGroup !== undefined ? knownOptionGroup : {}) : (knownOptionGroup || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KnownOptionsGroupsManagementApi - functional programming interface
 * @export
 */
export const KnownOptionsGroupsManagementApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {KnownOptionGroup} knownOptionGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createGroup(knownOptionGroup: KnownOptionGroup, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnownOptionGroup>> {
            const localVarAxiosArgs = await KnownOptionsGroupsManagementApiAxiosParamCreator(configuration).createGroup(knownOptionGroup, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteGroup(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await KnownOptionsGroupsManagementApiAxiosParamCreator(configuration).deleteGroup(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGroupById(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnownOptionGroup>> {
            const localVarAxiosArgs = await KnownOptionsGroupsManagementApiAxiosParamCreator(configuration).getGroupById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGroups(page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnownOptionGroupPage>> {
            const localVarAxiosArgs = await KnownOptionsGroupsManagementApiAxiosParamCreator(configuration).getGroups(page, limit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {KnownOptionGroup} knownOptionGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGroup(id: string, knownOptionGroup: KnownOptionGroup, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnownOptionGroup>> {
            const localVarAxiosArgs = await KnownOptionsGroupsManagementApiAxiosParamCreator(configuration).updateGroup(id, knownOptionGroup, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * KnownOptionsGroupsManagementApi - factory interface
 * @export
 */
export const KnownOptionsGroupsManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {KnownOptionGroup} knownOptionGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroup(knownOptionGroup: KnownOptionGroup, options?: any): AxiosPromise<KnownOptionGroup> {
            return KnownOptionsGroupsManagementApiFp(configuration).createGroup(knownOptionGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroup(id: string, options?: any): AxiosPromise<void> {
            return KnownOptionsGroupsManagementApiFp(configuration).deleteGroup(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroupById(id: string, options?: any): AxiosPromise<KnownOptionGroup> {
            return KnownOptionsGroupsManagementApiFp(configuration).getGroupById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroups(page?: number, limit?: number, options?: any): AxiosPromise<KnownOptionGroupPage> {
            return KnownOptionsGroupsManagementApiFp(configuration).getGroups(page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {KnownOptionGroup} knownOptionGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroup(id: string, knownOptionGroup: KnownOptionGroup, options?: any): AxiosPromise<KnownOptionGroup> {
            return KnownOptionsGroupsManagementApiFp(configuration).updateGroup(id, knownOptionGroup, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KnownOptionsGroupsManagementApi - object-oriented interface
 * @export
 * @class KnownOptionsGroupsManagementApi
 * @extends {BaseAPI}
 */
export class KnownOptionsGroupsManagementApi extends BaseAPI {
    /**
     * 
     * @param {KnownOptionGroup} knownOptionGroup 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnownOptionsGroupsManagementApi
     */
    public createGroup(knownOptionGroup: KnownOptionGroup, options?: any) {
        return KnownOptionsGroupsManagementApiFp(this.configuration).createGroup(knownOptionGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnownOptionsGroupsManagementApi
     */
    public deleteGroup(id: string, options?: any) {
        return KnownOptionsGroupsManagementApiFp(this.configuration).deleteGroup(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnownOptionsGroupsManagementApi
     */
    public getGroupById(id: string, options?: any) {
        return KnownOptionsGroupsManagementApiFp(this.configuration).getGroupById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnownOptionsGroupsManagementApi
     */
    public getGroups(page?: number, limit?: number, options?: any) {
        return KnownOptionsGroupsManagementApiFp(this.configuration).getGroups(page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {KnownOptionGroup} knownOptionGroup 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnownOptionsGroupsManagementApi
     */
    public updateGroup(id: string, knownOptionGroup: KnownOptionGroup, options?: any) {
        return KnownOptionsGroupsManagementApiFp(this.configuration).updateGroup(id, knownOptionGroup, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * KnownOptionsManagementApi - axios parameter creator
 * @export
 */
export const KnownOptionsManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateKnownOption} createKnownOption 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOption: async (createKnownOption: CreateKnownOption, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createKnownOption' is not null or undefined
            if (createKnownOption === null || createKnownOption === undefined) {
                throw new RequiredError('createKnownOption','Required parameter createKnownOption was null or undefined when calling createOption.');
            }
            const localVarPath = `/management/known_options`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createKnownOption !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createKnownOption !== undefined ? createKnownOption : {}) : (createKnownOption || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOption: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteOption.');
            }
            const localVarPath = `/management/known_options/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOptionById: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOptionById.');
            }
            const localVarPath = `/management/known_options/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOptionCommands: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOptionCommands.');
            }
            const localVarPath = `/management/known_options/{id}/commands`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [featureSetIds] 
         * @param {string} [groupIds] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOptions: async (featureSetIds?: string, groupIds?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/management/known_options`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (featureSetIds !== undefined) {
                localVarQueryParameter['featureSetIds'] = featureSetIds;
            }

            if (groupIds !== undefined) {
                localVarQueryParameter['groupIds'] = groupIds;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateKnownOption} updateKnownOption 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOption: async (id: string, updateKnownOption: UpdateKnownOption, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateOption.');
            }
            // verify required parameter 'updateKnownOption' is not null or undefined
            if (updateKnownOption === null || updateKnownOption === undefined) {
                throw new RequiredError('updateKnownOption','Required parameter updateKnownOption was null or undefined when calling updateOption.');
            }
            const localVarPath = `/management/known_options/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateKnownOption !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateKnownOption !== undefined ? updateKnownOption : {}) : (updateKnownOption || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KnownOptionsManagementApi - functional programming interface
 * @export
 */
export const KnownOptionsManagementApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateKnownOption} createKnownOption 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOption(createKnownOption: CreateKnownOption, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnownOption>> {
            const localVarAxiosArgs = await KnownOptionsManagementApiAxiosParamCreator(configuration).createOption(createKnownOption, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOption(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await KnownOptionsManagementApiAxiosParamCreator(configuration).deleteOption(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOptionById(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnownOption>> {
            const localVarAxiosArgs = await KnownOptionsManagementApiAxiosParamCreator(configuration).getOptionById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOptionCommands(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OptionCommand>>> {
            const localVarAxiosArgs = await KnownOptionsManagementApiAxiosParamCreator(configuration).getOptionCommands(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [featureSetIds] 
         * @param {string} [groupIds] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOptions(featureSetIds?: string, groupIds?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnownOptionPage>> {
            const localVarAxiosArgs = await KnownOptionsManagementApiAxiosParamCreator(configuration).getOptions(featureSetIds, groupIds, page, limit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateKnownOption} updateKnownOption 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOption(id: string, updateKnownOption: UpdateKnownOption, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnownOption>> {
            const localVarAxiosArgs = await KnownOptionsManagementApiAxiosParamCreator(configuration).updateOption(id, updateKnownOption, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * KnownOptionsManagementApi - factory interface
 * @export
 */
export const KnownOptionsManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {CreateKnownOption} createKnownOption 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOption(createKnownOption: CreateKnownOption, options?: any): AxiosPromise<KnownOption> {
            return KnownOptionsManagementApiFp(configuration).createOption(createKnownOption, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOption(id: string, options?: any): AxiosPromise<void> {
            return KnownOptionsManagementApiFp(configuration).deleteOption(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOptionById(id: string, options?: any): AxiosPromise<KnownOption> {
            return KnownOptionsManagementApiFp(configuration).getOptionById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOptionCommands(id: string, options?: any): AxiosPromise<Array<OptionCommand>> {
            return KnownOptionsManagementApiFp(configuration).getOptionCommands(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [featureSetIds] 
         * @param {string} [groupIds] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOptions(featureSetIds?: string, groupIds?: string, page?: number, limit?: number, options?: any): AxiosPromise<KnownOptionPage> {
            return KnownOptionsManagementApiFp(configuration).getOptions(featureSetIds, groupIds, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateKnownOption} updateKnownOption 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOption(id: string, updateKnownOption: UpdateKnownOption, options?: any): AxiosPromise<KnownOption> {
            return KnownOptionsManagementApiFp(configuration).updateOption(id, updateKnownOption, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KnownOptionsManagementApi - object-oriented interface
 * @export
 * @class KnownOptionsManagementApi
 * @extends {BaseAPI}
 */
export class KnownOptionsManagementApi extends BaseAPI {
    /**
     * 
     * @param {CreateKnownOption} createKnownOption 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnownOptionsManagementApi
     */
    public createOption(createKnownOption: CreateKnownOption, options?: any) {
        return KnownOptionsManagementApiFp(this.configuration).createOption(createKnownOption, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnownOptionsManagementApi
     */
    public deleteOption(id: string, options?: any) {
        return KnownOptionsManagementApiFp(this.configuration).deleteOption(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnownOptionsManagementApi
     */
    public getOptionById(id: string, options?: any) {
        return KnownOptionsManagementApiFp(this.configuration).getOptionById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnownOptionsManagementApi
     */
    public getOptionCommands(id: string, options?: any) {
        return KnownOptionsManagementApiFp(this.configuration).getOptionCommands(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [featureSetIds] 
     * @param {string} [groupIds] 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnownOptionsManagementApi
     */
    public getOptions(featureSetIds?: string, groupIds?: string, page?: number, limit?: number, options?: any) {
        return KnownOptionsManagementApiFp(this.configuration).getOptions(featureSetIds, groupIds, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateKnownOption} updateKnownOption 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnownOptionsManagementApi
     */
    public updateOption(id: string, updateKnownOption: UpdateKnownOption, options?: any) {
        return KnownOptionsManagementApiFp(this.configuration).updateOption(id, updateKnownOption, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * MemoryModulesManagementApi - axios parameter creator
 * @export
 */
export const MemoryModulesManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MemoryModule} memoryModule 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMemoryModule: async (memoryModule: MemoryModule, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memoryModule' is not null or undefined
            if (memoryModule === null || memoryModule === undefined) {
                throw new RequiredError('memoryModule','Required parameter memoryModule was null or undefined when calling createMemoryModule.');
            }
            const localVarPath = `/management/memory-modules`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof memoryModule !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(memoryModule !== undefined ? memoryModule : {}) : (memoryModule || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMemoryModule: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteMemoryModule.');
            }
            const localVarPath = `/management/memory-modules/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemoryModuleById: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getMemoryModuleById.');
            }
            const localVarPath = `/management/memory-modules/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} featureSetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemoryModulesByFeatureSetId: async (featureSetId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'featureSetId' is not null or undefined
            if (featureSetId === null || featureSetId === undefined) {
                throw new RequiredError('featureSetId','Required parameter featureSetId was null or undefined when calling getMemoryModulesByFeatureSetId.');
            }
            const localVarPath = `/management/memory-modules`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (featureSetId !== undefined) {
                localVarQueryParameter['featureSetId'] = featureSetId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {MemoryModule} memoryModule 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMemoryModule: async (id: string, memoryModule: MemoryModule, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateMemoryModule.');
            }
            // verify required parameter 'memoryModule' is not null or undefined
            if (memoryModule === null || memoryModule === undefined) {
                throw new RequiredError('memoryModule','Required parameter memoryModule was null or undefined when calling updateMemoryModule.');
            }
            const localVarPath = `/management/memory-modules/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof memoryModule !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(memoryModule !== undefined ? memoryModule : {}) : (memoryModule || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MemoryModulesManagementApi - functional programming interface
 * @export
 */
export const MemoryModulesManagementApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MemoryModule} memoryModule 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMemoryModule(memoryModule: MemoryModule, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemoryModule>> {
            const localVarAxiosArgs = await MemoryModulesManagementApiAxiosParamCreator(configuration).createMemoryModule(memoryModule, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMemoryModule(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MemoryModulesManagementApiAxiosParamCreator(configuration).deleteMemoryModule(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMemoryModuleById(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemoryModule>> {
            const localVarAxiosArgs = await MemoryModulesManagementApiAxiosParamCreator(configuration).getMemoryModuleById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} featureSetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMemoryModulesByFeatureSetId(featureSetId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MemoryModule>>> {
            const localVarAxiosArgs = await MemoryModulesManagementApiAxiosParamCreator(configuration).getMemoryModulesByFeatureSetId(featureSetId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {MemoryModule} memoryModule 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMemoryModule(id: string, memoryModule: MemoryModule, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemoryModule>> {
            const localVarAxiosArgs = await MemoryModulesManagementApiAxiosParamCreator(configuration).updateMemoryModule(id, memoryModule, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MemoryModulesManagementApi - factory interface
 * @export
 */
export const MemoryModulesManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {MemoryModule} memoryModule 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMemoryModule(memoryModule: MemoryModule, options?: any): AxiosPromise<MemoryModule> {
            return MemoryModulesManagementApiFp(configuration).createMemoryModule(memoryModule, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMemoryModule(id: string, options?: any): AxiosPromise<void> {
            return MemoryModulesManagementApiFp(configuration).deleteMemoryModule(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemoryModuleById(id: string, options?: any): AxiosPromise<MemoryModule> {
            return MemoryModulesManagementApiFp(configuration).getMemoryModuleById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} featureSetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemoryModulesByFeatureSetId(featureSetId: string, options?: any): AxiosPromise<Array<MemoryModule>> {
            return MemoryModulesManagementApiFp(configuration).getMemoryModulesByFeatureSetId(featureSetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {MemoryModule} memoryModule 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMemoryModule(id: string, memoryModule: MemoryModule, options?: any): AxiosPromise<MemoryModule> {
            return MemoryModulesManagementApiFp(configuration).updateMemoryModule(id, memoryModule, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MemoryModulesManagementApi - object-oriented interface
 * @export
 * @class MemoryModulesManagementApi
 * @extends {BaseAPI}
 */
export class MemoryModulesManagementApi extends BaseAPI {
    /**
     * 
     * @param {MemoryModule} memoryModule 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemoryModulesManagementApi
     */
    public createMemoryModule(memoryModule: MemoryModule, options?: any) {
        return MemoryModulesManagementApiFp(this.configuration).createMemoryModule(memoryModule, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemoryModulesManagementApi
     */
    public deleteMemoryModule(id: string, options?: any) {
        return MemoryModulesManagementApiFp(this.configuration).deleteMemoryModule(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemoryModulesManagementApi
     */
    public getMemoryModuleById(id: string, options?: any) {
        return MemoryModulesManagementApiFp(this.configuration).getMemoryModuleById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} featureSetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemoryModulesManagementApi
     */
    public getMemoryModulesByFeatureSetId(featureSetId: string, options?: any) {
        return MemoryModulesManagementApiFp(this.configuration).getMemoryModulesByFeatureSetId(featureSetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {MemoryModule} memoryModule 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemoryModulesManagementApi
     */
    public updateMemoryModule(id: string, memoryModule: MemoryModule, options?: any) {
        return MemoryModulesManagementApiFp(this.configuration).updateMemoryModule(id, memoryModule, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * VehiclesApi - axios parameter creator
 * @export
 */
export const VehiclesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * VIN Decoder for Vehicle Details
         * @summary Returns Vehicle Info Details.
         * @param {string} vin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        decodeVIN: async (vin: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'vin' is not null or undefined
            if (vin === null || vin === undefined) {
                throw new RequiredError('vin','Required parameter vin was null or undefined when calling decodeVIN.');
            }
            const localVarPath = `/vehicles`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (vin !== undefined) {
                localVarQueryParameter['vin'] = vin;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VehiclesApi - functional programming interface
 * @export
 */
export const VehiclesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * VIN Decoder for Vehicle Details
         * @summary Returns Vehicle Info Details.
         * @param {string} vin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async decodeVIN(vin: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VINDecodedResult>> {
            const localVarAxiosArgs = await VehiclesApiAxiosParamCreator(configuration).decodeVIN(vin, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * VehiclesApi - factory interface
 * @export
 */
export const VehiclesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * VIN Decoder for Vehicle Details
         * @summary Returns Vehicle Info Details.
         * @param {string} vin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        decodeVIN(vin: string, options?: any): AxiosPromise<VINDecodedResult> {
            return VehiclesApiFp(configuration).decodeVIN(vin, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VehiclesApi - object-oriented interface
 * @export
 * @class VehiclesApi
 * @extends {BaseAPI}
 */
export class VehiclesApi extends BaseAPI {
    /**
     * VIN Decoder for Vehicle Details
     * @summary Returns Vehicle Info Details.
     * @param {string} vin 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public decodeVIN(vin: string, options?: any) {
        return VehiclesApiFp(this.configuration).decodeVIN(vin, options).then((request) => request(this.axios, this.basePath));
    }

}


