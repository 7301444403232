





































































import { Component, Vue } from "vue-property-decorator";
import { Faq } from "@/apis/axon_faqs";
import { FAQSModule } from "@/store/modules/faqs";
import { router } from "@/router";

@Component({ name: "FAQSView" })
export default class FAQSView extends Vue {
  mounted() {
    FAQSModule.fetchFaqById(this.$route.params.id);
  }
  get item(): Faq {
    return FAQSModule.faq;
  }

  async onDelete() {
    const res = confirm(
      `Are you sure you want to remove "${this.item.question}"?`
    );
    if (res) {
      FAQSModule.deleteFaq(this.$route.params.id);
      router.go(-1);
    }
  }
}
