import { AuthModule } from "@/store/modules/auth";
import About from "@/views/About.vue";
import Codes from "@/views/Codes/Codes.vue";
import CodesGeneration from "@/views/Codes/CodesGeneration.vue";
import DeviceDetails from "@/views/Devices/Details.vue";
import DeviceForm from "@/views/Devices/DeviceForm.vue";
import Devices from "@/views/Devices/Devices.vue";
import DeviceMessageForm from "@/views/Devices/SendMessage.vue";
import DiagnosticDetails from "@/views/Diagnostics/Details.vue";
import Diagnostics from "@/views/Diagnostics/Diagnostics.vue";
import FAQSForm from "@/views/FAQS/Crud/FAQSForm.vue";
import FAQSView from "@/views/FAQS/Crud/FAQSView.vue";
import FAQS from "@/views/FAQS/FAQS.vue";
import FeatureSetForm from "@/views/FeatureSets/Crud/FeatureSetForm.vue";
import FeatureSetView from "@/views/FeatureSets/Crud/FeatureSetView.vue";
import MemoryModuleForm from "@/views/FeatureSets/Crud/MemoryModuleForm.vue";
import FeatureSets from "@/views/FeatureSets/FeatureSets.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
import Home from "@/views/Home.vue";
import KnownOptionGroupForm from "@/views/KnownOptionGroups/Crud/KnownOptionGroupForm.vue";
import KnownOptionGroupView from "@/views/KnownOptionGroups/Crud/KnownOptionGroupView.vue";
import KnownOptionGroups from "@/views/KnownOptionGroups/KnownOptionGroups.vue";
import KnownOptionCommandForm from "@/views/KnownOptions/Crud/KnownOptionCommandForm.vue";
import KnownOptionCommandValueForm from "@/views/KnownOptions/Crud/KnownOptionCommandValueForm.vue";
import KnownOptionForm from "@/views/KnownOptions/Crud/KnownOptionForm.vue";
import KnownOptions from "@/views/KnownOptions/KnownOptions.vue";
import Login from "@/views/Login.vue";
import Transactions from "@/views/Transactions/Transactions.vue";
import UserCreateForm from "@/views/Users/Crud/UserCreateForm.vue";
import UserUpdateForm from "@/views/Users/Crud/UserUpdateForm.vue";
import UserView from "@/views/Users/Crud/UserView.vue";
import Users from "@/views/Users/Users.vue";
import VINDecoder from "@/views/VINDecoder/VINDecoder.vue";
import Welcome from "@/views/Welcome.vue";
import Workbench from "@/views/Workbench.vue";
import Vue from "vue";
import VueRouter, {
  NavigationGuard,
  NavigationGuardNext,
  Route,
  RouteConfig,
} from "vue-router";

Vue.use(VueRouter);

const ifAuthenticated: NavigationGuard<Vue> = (
  to: Route,
  from: Route,
  next: NavigationGuardNext<Vue>
) => {
  if (AuthModule.currentUser && to.name != from.name) {
    next();
    return;
  }
  next("/");
};

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "welcome",
    component: Welcome,
    meta: { title: "Welcome" },
  },
  {
    path: "/workbench",
    name: "Workbench",
    component: Workbench,
    meta: { title: "Workbench" },
  },
  {
    path: "/home",
    name: "home",
    component: Home,
    meta: {
      title: "Dashboard",
    },
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { title: "Admin Login" },
    children: [
      {
        path: "forgot-password",
        name: "forgot-password",
        component: ForgotPassword,
      },
    ],
  },
  {
    path: "/vin-decoder",
    name: "vin-decoder",
    component: VINDecoder,
    meta: { title: "VIN Decoder" },
  },
  {
    path: "/about",
    name: "About",
    component: About,
    meta: { title: "About" },
  },
  {
    path: "/faqs",
    name: "faqs",
    component: FAQS,
    meta: { title: "Frequently Asked Questions" },
    beforeEnter: ifAuthenticated,
    children: [{ path: "create", name: "faqs-create", component: FAQSForm }],
  },
  {
    path: "/faqs/:id",
    name: "faqs-view",
    component: FAQSView,
    meta: { title: "Frequently Asked Questions" },
    beforeEnter: ifAuthenticated,
    children: [{ path: "edit", name: "faqs-edit", component: FAQSForm }],
  },
  {
    path: "/diagnostics",
    name: "Diagnostics",
    component: Diagnostics,
    meta: { title: "Diagnostics" },
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: ":id",
        name: "diagnostics-details",
        beforeEnter: ifAuthenticated,
        component: DiagnosticDetails,
        meta: { title: "Diagnostics Details" },
      },
    ],
  },
  {
    path: "/feature-sets",
    name: "feature-sets",
    beforeEnter: ifAuthenticated,
    component: FeatureSets,
    meta: { title: "Feature Sets" },
    children: [
      {
        path: "create",
        name: "feature-set-create",
        beforeEnter: ifAuthenticated,
        component: FeatureSetForm,
        meta: { title: "New Feature Set" },
      },
    ],
  },
  {
    path: "/known-option-groups",
    name: "known-option-groups",
    beforeEnter: ifAuthenticated,
    component: KnownOptionGroups,
    meta: { title: "Known Option Groups" },
    children: [
      {
        path: "create",
        name: "known-option-group-create",
        beforeEnter: ifAuthenticated,
        component: KnownOptionGroupForm,
        meta: { title: "New Known Option Group" },
      },
    ],
  },
  {
    path: "/known-option-groups/:id",
    name: "known-option-group-view",
    beforeEnter: ifAuthenticated,
    component: KnownOptionGroupView,
    meta: { title: "Known Option Group" },
    children: [
      {
        path: "edit",
        name: "known-option-group-edit",
        beforeEnter: ifAuthenticated,
        component: KnownOptionGroupForm,
        meta: { title: "Edit Known Option Group" },
      },
    ],
  },
  {
    path: "/feature-sets/:id",
    name: "feature-set-view",
    beforeEnter: ifAuthenticated,
    component: FeatureSetView,
    meta: { title: "View Feature Set" },
    children: [
      {
        path: "edit",
        name: "feature-set-edit",
        beforeEnter: ifAuthenticated,
        component: FeatureSetForm,
        meta: { title: "Edit Feature Set" },
      },
      {
        path: "memory-module/create",
        name: "memory-module-create",
        beforeEnter: ifAuthenticated,
        component: MemoryModuleForm,
        meta: { title: "Create Memory Module" },
      },
      {
        path: "memory-module/:memoryModuleId",
        name: "memory-module-edit",
        beforeEnter: ifAuthenticated,
        component: MemoryModuleForm,
        meta: { title: "Edit Memory Module" },
      },
    ],
  },
  {
    path: "/known-options",
    name: "KnownOptions",
    beforeEnter: ifAuthenticated,
    component: KnownOptions,
    meta: { title: "Known Options" },
    children: [
      {
        path: "create",
        name: "known-option-create",
        beforeEnter: ifAuthenticated,
        component: KnownOptionForm,
        meta: { title: "New Known Option" },
      },
      {
        path: ":id/edit",
        name: "known-option-edit",
        beforeEnter: ifAuthenticated,
        component: KnownOptionForm,
        meta: { title: "Edit Known Option" },
      },
      {
        path: ":id/add-command",
        name: "known-option-add-command",
        beforeEnter: ifAuthenticated,
        component: KnownOptionCommandForm,
        meta: { title: "Add Known Option Command" },
      },
      {
        path: ":id/edit-command/:commandId",
        name: "known-option-edit-command",
        beforeEnter: ifAuthenticated,
        component: KnownOptionCommandForm,
        meta: { title: "Edit Known Option Command" },
      },
      {
        path: ":id/command/:commandId/add-value",
        name: "known-option-command-add-value",
        beforeEnter: ifAuthenticated,
        component: KnownOptionCommandValueForm,
        meta: { title: "New Known Option Command Value" },
      },
      {
        path: ":id/command/:commandId/edit-value/:valueId",
        name: "known-option-command-edit-value",
        beforeEnter: ifAuthenticated,
        component: KnownOptionCommandValueForm,
        meta: { title: "Edit Known Option Command Value" },
      },
    ],
  },
  {
    path: "/users",
    name: "Users",
    beforeEnter: ifAuthenticated,
    component: Users,
    meta: { title: "Users" },
    children: [
      {
        path: "create",
        name: "users-create",
        component: UserCreateForm,
        meta: { title: "New User" },
      },
    ],
  },
  {
    path: "/users/:id",
    name: "user-view",
    beforeEnter: ifAuthenticated,
    component: UserView,
    meta: { title: "User Details" },
    children: [
      {
        path: "edit",
        name: "user-edit",
        beforeEnter: ifAuthenticated,
        component: UserUpdateForm,
        meta: { title: "Edit User" },
      },
    ],
  },
  {
    path: "/devices",
    name: "devices",
    beforeEnter: ifAuthenticated,
    component: Devices,
    meta: { title: "Registered Devices" },
    children: [],
  },
  {
    path: "/devices/:id",
    name: "device-details",
    beforeEnter: ifAuthenticated,
    component: DeviceDetails,
    meta: { title: "Device Details" },
    children: [
      {
        path: "edit",
        name: "device-edit",
        beforeEnter: ifAuthenticated,
        component: DeviceForm,
        meta: { title: "Edit Device" },
      },
      {
        path: "message",
        name: "device-message",
        beforeEnter: ifAuthenticated,
        component: DeviceMessageForm,
        meta: { title: "Send Message to Device" },
      },
    ],
  },
  {
    path: "/transactions",
    name: "transactions",
    beforeEnter: ifAuthenticated,
    component: Transactions,
    meta: { title: "Transactions" },
  },
  {
    path: "/codes",
    name: "codes",
    beforeEnter: ifAuthenticated,
    component: Codes,
    meta: { title: "Redeemable Codes" },
    children: [
      {
        path: "generate",
        name: "codes-generate",
        beforeEnter: ifAuthenticated,
        component: CodesGeneration,
        meta: { title: "Codes Generation" },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = "Axon Admin - " + (to.meta.title || `${to.path}`);
  });
});

export { router, routes };
