import {
  CreateOptionCommand,
  KnownOptionCommandsManagementApi,
  KnownOptionsManagementApi,
  OptionCommand,
  UpdateOptionCommand,
} from "@/apis/axon_configurations";
import { getIDTokenFromCurrentUser } from "@/plugins/firebase";
import store from "@/store";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";

export interface OptionCommandsState {
  commands: OptionCommand[];
  command: OptionCommand;
}

@Module({
  dynamic: true,
  namespaced: true,
  name: "KnownOptionCommands",
  store,
})
class KnownOptionCommands extends VuexModule {
  public commands: OptionCommand[] = [];
  public command: OptionCommand = {} as OptionCommand;

  @Mutation
  setCommand(command: OptionCommand) {
    this.command = command;
  }

  @Mutation
  setCommands(commands: OptionCommand[]) {
    this.commands = commands;
  }

  @Action
  async fetchCommands(action: ListOptionCommandsAction) {
    const token = await getIDTokenFromCurrentUser();
    const api = new KnownOptionsManagementApi({
      basePath: "/api/configurations-service",
      accessToken: token,
    });
    try {
      this.context.commit("Loader/increase", null, { root: true });
      this.context.commit(
        "setCommands",
        (await api.getOptionCommands(action.knownOptionId)).data
      );
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }

  @Action
  async fetchCommandById(commandId: string) {
    const token = await getIDTokenFromCurrentUser();
    const api = new KnownOptionCommandsManagementApi({
      basePath: "/api/configurations-service",
      accessToken: token,
    });
    try {
      this.context.commit("Loader/increase", null, { root: true });
      this.context.commit(
        "setCommand",
        (await api.getCommandById(commandId)).data
      );
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }

  @Action
  async updateCommand(command: UpdateOptionCommandAction) {
    const token = await getIDTokenFromCurrentUser();
    const api = new KnownOptionCommandsManagementApi({
      basePath: "/api/configurations-service",
      accessToken: token,
    });
    try {
      this.context.commit("Loader/increase", null, { root: true });
      const _command = (await api.updateCommand(command.commandId, command))
        .data;
      this.context.commit("setCommand", _command);
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }

  @Action
  async saveCommand(form: CreateOptionCommand) {
    const token = await getIDTokenFromCurrentUser();
    const api = new KnownOptionCommandsManagementApi({
      basePath: "/api/configurations-service",
      accessToken: token,
    });
    try {
      this.context.commit("Loader/increase", null, { root: true });
      const _command = (await api.createCommand(form)).data;
      this.context.commit("setCommand", _command);
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }

  @Action
  async deleteCommandById(commandId: string) {
    const token = await getIDTokenFromCurrentUser();
    const api = new KnownOptionCommandsManagementApi({
      basePath: "/api/configurations-service",
      accessToken: token,
    });
    try {
      this.context.commit("Loader/increase", null, { root: true });
      (await api.deleteCommand(commandId)).data;
      this.context.commit("setCommand", { commandId: commandId });
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }
}
export class ListOptionCommandsAction {
  constructor(public readonly knownOptionId: string) {}
  limit = 10;
  page = 1;
}

export interface UpdateOptionCommandAction extends UpdateOptionCommand {
  commandId: string;
}

export const KnownOptionCommandsModule = getModule(KnownOptionCommands);
