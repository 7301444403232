





















































import { Component, Vue, Watch } from "vue-property-decorator";
import {
  TransactionsHistoryModule,
  ListTransactionsAction,
} from "@/store/modules/transactions-history";
import { TransactionHistoryPage } from "@/apis/axon_user_device_info";

@Component({ name: "TransactionsHistory" })
export default class TransactionsHistory extends Vue {
  search = "";
  action = new ListTransactionsAction();
  options = {};
  headers = [
    {
      text: "Reference",
      value: "reference",
      sortable: false,
    },
    { text: "Email", value: "email", sortable: false },
    { text: "SKU", value: "sku", sortable: false },
    { text: "Amount", value: "amount", sortable: false },
    { text: "Currency", value: "currency", sortable: false },
    { text: "Platform", value: "platform", sortable: false },
    { text: "Status", value: "status", sortable: false },
    { text: "Created", value: "created", sortable: false },
    { text: "Updated", value: "modified", sortable: false },
  ];

  @Watch("options")
  optionsChanged() {
    this.searchRemote();
  }

  get page() {
    return TransactionsHistoryModule.page as TransactionHistoryPage;
  }

  searchRemote() {
    TransactionsHistoryModule.fetchTransactions(this.action);
  }
}
