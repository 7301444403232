




















































import { Component, Vue, Watch } from "vue-property-decorator";
import {
  KnownOptionCommandValuesModule,
  UpdateOptionCommandValueAction,
} from "@/store/modules/known-option-command-values";
import { VForm } from "@/types";
import { CreateOptionCommandValue } from "@/apis/axon_configurations";
import { KnownOptionCommandsModule } from "@/store/modules/known-option-commands";

@Component({ name: "KnownOptionCommandValueForm" })
export default class KnownOptionCommandValueForm extends Vue {
  dialog = true;
  create = {
    commandId: this.$route.params.commandId,
  } as CreateOptionCommandValue;

  valueHex: string[] = [];

  created() {
    if (this.$route.params.valueId) {
      KnownOptionCommandValuesModule.fetchCommandValueBy(
        this.$route.params.valueId
      );
    }
    KnownOptionCommandsModule.fetchCommandById(this.$route.params.commandId);
  }

  get model() {
    return this.$route.params.valueId
      ? KnownOptionCommandValuesModule.value
      : this.create;
  }

  get command() {
    return KnownOptionCommandsModule.command;
  }

  @Watch("command")
  onCommandUpdated() {
    this.valueHex = [];
    if (this.model.valueHex) {
      for (let i = 0; i < this.model.valueHex.length; i++)
        this.valueHex[i] = this.model.valueHex.charAt(i);
    }
  }

  get hexes() {
    return [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
    ];
  }

  async onSave() {
    if ((this.$refs.form as VForm).validate()) {
      this.command.valueTemplate.forEach((x, i) => {
        if (x != "*") this.valueHex[i] = "X";
      });
      this.model.valueHex = this.valueHex.join("");
      if (this.$route.params.valueId) {
        await KnownOptionCommandValuesModule.updateValue(
          this.model as UpdateOptionCommandValueAction
        );
      } else {
        await KnownOptionCommandValuesModule.saveValue(
          this.model as CreateOptionCommandValue
        );
      }
      this.$router.go(-1);
    }
  }
}
