import {
  CreateUserRequest,
  UpdateUserRequest,
  User,
  UserManagementApi,
  UserPage,
} from "@/apis/axon_user_device_info";
import { getIDTokenFromCurrentUser } from "@/plugins/firebase";
import store from "@/store";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";

export interface UsersState {
  usersPage: UserPage;
  selectedUser: User;
}

@Module({ dynamic: true, namespaced: true, name: "Users", store })
class Users extends VuexModule implements UsersState {
  public usersPage: UserPage = {
    users: [],
    limit: 10,
    pageToken: "",
  };

  public selectedUser: User = {} as User;

  @Mutation
  updateSelectedUser(user: User) {
    this.selectedUser = user;
  }

  @Mutation
  updatePage(usersPage: UserPage) {
    usersPage.users.forEach((x) => {
      const found = this.usersPage.users.find((u) => u.id == x.id);
      if (found) {
        this.usersPage.users[this.usersPage.users.indexOf(found)] = x;
      } else {
        this.usersPage.users.push(x);
      }
    });
    this.usersPage.pageToken = usersPage.pageToken;
  }

  @Mutation
  mergeStateUsers(user: User) {
    const found = this.usersPage.users.find((u) => u.id == user.id);
    if (found) {
      this.usersPage.users[this.usersPage.users.indexOf(found)] = user;
    } else {
      this.usersPage.users.push(user);
    }
  }

  @Action
  async fetchUsers(currentPage: UserPage) {
    const token = await getIDTokenFromCurrentUser();
    const api = new UserManagementApi({
      basePath: "/api/user-device-info-service",
      accessToken: token,
    });
    try {
      this.context.commit("Loader/increase", null, { root: true });
      const page = (
        await api.getUsers(
          !currentPage.pageToken || currentPage.pageToken == ""
            ? undefined
            : currentPage.pageToken,
          currentPage.limit
        )
      ).data;
      this.context.commit("updatePage", page);
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }

  @Action
  async fetchUserById(id: string) {
    const token = await getIDTokenFromCurrentUser();
    const api = new UserManagementApi({
      basePath: "/api/user-device-info-service",
      accessToken: token,
    });
    try {
      this.context.commit("Loader/increase", null, { root: true });
      const user = (await api.getUserById(id)).data;
      this.context.commit("updateSelectedUser", user);
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }

  @Action
  async updateUser(model: UpdateUserAction) {
    const token = await getIDTokenFromCurrentUser();
    const api = new UserManagementApi({
      basePath: "/api/user-device-info-service",
      accessToken: token,
    });

    try {
      this.context.commit("Loader/increase", null, { root: true });
      if (model.id && model.update) {
        const user = (await api.updateUser(model.id, model.update)).data;
        user.id = model.id;
        this.context.commit("mergeStateUsers", user);
        this.context.commit("updateSelectedUser", user);
      }
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }

  @Action
  async createUser(model: CreateUserAction) {
    const token = await getIDTokenFromCurrentUser();
    const api = new UserManagementApi({
      basePath: "/api/user-device-info-service",
      accessToken: token,
    });
    try {
      this.context.commit("Loader/increase", null, { root: true });
      if (model.create) {
        const user = (await api.createUser(model.create)).data;
        this.context.commit("mergeStateUsers", user);
      }
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
  }

  @Action
  async searchUserByEmail(email: string): Promise<User | null> {
    const token = await getIDTokenFromCurrentUser();
    const api = new UserManagementApi({
      basePath: "/api/user-device-info-service",
      accessToken: token,
    });
    try {
      this.context.commit("Loader/increase", null, { root: true });
      const user = (await api.getUserByEmail(email)).data;
      this.context.commit("mergeStateUsers", user);
      return user;
    } catch (e) {
      this.context.commit("Loader/setMessage", e.message, { root: true });
    } finally {
      this.context.commit("Loader/decrease", null, { root: true });
    }
    return null;
  }
}

export interface UpdateUserAction {
  id: string | undefined;
  update: UpdateUserRequest | undefined;
}

export interface CreateUserAction {
  create: CreateUserRequest | undefined;
}

export const UserModule = getModule(Users);
