


























































import { Component, Vue } from "vue-property-decorator";
import { AuthModule, LoginRequest } from "@/store/modules/auth";
import { router } from "@/router";
import { VForm } from "@/types";

@Component({ name: "LoginComponent" })
export default class LoginComponent extends Vue {
  loginRequest: LoginRequest = new LoginRequest();

  rules = {
    required: (v: string) => !!v || "Required.",
    email: (v: string) => /.+@.+\..+/.test(v) || "E-mail must be valid",
  };

  mounted() {
    AuthModule.checkIfSignedIn();
  }

  async login() {
    if ((this.$refs.form as VForm).validate()) {
      const user = await AuthModule.doLogin(this.loginRequest);
      if (user) {
        router.push({ name: "home" });
      }
    }
  }
}
