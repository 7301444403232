







































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { router } from "@/router";
import {
  KnownOptionCommandsModule,
  UpdateOptionCommandAction,
} from "@/store/modules/known-option-commands";

import { MemoryModulesModule } from "@/store/modules/memory-modules";
import { VForm } from "@/types";
import { CreateOptionCommand } from "@/apis/axon_configurations";
import AddressMasker from "@/components/AddressMasker.vue";

@Component({ name: "KnownOptionCommandForm", components: { AddressMasker } })
export default class KnownOptionCommandForm extends Vue {
  dialog = true;
  inputTypes = ["NONE", "DROPDOWN", "SWITCH"];
  newInstance = {
    knownOptionId: this.$route.params.id,
    userInput: false,
    valueLength: 2,
    controlType: "NONE",
    staticValueVariables: [""],
    dynamicValueVariables: [""],
    valueTemplate: [""],
  } as CreateOptionCommand;

  created() {
    if (this.$route.params.commandId) {
      KnownOptionCommandsModule.fetchCommandById(this.$route.params.commandId);
    }
    MemoryModulesModule.fetchMemoryModules({
      knownOptionId: this.$route.params.id,
    });
  }

  get model(): CreateOptionCommand | UpdateOptionCommandAction {
    return this.$route.params.commandId
      ? KnownOptionCommandsModule.command
      : this.newInstance;
  }

  get memoryModules() {
    return MemoryModulesModule.memoryModules;
  }

  get renderedTemplate() {
    if (!this.model.valueTemplate) return "...";
    const res: string[] = [];
    this.model.valueTemplate.forEach((x, i) => {
      res.push(x);
      if ((i + 1) % 4 == 0) res.push(" ");
    });
    return res.join("");
  }

  @Watch("model.module")
  onModuleChanged() {
    const module = this.memoryModules.find((x) => x.name == this.model.module);
    if (module) {
      this.model.address = module.address;
    }
  }

  async onSave() {
    if ((this.$refs.form as VForm).validate()) {
      this.model.userInput = this.model.valueTemplate.indexOf("*") >= 0;
      if (this.$route.params.commandId) {
        await KnownOptionCommandsModule.updateCommand(
          this.model as UpdateOptionCommandAction
        );
      } else {
        await KnownOptionCommandsModule.saveCommand(this.model);
      }
      router.go(-1);
    }
  }
}
