var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{attrs:{"label":"VIN","color":"secondary","outlined":"","dense":"","required":"","clearable":""},model:{value:(_vm.searchDiagnostics.vin),callback:function ($$v) {_vm.$set(_vm.searchDiagnostics, "vin", $$v)},expression:"searchDiagnostics.vin"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{attrs:{"label":"User ID","color":"secondary","outlined":"","dense":"","required":"","clearable":""},model:{value:(_vm.searchDiagnostics.userId),callback:function ($$v) {_vm.$set(_vm.searchDiagnostics, "userId", $$v)},expression:"searchDiagnostics.userId"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{attrs:{"label":"Device Serial Number","color":"secondary","outlined":"","dense":"","required":"","clearable":""},model:{value:(_vm.searchDiagnostics.deviceId),callback:function ($$v) {_vm.$set(_vm.searchDiagnostics, "deviceId", $$v)},expression:"searchDiagnostics.deviceId"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":"E-mail","color":"secondary","outlined":"","dense":"","required":"","clearable":""},model:{value:(_vm.searchDiagnostics.email),callback:function ($$v) {_vm.$set(_vm.searchDiagnostics, "email", $$v)},expression:"searchDiagnostics.email"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.searchRemote}},[_vm._v("Search")])],1)],1)],1),_c('v-divider'),_c('v-data-table',{attrs:{"page":_vm.searchDiagnostics.page,"pageCount":_vm.pageResult.items / _vm.pageResult.limit,"server-items-length":_vm.pageResult.total,"headers":_vm.headers,"items":_vm.pageResult.docs,"footer-props":{
        'items-per-page-options': [10, 20, 50],
      },"items-per-page":_vm.searchDiagnostics.limit,"search":_vm.search,"options":_vm.options},on:{"update:page":function($event){return _vm.$set(_vm.searchDiagnostics, "page", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.searchDiagnostics, "limit", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.searchDiagnostics, "limit", $event)},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(item.id))])]}},{key:"item.executionDate",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.executionDate,"from", "now"))+" ")]}},{key:"item.registrationDate",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.registrationDate,"from", "now"))+" ")]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","text":""},on:{"click":function($event){return _vm.onItemClick(item)}}},[_c('v-icon',[_vm._v("mdi-eye")]),_vm._v(" View ")],1),_c('v-btn',{attrs:{"small":"","icon":"","color":"error"},on:{"click":function($event){return _vm.onItemDelete(item)}}},[_c('v-icon',[_vm._v("mdi-trash-can")])],1)]}}],null,true)})],1),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }