var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.selectedCommand)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-0",attrs:{"md":"3"}},[_c('v-list',{staticClass:"ma-0",attrs:{"dense":"","subheader":"","elevation":"0","two-line":""}},[_c('v-subheader',{staticClass:"title"},[_vm._v(" List of Commands ")]),_c('v-list-item-group',{attrs:{"color":"primary"},model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}},[_c('draggable',{on:{"change":_vm.changeCommandOrder},model:{value:(_vm.commandsSynced),callback:function ($$v) {_vm.commandsSynced=$$v},expression:"commandsSynced"}},_vm._l((_vm.commandsSynced),function(item,i){return _c('v-list-item',{key:i.commandId},[_c('v-list-item-content',[_c('v-list-item-title',[_c('code',{staticClass:"text-subtitle-1 font-weight-black"},[_vm._v(_vm._s(item.module))]),_vm._v(" "+_vm._s(item.inputLabel)+" ")]),_c('v-list-item-subtitle',[_c('v-sheet',{attrs:{"elevation-2":""}},[_c('code',[_vm._v(" "+_vm._s(item.address)+"-"+_vm._s(item.block)+"-"+_vm._s(item.line)+" "+_vm._s(_vm.getInterpretedValue(item))+" ")])])],1),_c('v-list-item-subtitle')],1)],1)}),1)],1)],1)],1),_c('v-col',{attrs:{"md":_vm.selectedCommand && _vm.selectedCommand.userInput ? 5 : 5,"no-gutters":_vm.$vuetify.breakpoint.xsOnly}},[_c('v-sheet',{staticClass:"pa-4",attrs:{"elevation":"2","rounded":"lg"}},[_c('v-list',{attrs:{"dense":"","subheader":""}},[_c('v-subheader',{staticClass:"title"},[_vm._v("Details "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"primary","to":{
              name: 'known-option-edit-command',
              params: {
                id: _vm.knownOptionId,
                commandId: _vm.selectedCommand.commandId,
              },
            }}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.deleteCommand(_vm.selectedCommand)}}},[_c('v-icon',[_vm._v(" mdi-trash-can ")])],1)],1),_vm._l((_vm.commandFields),function(f){return _c('v-list-item',{key:f.text},[_c('v-list-item-content',[_c('v-list-item-title',[_c('span',{staticClass:"font-weight-bold mr-2"},[_vm._v(_vm._s(f.text))])])],1),_c('v-list-item-content',[_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(f.value))])])],1)})],2)],1)],1),(_vm.selectedCommand && _vm.selectedCommand.userInput)?_c('v-col',{attrs:{"cols":"12","md":"4","no-gutters":""}},[_c('v-list',{staticClass:"ml-2",attrs:{"dense":""}},[_c('v-subheader',{staticClass:"title"},[_vm._v("Available Values "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"primary","to":{
            name: 'known-option-command-add-value',
            params: {
              id: _vm.knownOptionId,
              commandId: _vm.selectedCommand.commandId,
            },
          }}},[_c('v-icon',[_vm._v(" mdi-plus ")])],1)],1),_c('v-expansion-panels',{staticClass:"ma-0 pa-0 d-flex flex-column"},[_c('draggable',{on:{"change":_vm.changeValueOrder},model:{value:(_vm.selectedCommand.commandValues),callback:function ($$v) {_vm.$set(_vm.selectedCommand, "commandValues", $$v)},expression:"selectedCommand.commandValues"}},[_c('transition-group',_vm._l((_vm.selectedCommand.commandValues),function(v){return _c('v-expansion-panel',{key:v.valueId},[_c('v-expansion-panel-header',[_c('v-row',{staticClass:"pa-0 ma-0",attrs:{"no-gutters":"","light":""}},[_c('v-col',[_c('div',{staticClass:"caption font-weight-black"},[_vm._v(" "+_vm._s(v.label)+" ")]),_c('code',[_vm._v(_vm._s(_vm.selectedCommand.address)+"-"+_vm._s(_vm.selectedCommand.block)+"-"+_vm._s(_vm.selectedCommand.line)+" "+_vm._s(_vm.getInterpretedValue(_vm.selectedCommand, v))+" ")])])],1)],1),_c('v-expansion-panel-content',[_c('div',{staticClass:"ma-0"},[_c('div',{staticClass:"font-weight-bold caption"},[_vm._v("ID:")]),_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(v.valueId)+" ")])]),_c('div',{staticClass:"ma-0"},[_c('div',{staticClass:"font-weight-bold caption"},[_vm._v("Created:")]),_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm._f("moment")(v.created,"from", "now"))+" ")])]),_c('div',{staticClass:"ma-0"},[_c('div',{staticClass:"font-weight-bold caption"},[_vm._v("Modified:")]),_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm._f("moment")(v.updated,"from", "now"))+" ")])]),_c('v-row',{staticClass:"pt-2",attrs:{"no-gutters":""}},[_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","small":"","color":"primary","to":{
                      name: 'known-option-command-edit-value',
                      params: {
                        id: _vm.knownOptionId,
                        commandId: _vm.selectedCommand.commandId,
                        valueId: v.valueId,
                      },
                    }}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{attrs:{"icon":"","small":"","color":"red"},on:{"click":function($event){return _vm.deleteCommandValue(v)}}},[_c('v-icon',[_vm._v(" mdi-trash-can ")])],1)],1)],1),_c('v-divider')],1)}),1)],1)],1)],1)],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }