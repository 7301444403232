
























































































import { Component, Vue } from "vue-property-decorator";
import {
  MemoryModulesModule,
  SaveMemoryModulesAction,
} from "@/store/modules/memory-modules";
import { VForm } from "@/types";
import { MemoryModule } from "@/apis/axon_configurations";

@Component({ name: "MemoryModuleForm" })
export default class MemoryModuleForm extends Vue {
  dialog = true;

  created() {
    if (this.$route.params.memoryModuleId) {
      MemoryModulesModule.fetchMemoryModuleById(
        this.$route.params.memoryModuleId
      );
    }
  }

  get model() {
    if (this.$route.params.memoryModuleId) {
      return MemoryModulesModule.memoryModule;
    }
    return {
      featureSetId: this.$route.params.id,
    } as MemoryModule;
  }

  async onSave() {
    if ((this.$refs.form as VForm).validate()) {
      await MemoryModulesModule.saveMemoryModule(
        this.model as SaveMemoryModulesAction
      );
      this.$router.go(-1);
    }
  }
}
