// tslint:disable
/**
 * FAQS
 * This Service is intented for handling FAQS Content
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Faq
 */
export interface Faq {
    /**
     * 
     * @type {string}
     * @memberof Faq
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Faq
     */
    question: string;
    /**
     * 
     * @type {string}
     * @memberof Faq
     */
    answer: string;
    /**
     * 
     * @type {number}
     * @memberof Faq
     */
    order: number;
    /**
     * 
     * @type {string}
     * @memberof Faq
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof Faq
     */
    updated?: string;
}

/**
 * FAQSApi - axios parameter creator
 * @export
 */
export const FAQSApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create FAQ
         * @summary Create FAQ
         * @param {Faq} faq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFAQ: async (faq: Faq, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'faq' is not null or undefined
            if (faq === null || faq === undefined) {
                throw new RequiredError('faq','Required parameter faq was null or undefined when calling createFAQ.');
            }
            const localVarPath = `/faqs`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof faq !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(faq !== undefined ? faq : {}) : (faq || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete FAQ
         * @summary Delete FAQ
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFAQ: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteFAQ.');
            }
            const localVarPath = `/faqs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * FAQ by ID
         * @summary FAQ by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFAQById: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getFAQById.');
            }
            const localVarPath = `/faqs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List of  FAQS
         * @summary List of FAQS
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFAQs: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/faqs`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update FAQ
         * @summary Update FAQ
         * @param {string} id 
         * @param {Faq} faq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFAQ: async (id: string, faq: Faq, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateFAQ.');
            }
            // verify required parameter 'faq' is not null or undefined
            if (faq === null || faq === undefined) {
                throw new RequiredError('faq','Required parameter faq was null or undefined when calling updateFAQ.');
            }
            const localVarPath = `/faqs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof faq !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(faq !== undefined ? faq : {}) : (faq || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FAQSApi - functional programming interface
 * @export
 */
export const FAQSApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Create FAQ
         * @summary Create FAQ
         * @param {Faq} faq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFAQ(faq: Faq, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Faq>> {
            const localVarAxiosArgs = await FAQSApiAxiosParamCreator(configuration).createFAQ(faq, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Delete FAQ
         * @summary Delete FAQ
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFAQ(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await FAQSApiAxiosParamCreator(configuration).deleteFAQ(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * FAQ by ID
         * @summary FAQ by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFAQById(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Faq>> {
            const localVarAxiosArgs = await FAQSApiAxiosParamCreator(configuration).getFAQById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * List of  FAQS
         * @summary List of FAQS
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFAQs(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Faq>>> {
            const localVarAxiosArgs = await FAQSApiAxiosParamCreator(configuration).getFAQs(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update FAQ
         * @summary Update FAQ
         * @param {string} id 
         * @param {Faq} faq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFAQ(id: string, faq: Faq, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Faq>> {
            const localVarAxiosArgs = await FAQSApiAxiosParamCreator(configuration).updateFAQ(id, faq, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * FAQSApi - factory interface
 * @export
 */
export const FAQSApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Create FAQ
         * @summary Create FAQ
         * @param {Faq} faq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFAQ(faq: Faq, options?: any): AxiosPromise<Faq> {
            return FAQSApiFp(configuration).createFAQ(faq, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete FAQ
         * @summary Delete FAQ
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFAQ(id: string, options?: any): AxiosPromise<void> {
            return FAQSApiFp(configuration).deleteFAQ(id, options).then((request) => request(axios, basePath));
        },
        /**
         * FAQ by ID
         * @summary FAQ by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFAQById(id: string, options?: any): AxiosPromise<Faq> {
            return FAQSApiFp(configuration).getFAQById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * List of  FAQS
         * @summary List of FAQS
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFAQs(options?: any): AxiosPromise<Array<Faq>> {
            return FAQSApiFp(configuration).getFAQs(options).then((request) => request(axios, basePath));
        },
        /**
         * Update FAQ
         * @summary Update FAQ
         * @param {string} id 
         * @param {Faq} faq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFAQ(id: string, faq: Faq, options?: any): AxiosPromise<Faq> {
            return FAQSApiFp(configuration).updateFAQ(id, faq, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FAQSApi - object-oriented interface
 * @export
 * @class FAQSApi
 * @extends {BaseAPI}
 */
export class FAQSApi extends BaseAPI {
    /**
     * Create FAQ
     * @summary Create FAQ
     * @param {Faq} faq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FAQSApi
     */
    public createFAQ(faq: Faq, options?: any) {
        return FAQSApiFp(this.configuration).createFAQ(faq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete FAQ
     * @summary Delete FAQ
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FAQSApi
     */
    public deleteFAQ(id: string, options?: any) {
        return FAQSApiFp(this.configuration).deleteFAQ(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * FAQ by ID
     * @summary FAQ by ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FAQSApi
     */
    public getFAQById(id: string, options?: any) {
        return FAQSApiFp(this.configuration).getFAQById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List of  FAQS
     * @summary List of FAQS
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FAQSApi
     */
    public getFAQs(options?: any) {
        return FAQSApiFp(this.configuration).getFAQs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update FAQ
     * @summary Update FAQ
     * @param {string} id 
     * @param {Faq} faq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FAQSApi
     */
    public updateFAQ(id: string, faq: Faq, options?: any) {
        return FAQSApiFp(this.configuration).updateFAQ(id, faq, options).then((request) => request(this.axios, this.basePath));
    }

}


