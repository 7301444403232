





















































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { AxonDevice } from "@/apis/axon_user_device_info";
import { ListDevicesAction } from "@/store/modules/devices";
import { UserModule } from "@/store/modules/users";
import { UsersCapabilitiesModule } from "@/store/modules/user-capabilities";
import { DevicesModule } from "@/store/modules/devices";

@Component({ name: "UserViewComponent" })
export default class UserViewComponent extends Vue {
  //----
  search = "";
  listDevicesAction = new ListDevicesAction();

  get item() {
    return UserModule.selectedUser;
  }

  mounted() {
    UsersCapabilitiesModule.fetchCodes(this.$route.params.id);
    UsersCapabilitiesModule.fetchHistory(this.$route.params.id);
    UserModule.fetchUserById(this.$route.params.id);
  }

  public created() {
    this.listDevicesAction.page = 1;
    this.listDevicesAction.email = this.item.email;
    DevicesModule.fetchDevices(this.listDevicesAction);
  }

  get capabilities() {
    return UsersCapabilitiesModule.capabilities;
  }

  get history() {
    return UsersCapabilitiesModule.history;
  }

  headers = [
    // { text: "ID", value: "deviceId", sortable: false },
    { text: "Device Identifier", value: "deviceIdentifier", sortable: false },
    { text: "DeviceVersion", value: "deviceVersion", sortable: false },
    {
      text: "VIN",
      value: "vin",
      sortable: false,
    },
    { text: "Status", value: "status", sortable: false },
    { text: "Restriction", value: "open", sortable: false },
    { text: "Actions", value: "actions", sortable: false },
  ];

  historyHeaders = [
    // { text: "ID", value: "deviceId", sortable: false },
    { text: "Entry", value: "action", sortable: false },
    // { text: "Source", value: "source", sortable: false },
    { text: "Source", value: "sourceType", sortable: false },
    {
      text: "Points",
      value: "points",
      sortable: false,
    },
    { text: "Balance Before", value: "balanceBefore", sortable: false },
    { text: "Balance after", value: "balanceAfter", sortable: false },
    { text: "Created", value: "created", sortable: false },
  ];

  @Watch("options")
  optionsChanged() {
    DevicesModule.fetchDevices(this.listDevicesAction);
  }

  options = {};

  get pageResult() {
    return DevicesModule.page;
  }

  onItemClick(item: AxonDevice) {
    this.$router.push({
      name: "device-details",
      params: { id: item.deviceId },
    });
  }
}
