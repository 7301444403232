










































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import KnownOptionDisplay from "@/components/KnownOptionDisplay.vue";
import KnownOptionCommandsDisplay from "@/components/KnownOptionCommandsDisplay.vue";

import {
  KnownOptionsModule,
  ListKnownOptionsAction,
} from "@/store/modules/known-options";
import { FeatureSetsModule } from "@/store/modules/feature-sets";
import { KnownOptionsGroupsModule } from "@/store/modules/known-option-groups";
import { KnownOptionCommandsModule } from "@/store/modules/known-option-commands";
import { KnownOptionCommandValuesModule } from "@/store/modules/known-option-command-values";
import { KnownOption } from "@/apis/axon_configurations";

@Component({
  name: "KnownOptionsListComponent",
  components: { KnownOptionDisplay, KnownOptionCommandsDisplay },
})
export default class KnownOptionsListComponent extends Vue {
  search = "";
  action = new ListKnownOptionsAction();
  headers = [
    // { text: "ID", value: "knownOptionId", sortable: false },
    {
      text: "Name",
      value: "name",
      sortable: false,
    },
    { text: "SKU", value: "sku", sortable: false },
    { text: "Group", value: "groupId", sortable: false },
    { text: "Feature Set", value: "featureSetId", sortable: false },
    { text: "Cost", value: "points", sortable: false },
    { text: "Created", value: "created", sortable: false },
    { text: "Updated", value: "updated", sortable: false },
  ];
  options = {};

  searchFeatureSets: { text: string; value: string }[] = [];
  searchGroups: { text: string; value: string }[] = [];

  @Watch("options")
  optionsChanged() {
    this.searchAction();
  }

  get command() {
    return KnownOptionCommandsModule.command;
  }

  get commandValue() {
    return KnownOptionCommandValuesModule.value;
  }

  get knownOption() {
    return KnownOptionsModule.option;
  }

  @Watch("commandValue")
  @Watch("command")
  @Watch("knownOption")
  onCommandValueUpdated() {
    KnownOptionsModule.fetchKnownOptions(this.action);
  }

  public created() {
    KnownOptionsModule.fetchKnownOptions(this.action);
    KnownOptionsGroupsModule.fetchAll();
    FeatureSetsModule.fetchAll();
  }

  searchAction() {
    if (this.searchFeatureSets.length > 0) {
      this.action.featureSetIds = this.searchFeatureSets
        .map((x) => x.value)
        .join(",");
    } else this.action.featureSetIds = undefined;
    if (this.searchGroups.length > 0) {
      this.action.groupIds = this.searchGroups.map((x) => x.value).join(",");
    } else this.action.groupIds = undefined;
    KnownOptionsModule.fetchKnownOptions(this.action);
  }

  get page() {
    return KnownOptionsModule.page;
  }

  get featureSets() {
    return FeatureSetsModule.all.map((x) => {
      return { text: x.name, value: x.featureSetId };
    });
  }

  get groups() {
    return KnownOptionsGroupsModule.all.map((x) => {
      return { text: x.name, value: x.groupId };
    });
  }

  getFeatureSetName(id: string) {
    const featureSet = FeatureSetsModule.all.find((x) => x.featureSetId == id);
    if (featureSet) return featureSet.name;
    return id;
  }

  getGroupName(id: string) {
    const group = KnownOptionsGroupsModule.all.find((x) => x.groupId == id);
    if (group) return group.name;
    return id;
  }

  async deleteOption(item: KnownOption) {
    if (confirm(`Are you sure to remove '${item.name}'?`)) {
      await KnownOptionsModule.deleteOption(item.knownOptionId);
    }
  }
}
