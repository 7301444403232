





















































import { Component, Vue, Watch } from "vue-property-decorator";
import {
  FeatureSetsModule,
  ListFeatureSetsAction,
} from "@/store/modules/feature-sets";
import { router } from "@/router";
import { FeatureSet, FeatureSetPage } from "@/apis/axon_configurations";

@Component({ name: "FeatureSets" })
export default class FeatureSets extends Vue {
  search = "";
  action = new ListFeatureSetsAction();
  public created() {
    FeatureSetsModule.fetchFeatureSets(this.action);
  }
  headers = [
    // { text: "ID", value: "featureSetId", sortable: false },
    {
      text: "Name",
      value: "name",
      sortable: false,
    },
    { text: "Model", value: "model", sortable: false },
    { text: "Manufaturer", value: "manufacturer", sortable: false },
    { text: "Start Year", value: "startYear", sortable: false },
    { text: "End Year", value: "endYear", sortable: false },
    { text: "Family", value: "family", sortable: false },
    {
      text: "Allow Speedo",
      value: "allowSpeedCalibration",
      sortable: false,
    },
    { text: "Created", value: "created", sortable: false },
    { text: "Updated", value: "updated", sortable: false },
    { text: "Actions", value: "actions", sortable: false },
  ];

  options = {};

  @Watch("options")
  optionsChanged() {
    FeatureSetsModule.fetchFeatureSets(this.action);
  }

  get featureSet() {
    return FeatureSetsModule.featureSet;
  }

  @Watch("featureSet")
  onFeatureSetChanged() {
    FeatureSetsModule.fetchFeatureSets(this.action);
  }

  get page() {
    return FeatureSetsModule.featureSetPage as FeatureSetPage;
  }

  onItemClick(item: FeatureSet) {
    router.push({
      name: "feature-set-view",
      params: { id: item.featureSetId },
    });
  }
}
