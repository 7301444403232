var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":"Device ID","color":"secondary","outlined":"","dense":"","clearable":"","required":""},model:{value:(_vm.listDevicesAction.deviceIdentifier),callback:function ($$v) {_vm.$set(_vm.listDevicesAction, "deviceIdentifier", $$v)},expression:"listDevicesAction.deviceIdentifier"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":"E-mail","color":"secondary","outlined":"","dense":"","clearable":"","required":""},model:{value:(_vm.listDevicesAction.email),callback:function ($$v) {_vm.$set(_vm.listDevicesAction, "email", $$v)},expression:"listDevicesAction.email"}})],1),_c('v-col',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.searchRemote}},[_vm._v("Search")])],1)],1)],1)],1),_c('v-divider'),_c('v-data-table',{attrs:{"page":_vm.listDevicesAction.page,"pageCount":_vm.pageResult.items / _vm.pageResult.limit,"server-items-length":_vm.pageResult.total,"headers":_vm.headers,"items":_vm.pageResult.devices,"footer-props":{
        'items-per-page-options': [10, 20, 50],
      },"items-per-page":_vm.listDevicesAction.limit,"search":_vm.search,"options":_vm.options},on:{"update:page":function($event){return _vm.$set(_vm.listDevicesAction, "page", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.listDevicesAction, "limit", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.listDevicesAction, "limit", $event)},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.deviceId",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(item.deviceId))])]}},{key:"item.open",fn:function(ref){
      var item = ref.item;
return [(item.open)?_c('v-chip',{attrs:{"small":"","color":"success"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-lock-open-check ")]),_vm._v(" Open ")],1):_c('v-chip',{attrs:{"small":"","color":"error"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-lock ")]),_vm._v(" Close ")],1)]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [(item.status == 'REGISTERED')?_c('v-chip',{attrs:{"small":"","color":"success"}},[_vm._v(_vm._s(item.status))]):(item.status == 'LOCKED')?_c('v-chip',{attrs:{"small":"","color":"warning"}},[_vm._v(_vm._s(item.status))]):(item.status == 'DISABLED')?_c('v-chip',{attrs:{"small":"","color":"error"}},[_vm._v(_vm._s(item.status))]):_c('v-chip',{staticClass:"primary--text",attrs:{"small":""}},[_vm._v(_vm._s(item.status))])]}},{key:"item.featureSetId",fn:function(ref){
      var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.getFeatureSet(item.featureSetId))}})]}},{key:"item.lastUsageDate",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.lastUsageDate,"from", "now"))+" ")]}},{key:"item.registrationDate",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.registrationDate,"from", "now"))+" ")]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","text":""},on:{"click":function($event){return _vm.onItemClick(item)}}},[_c('v-icon',[_vm._v("mdi-eye")]),_vm._v(" View ")],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }