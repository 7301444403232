var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","to":{ name: 'codes-generate' },"color":"secondary","small":""}},[_vm._v("Generate")])],1),_c('v-divider'),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":"Code","color":"secondary","outlined":"","dense":"","clearable":"","required":""},model:{value:(_vm.action.code),callback:function ($$v) {_vm.$set(_vm.action, "code", $$v)},expression:"action.code"}})],1),_c('v-col',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.searchRemote}},[_vm._v("Search")])],1)],1)],1),_c('v-divider'),_c('v-data-table',{attrs:{"headers":_vm.headers,"page":_vm.action.page,"pageCount":_vm.page.items / _vm.page.limit,"server-items-length":_vm.page.total,"items":_vm.page.codes,"items-per-page":_vm.action.limit,"search":_vm.search,"footer-props":{
        'items-per-page-options': [10, 20, 50],
      },"options":_vm.options},on:{"update:page":function($event){return _vm.$set(_vm.action, "page", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.action, "limit", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.action, "limit", $event)},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.redeemUser",fn:function(ref){
      var item = ref.item;
return [(item.redeemUser)?_c('center',[_c('v-btn',{attrs:{"to":{ name: 'user-view', params: { id: item.redeemUser } },"x-small":"","icon":""}},[_c('v-icon',[_vm._v(" mdi-eye ")])],1)],1):_c('center',[_vm._v("N/A")])]}},{key:"item.redeemDate",fn:function(ref){
      var item = ref.item;
return [(item.redeemDate)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.redeemDate,"from", "now"))+" ")]):_c('span',[_vm._v("N/A")])]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [(item.status == 'REDEEMED')?_c('v-chip',{attrs:{"small":"","color":"teal"}},[_vm._v(_vm._s(item.status))]):(item.status == 'RESERVED')?_c('v-chip',{attrs:{"small":"","color":"warning"}},[_vm._v(_vm._s(item.status))]):(item.status == 'CANCELED')?_c('v-chip',{attrs:{"small":"","color":"error"}},[_vm._v(_vm._s(item.status))]):_c('v-chip',{attrs:{"small":"","color":"blue darken-4"}},[_vm._v(_vm._s(item.status))])]}},{key:"item.unlocked",fn:function(ref){
      var item = ref.item;
return [(item.unlocked)?_c('v-chip',{attrs:{"small":"","color":"blue darken-4"}},[_vm._v("UNLOCKED")]):_c('v-chip',{attrs:{"small":"","color":"blue"}},[_vm._v("LIMITED")])]}},{key:"item.created",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.created,"from", "now"))+" ")]}},{key:"item.modified",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.modified,"from", "now"))+" ")]}},{key:"item.transfer",fn:function(ref){
      var item = ref.item;
return [(item.transfer)?_c('v-chip',[_vm._v("YES")]):_c('v-chip',[_vm._v("NO")])]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [('OK' == item.status)?_c('v-btn',{attrs:{"color":"red","small":""},on:{"click":function($event){return _vm.reserveCode(item)}}},[_vm._v(" Reserve ")]):_vm._e()]}}],null,true)})],1),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }