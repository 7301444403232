import Vue from "vue";
import Vuex from "vuex";
import { AuthState } from "./modules/auth";
import { CodesState } from "./modules/codes";
import { DevicesState } from "./modules/devices";
import { DiagnosticsState } from "./modules/diagnostics";
import { FAQSState } from "./modules/faqs";
import { FeatureSetsState } from "./modules/feature-sets";
import { OptionCommandValuesState } from "./modules/known-option-command-values";
import { OptionCommandsState } from "./modules/known-option-commands";
import { OptionGroupsState } from "./modules/known-option-groups";
import { OptionsState } from "./modules/known-options";
import { LoaderState } from "./modules/loader";
import { MemoryModuleState } from "./modules/memory-modules";
import { TransactionHistoryState } from "./modules/transactions-history";
import { UserCapabilitiesState } from "./modules/user-capabilities";
import { UsersState } from "./modules/users";
import { VINDecoderState } from "./modules/vin-decoder";

Vue.use(Vuex);

export interface AxonRootState {
  Auth: AuthState;
  Codes: CodesState;
  Devices: DevicesState;
  Diagnostics: DiagnosticsState;
  FAQs: FAQSState;
  FeatureSets: FeatureSetsState;
  KnownOptionCommandValues: OptionCommandValuesState;
  KnownOptionCommands: OptionCommandsState;
  KnownOptionsGroups: OptionGroupsState;
  KnownOptions: OptionsState;
  Loader: LoaderState;
  TransactionsHistory: TransactionHistoryState;
  UsersCapabilities: UserCapabilitiesState;
  Users: UsersState;
  VINDecoder: VINDecoderState;
  MemoryModule: MemoryModuleState;
}

const store = new Vuex.Store<AxonRootState>({});

export default store;
