











































































import { Component, Vue } from "vue-property-decorator";
import { KnownOptionsGroupsModule } from "@/store/modules/known-option-groups";

@Component({ name: "KnownOptionGroupComponent" })
export default class KnownOptionGroupComponent extends Vue {
  created() {
    KnownOptionsGroupsModule.fetchKnownOptionGroupById(this.$route.params.id);
  }

  get item() {
    return KnownOptionsGroupsModule.group;
  }

  async onDelete() {
    if (confirm(`Are you sure to delete group "${this.item.name}"?`)) {
      await KnownOptionsGroupsModule.deleteGroup(this.$route.params.id);
      this.$router.go(-1);
    }
  }
}
