




















import { Component, Vue } from "vue-property-decorator";
import AddressMasker from "@/components/AddressMasker.vue";

@Component({ name: "Workbench", components: { AddressMasker } })
export default class Workbench extends Vue {
  selectedStaticValues: string[] = [];
  selectedDynamicValues: string[] = [];
  valueSize = 6;
  nibbles: string[] = ["*", "*", "*", "*"];
}
